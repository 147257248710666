import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const ClearingOrganisations = t.type(
  {
    name: t.string,
    displayName: t.string,
    email: t.string,
    logoUrl: t.string,
  },
  'Organisations'
)

export type ClearingOrganisations = t.TypeOf<typeof ClearingOrganisations>

export const OrganisationsSearchParams = t.partial(
  {
    page: t.union([t.number, t.undefined]),
    itemsPerPage: t.union([t.number, t.undefined]),
    module: t.union([t.string, t.undefined]),
    search: t.union([t.string, t.undefined]),
  },
  'OrganisationsSearchParams'
)

export type OrganisationsSearchParams = t.TypeOf<
  typeof OrganisationsSearchParams
>
/**
 * WorkingHours
 */
export const WorkingHours = t.union([
  t.type({
    end: t.string,
    start: t.string,
    fullCycle: t.literal(false),
    timeZone: t.string,
  }),
  t.type({ fullCycle: t.literal(true) }),
])
export type WorkingHours = t.TypeOf<typeof WorkingHours>
/* **
 *
 * clearing Assets type
 * assets
 *  enabled
 *
 */
export const ClearingAssets = t.type({
  assets: t.array(t.string),
  enabled: t.boolean,
})

export type ClearingAssets = t.TypeOf<typeof ClearingAssets>

/* **
 *
 * Clearing settings type
 *  clearingAssets
 *  currencies
 *
 *
 */
export const ClearingConfig = t.type({
  clearingAssets: ClearingAssets,
  currencies: t.array(t.string),
  workingHours: WorkingHours,
})

export type ClearingConfig = t.TypeOf<typeof ClearingConfig>

export const Currency = t.type({
  assetID: t.string,
  currency: t.string,
})

export type Currency = t.TypeOf<typeof Currency>

export const Currencies = t.array(Currency)

export type Currencies = t.TypeOf<typeof Currencies>

export const Rate = t.type({
  currency: t.string,
  rate: t.type({
    denominator: t.number,
    numerator: t.number,
  }),
  time: DateFromISOString,
})

export type Rate = t.TypeOf<typeof Rate>

export const Rates = t.array(Rate)

export type Rates = t.TypeOf<typeof Rates>

export const Format = t.type({
  code: t.string,
  decimalPoints: t.number,
})

export type Format = t.TypeOf<typeof Format>

/**
 * Assets info
 */
export const Asset = t.type({
  assetName: t.string,
  recipient: t.string,
  format: Format,
  unitID: t.string,
  issuer: t.string,
  createdAt: t.string,
  organization: t.string,
})

export type Asset = t.TypeOf<typeof Asset>

export const OrganizationDetails = t.type({
  organization: t.string,
  status: t.keyof({
    pending: null,
    blocked: null,
    active: null,
  }),
  full: t.array(t.string),
  fractional: t.array(t.string),
})
export type OrganizationDetails = t.TypeOf<typeof OrganizationDetails>

export const OrganizationReserve = t.type({
  full: t.array(t.string),
  fractional: t.array(t.string),
})
export type OrganizationReserve = t.TypeOf<typeof OrganizationReserve>

export const OrganizationStatus = t.type({
  status: t.keyof({
    blocked: null,
    active: null,
  }),
})

export type OrganizationStatus = t.TypeOf<typeof OrganizationStatus>
