"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = contains;

function contains(root, n) {
  if (!root) {
    return false;
  } // Use native if support


  if (root.contains) {
    return root.contains(n);
  } // `document.contains` not support with IE11


  var node = n;

  while (node) {
    if (node === root) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
}