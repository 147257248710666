import { useAccount, useCurrentWorkspace, Module } from '@library/react-toolkit'
import { Alert, Card, Divider, Typography } from 'antd'
import { array } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import styled, { css } from 'styled-components'

import { TranslatedMessage } from '../../shared/translations/data'
import { applications } from './types'
import { useOrganizationStatus } from './queries'
import ClearingModuleInfo from '../../shared/utils/ClearingInfo'

const { Meta } = Card
const CardContainer = styled(Card)`
  padding-top: 24px;
  height: 100%;
  transition: box-shadow 0.3s, border-color 0.3s, transform 0.3s;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
    0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1),
    0 16px 16px rgba(0, 0, 0, 0.1);
`

const Icon = styled.div`
  height: 160px;
  width: 165px;
  background: ${_theme.colors.primary};
  border-radius: 80px;
  margin: auto;
  svg {
    height: 80px;
    width: 80px;
    color: #fff;
    margin-left: 40px;
    margin-top: 40px;
  }
`

const MetaContainer = styled(Meta)`
  display: block;
  text-align: center;
`

const CardHover = styled.a<{ pending: boolean }>`
  ${({ pending }) =>
    pending
      ? css`
          cursor: default;
        `
      : css`
          &:hover {
            ${CardContainer} {
              height: 100%;
              transform: scale(1.01);
              box-shadow: 0 16px 16px rgba(0, 0, 0, 0.4),
                0 24px 24px rgba(0, 0, 0, 0.1);
            }
          }
        `}
`

const ModulesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 20rem));
  grid-gap: 16px;
  justify-content: center;
`

// TODO: clean up these props
const ModuleCard = ({ app, status }: { app: any; status?: string }) => {
  const pending = app.name === 'CLEARING' && status === 'pending'

  return (
    <CardHover pending={pending} key={app.name}>
      <CardContainer
        hoverable={!pending}
        onClick={() => {
          if (status !== 'pending') window.location.href = app.href
        }}
        bordered={false}
        cover={
          status === 'pending' ? (
            <Icon style={{ background: '#79747e' }}>{app.icon}</Icon>
          ) : (
            <Icon>{app.icon}</Icon>
          )
        }
      >
        <Divider />
        <MetaContainer
          title={
            <Typography.Title level={4} type="secondary">
              {<TranslatedMessage id={app.name} />}
            </Typography.Title>
          }
          description={<TranslatedMessage id={app.descriptionKey} />}
        />
        {status === 'pending' || status === 'blocked' ? (
          <ClearingModuleInfo status={status} />
        ) : null}
      </CardContainer>
    </CardHover>
  )
}

const AccountApps = () => {
  const ws = useCurrentWorkspace()
  const account = useAccount()
  const organisationStatus = useOrganizationStatus()
  const status = organisationStatus?.data
  const hasApplication = (app: Module) => {
    if (!ws || !account) {
      return false
    } else if (ws.type === 'personal') {
      return app === 'WEB_WALLET'
    } else {
      return !!account.organizations[ws.organization.name]?.modulePermissions?.[
        app
      ]
    }
  }

  const availableApplications = pipe(
    applications,
    array.filter((app) => hasApplication(app.name) && app.name !== 'SMART_HUB')
  )
  return (
    <>
      <Typography.Title level={2} style={{ textAlign: 'center' }}>
        <TranslatedMessage id="services" />
      </Typography.Title>
      <Divider />
      {applications.length === 0 ? (
        <Alert
          message={<TranslatedMessage id="noAuthority" />}
          description={<TranslatedMessage id="noModulePermissions" />}
          type="warning"
        />
      ) : (
        <ModulesGrid>
          {availableApplications.map((app) => (
            <ModuleCard
              app={app}
              key={app.name}
              status={
                app.name === 'CLEARING' &&
                (status === 'pending' || status === 'blocked')
                  ? status
                  : undefined
              }
            />
          ))}
        </ModulesGrid>
      )}
    </>
  )
}

export default AccountApps
