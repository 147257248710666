"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard")["default"];
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = scrollTo;
var _raf = _interopRequireDefault(require("rc-util/lib/raf"));
var _easings = require("./easings");
var _getScroll = _interopRequireWildcard(require("./getScroll"));
function scrollTo(y) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _options$getContainer = options.getContainer,
    getContainer = _options$getContainer === void 0 ? function () {
      return window;
    } : _options$getContainer,
    callback = options.callback,
    _options$duration = options.duration,
    duration = _options$duration === void 0 ? 450 : _options$duration;
  var container = getContainer();
  var scrollTop = (0, _getScroll["default"])(container, true);
  var startTime = Date.now();
  var frameFunc = function frameFunc() {
    var timestamp = Date.now();
    var time = timestamp - startTime;
    var nextScrollTop = (0, _easings.easeInOutCubic)(time > duration ? duration : time, scrollTop, y, duration);
    if ((0, _getScroll.isWindow)(container)) {
      container.scrollTo(window.pageXOffset, nextScrollTop);
    } else if (container instanceof Document || container.constructor.name === 'HTMLDocument') {
      container.documentElement.scrollTop = nextScrollTop;
    } else {
      container.scrollTop = nextScrollTop;
    }
    if (time < duration) {
      (0, _raf["default"])(frameFunc);
    } else if (typeof callback === 'function') {
      callback();
    }
  };
  (0, _raf["default"])(frameFunc);
}