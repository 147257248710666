import React from 'react'
import { Form, Input } from 'antd'
import {
  translatedMessage,
  TranslatedMessage,
} from '../../../shared/translations/data'
import SignUpStep, { Props as StepProps } from './SignUpStep'
import { useSelector } from 'react-redux'

type FormProps<T> = StepProps<T> & { goBack?: () => void }

type Password = {
  password: string
}
const SetPassword = ({
  goBack,
  onSubmit,
  onChange,
  ...stepProps
}: FormProps<Password>) => {
  const locale = useSelector((s) => s.locale)
  const [form] = Form.useForm<Password>()

  return (
    <SignUpStep<Password>
      {...stepProps}
      onSubmit={({ password }) => onSubmit({ password })}
      onChange={({ password }) => onChange({ password })}
      goBack={goBack}
      form={form}
    >
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: <TranslatedMessage id="requiredPassword" />,
          },
          {
            min: 8,
          },
        ]}
      >
        <Input
          allowClear
          type="password"
          placeholder={translatedMessage(locale)('password')}
        />
      </Form.Item>
      <Form.Item
        name="verifyPassword"
        rules={[
          {
            validator: (_, v) =>
              new Promise((res, rej) => {
                v !== form.getFieldValue('password')
                  ? rej('non matching')
                  : res(0)
              }),
            message: <TranslatedMessage id="mismatchedPasswords" />,
          },
        ]}
      >
        <Input
          allowClear
          type="password"
          placeholder={translatedMessage(locale)('verifyPassword')}
        />
      </Form.Item>
    </SignUpStep>
  )
}

export default SetPassword
