import { Badge } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { TranslatedMessage } from '../../../shared/translations/data'

type AffiliateStatusProps = {
  status: string
}

const ColoredStatus = styled.div<{ status: string }>`
  color: ${(props) =>
    props.status === 'active'
      ? props.theme.colors.primary
      : props.status === 'pending'
      ? props.theme.colors.secondary
      : props.theme.colors.error};
  & > .ant-badge,
  & > .ant-badge > .ant-badge-status-text,
  & > .ant-badge > .ant-badge-status-dot {
    color: ${(props) =>
      props.status === 'active'
        ? props.theme.colors.primary
        : props.status === 'pending'
        ? props.theme.colors.secondary
        : props.theme.colors.error};
  }
  & > .ant-badge > .ant-badge-status-dot {
    background-color: ${(props) =>
      props.status === 'active'
        ? props.theme.colors.primary
        : props.status === 'pending'
        ? props.theme.colors.secondary
        : props.theme.colors.error};
  }
  & > .ant-badge > .ant-badge-status-dot::after {
    border: 1px solid
      ${(props) =>
        props.status === 'active'
          ? props.theme.colors.primary
          : props.status === 'pending'
          ? props.theme.colors.secondary
          : props.theme.colors.error};
  }
`

const AffiliateStatus = ({ status }: AffiliateStatusProps) => {
  return (
    <ColoredStatus status={status}>
      {{
        pending: (
          <Badge
            status="processing"
            text={<TranslatedMessage id="pending" />}
          />
        ),
        active: (
          <Badge status="success" text={<TranslatedMessage id="active" />} />
        ),
        blocked: (
          <Badge status="warning" text={<TranslatedMessage id="blocked" />} />
        ),
      }[status] ?? null}
    </ColoredStatus>
  )
}

export default AffiliateStatus
