import * as axios from '../../../../axios'
import {
  Asset,
  ClearingConfig,
  OrganisationsSearchParams,
  Currencies,
  Rate,
  Rates,
  OrganizationDetails,
  ClearingOrganisations,
  OrganizationReserve,
  OrganizationStatus,
} from './types'
import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { taskEither } from 'fp-ts'

export const getOrganizationsParticipantsClearing = (
  req: OrganisationsSearchParams,
  cancelToken?: axios.CancelToken
) =>
  axios.get('/uaa/api/organizations', {
    decoder: t.type({
      count: t.number,
      data: t.array(ClearingOrganisations),
    }),
    cancelToken,
    params: req,
  })

export const setGeneralSettings = (
  req: ClearingConfig,
  cancelToken?: axios.CancelToken
) =>
  axios.post('/prosper-clear/api/settings', req, {
    decoder: t.unknown,
    cancelToken,
  })

export const getGeneralSettings = (
  org: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/prosper-clear/api/settings/${org}`, {
    decoder: ClearingConfig,
    cancelToken,
  })

export const getAssets = (org: string, cancelToken?: axios.CancelToken) =>
  axios.get(`/assets-management/1.0/assets/${org}`, {
    cancelToken,
    decoder: t.array(Asset),
  })

export const getCurrencies = (org: string, cancelToken?: axios.CancelToken) =>
  axios.get(`/prosper-clear/api/currencies/${org}`, {
    cancelToken,
    decoder: Currencies,
  })

export const postCurrencies = (currencies: Currencies) =>
  axios.post('/prosper-clear/api/currencies', currencies, {
    decoder: t.unknown,
    encoder: Currencies,
  })

export const getRate = (
  org: string,
  currency: string,
  cancelToken?: axios.CancelToken
) =>
  pipe(
    axios.get(`/prosper-clear/api/rates/${org}/latest/${currency}`, {
      cancelToken,
      decoder: t.union([Rate, t.literal('')]),
    }),
    taskEither.map((v) => (v === '' ? undefined : v))
  )

export const postRates = (rates: Rates) =>
  axios.post('/prosper-clear/api/rates', rates, {
    decoder: t.unknown,
    encoder: Rates,
  })

export const getParticipant = (
  org: string,
  participant: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(
    `/prosper-clear/api/participants/${org}/participant/${participant}`,
    {
      decoder: t.type({ nym: t.string }),
      cancelToken,
    }
  )

export const getOrganizationDetails = (
  org: string,
  cancelToken?: axios.CancelToken
) =>
  pipe(
    axios.get(`/prosper-clear/api/settings/${org}/details`, {
      decoder: t.union([OrganizationDetails, t.literal('')]),
      cancelToken,
    }),
    taskEither.map((v) => (v === '' ? undefined : v))
  )

export const postOrganizationReserve = (
  org: string,
  body: OrganizationReserve
) =>
  axios.post(`/prosper-clear/api/settings/${org}/reserve`, body, {
    decoder: t.unknown,
    encoder: OrganizationReserve,
  })

export const putOrganizationStatus = (org: string, body: OrganizationStatus) =>
  axios.put(`/prosper-clear/api/settings/${org}/status`, body, {
    decoder: t.unknown,
    encoder: OrganizationStatus,
  })

export const getVerifyOrganization = (
  org: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/prosper-clear/api/settings/${org}/verify`, {
    decoder: t.string,
    cancelToken,
  })
