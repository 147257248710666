import { ConfigProvider } from 'antd'
import { ConnectedRouter } from 'connected-react-router'
import * as React from 'react'
import { render } from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'

import App from './components/App'
import store, { history } from './shared/state/store'

ConfigProvider.config({ theme: { primaryColor: _theme.colors.primary } })
const queryClient = new QueryClient()

render(
  <ConfigProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </QueryClientProvider>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)
