import * as React from 'react'
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  Button,
  Form,
  FormInstance,
  Modal,
  Select,
  Spin,
  Table,
  Typography,
} from 'antd'
import { TranslatedMessage } from '../../../../../shared/translations/data'
import { useGeneralSettings, useGetOrganizationDetails } from '../queries'
import { useUnits } from '@library/react-toolkit'
import { useIntl } from 'react-intl'
import { OrganizationReserve } from '../types'
import { array, string } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'

type Props = {
  active: boolean
  participant: string
  form: FormInstance
  onFinish: (_: { full: string[]; fractional: string[] }) => void
}
type Model = 'fractional' | 'full'
type FormRow = {
  asset?: string
  model?: Model
  key: number
}

const orElse = <T,>(x: 'pristine' | T, e: T): T => (x === 'pristine' ? e : x)

const AssetsAuthorizationForm = ({ participant, form, onFinish }: Props) => {
  const units = useUnits()
  const intl = useIntl()
  const [formList, setFormList] = React.useState<'pristine' | FormRow[]>(
    'pristine'
  )
  const settingsQuery = useGeneralSettings()
  const organizationDetailsQuery = useGetOrganizationDetails(participant)

  React.useEffect(() => {
    setFormList('pristine')
  }, [])

  const clearingAssets = !settingsQuery.data
    ? 'pending'
    : settingsQuery.data.clearingAssets.assets

  const prevValues = React.useMemo(() => {
    if (!organizationDetailsQuery.data) {
      return 'pending'
    }

    const {
      data: { full, fractional },
    } = organizationDetailsQuery

    return [
      ...full.map((r) => ({
        asset: r,
        model: 'full' as Model,
      })),
      ...fractional.map((r) => ({
        asset: r,
        model: 'fractional' as Model,
      })),
    ].map((r, i): Required<FormRow> => ({ ...r, key: i }))
  }, [organizationDetailsQuery.isSuccess])

  const initialValues = (prevValues === 'pending' ? [] : prevValues).map(
    (r): FormRow => ({
      ...r,
      asset: r.asset,
      model: r.model,
    })
  )

  const allOfMyData = orElse(
    formList,
    prevValues === 'pending' ? [] : prevValues
  )

  const allFullData: Set<string> = new Set(
    allOfMyData
      .filter(
        (v): v is Required<FormRow> =>
          v.model === 'full' && v.asset !== undefined
      )
      .map((v) => v.asset)
  )
  const allFractionalData: Set<string> = new Set(
    allOfMyData
      .filter(
        (v): v is Required<FormRow> =>
          v.model === 'fractional' && v.asset !== undefined
      )
      .map((v) => v.asset)
  )

  const setData = (d: FormRow[]) => {
    form.setFieldsValue(
      d.reduce<Record<string, FormRow>>((p, c, ind) => ({ ...p, [ind]: c }), {})
    )
    setFormList(d)
  }

  const removeRow = React.useCallback(
    (k: number) => setData(allOfMyData.filter((_, idx) => idx !== k)),
    [allOfMyData, setData, setFormList]
  )

  const addRow = React.useCallback(() => {
    const key = Math.random() * 1000
    setData([...allOfMyData, { asset: undefined, model: undefined, key }])
  }, [allOfMyData, setData, setFormList])

  const submitReserve = React.useCallback(
    (rec: Record<number, Required<FormRow>>) => {
      const v = Object.values(rec)
      Modal.confirm({
        title: (
          <Typography.Title level={5}>
            {intl.formatMessage({ id: 'updatingClearingConfig' })}
          </Typography.Title>
        ),
        icon: <ExclamationCircleOutlined />,
        content: (
          <p>{intl.formatMessage({ id: 'updatingClearingConfigBody' })}</p>
        ),
        okText: intl.formatMessage({ id: 'approve' }),
        onOk: () => {
          const value = v.reduce<OrganizationReserve>(
            (p, c): OrganizationReserve => {
              return c.model === 'fractional'
                ? {
                    ...p,
                    fractional: [...p.fractional, c.asset],
                  }
                : {
                    ...p,
                    full: [...p.full, c.asset],
                  }
            },
            { full: [], fractional: [] }
          )

          onFinish({
            full: pipe(value.full, array.uniq(string.Eq)),
            fractional: pipe(value.fractional, array.uniq(string.Eq)),
          })
        },
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onCancel: () => {},
        width: 448,
      })
    },
    [intl]
  )

  return prevValues === 'pending' ? (
    <Spin spinning />
  ) : (
    <Form<Record<number, Required<FormRow>>>
      initialValues={initialValues}
      onValuesChange={(_, v) => {
        setFormList(Object.values(v))
      }}
      form={form}
      name="clearing-reserve"
      onFinish={submitReserve}
    >
      <Table<FormRow>
        bordered
        pagination={false}
        loading={organizationDetailsQuery.isLoading}
        columns={[
          {
            title: <TranslatedMessage id="asset" />,
            key: 'asset',
            render: (_, _v, ind) => (
              <>
                <Form.Item name={[ind, 'key']} hidden />
                <Form.Item
                  shouldUpdate
                  name={[ind, 'asset']}
                  rules={[{ required: true }]}
                >
                  <Select
                    loading={clearingAssets === 'pending'}
                    placeholder={<TranslatedMessage id="selectAsset" />}
                    filterOption={(_, opt) => {
                      console.log('option', opt)
                      return (
                        !!opt?.value &&
                        (!allFullData.has(opt.value) ||
                          !allFractionalData.has(opt.value))
                      )
                    }}
                    options={
                      clearingAssets === 'pending'
                        ? []
                        : clearingAssets.map((a) => ({
                            value: a,
                            label: units[a].name,
                            disabled:
                              allFullData.has(a) && allFractionalData.has(a),
                          }))
                    }
                  />
                </Form.Item>
              </>
            ),
          },
          {
            title: <TranslatedMessage id="clearingModel" />,
            key: 'model',
            render: (_, v, ind) => (
              <Form.Item
                shouldUpdate
                name={[ind, 'model']}
                rules={[{ required: true }]}
              >
                <Select<'full' | 'fractional'>
                  placeholder={<TranslatedMessage id="selectModel" />}
                  options={[
                    {
                      value: 'full',
                      label: <TranslatedMessage id="fullReserve" />,
                      disabled: !v.asset || allFullData.has(v.asset),
                    },
                    {
                      value: 'fractional',
                      label: <TranslatedMessage id="fracReserve" />,
                      disabled: !v.asset || allFractionalData.has(v.asset),
                    },
                  ]}
                />
              </Form.Item>
            ),
          },
          {
            title: <TranslatedMessage id="actions" />,
            dataIndex: 'actions',
            align: 'center',
            key: 'actions',
            render: (_, _v, ind) => (
              <MinusCircleOutlined onClick={() => removeRow(ind)} />
            ),
          },
        ]}
        dataSource={allOfMyData}
      />
      <Button
        type="dashed"
        onClick={addRow}
        block
        icon={<PlusOutlined />}
        loading={organizationDetailsQuery.isLoading}
      >
        <TranslatedMessage id="addRow" />
      </Button>
    </Form>
  )
}

export default AssetsAuthorizationForm
