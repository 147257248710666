"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var DescriptionsItem = function DescriptionsItem(_ref) {
  var children = _ref.children;
  return children;
};
var _default = DescriptionsItem;
exports["default"] = _default;