import { useAccount, useOrganization } from '@library/react-toolkit'
import { Card, Result, Tabs } from 'antd'
import { push } from 'connected-react-router'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Switch, useParams } from 'react-router-dom'
import { TranslatedMessage } from '../../../shared/translations/data'
import BackHome from '../../../shared/widgets/BackHome'
import { AssetCreation } from './mintTabs/AssetCreation'
import { MintValidation } from './mintTabs/MintValidation'
import { OfflineMint } from './mintTabs/OfflineMint'

export const MintModuleSettings = () => {
  const dispatch = useDispatch()
  const organization = useOrganization()
  const account = useAccount()
  const hasPermission =
    !!account &&
    !!organization &&
    !!(account.organizations[organization.name]?.authority === 'ADMIN')
  const { tab } = useParams<{ tab?: string }>()
  return (
    <div>
      <BackHome />
      <Card title={<TranslatedMessage id="mintConfiguration" />}>
        {hasPermission ? (
          <Switch>
            <Tabs
              onChange={(tabKey) =>
                dispatch(push(`/modules_setting/mint/${tabKey}`))
              }
              defaultActiveKey={tab ?? 'minting-validation'}
              activeKey={tab ?? 'minting-validation'}
            >
              <Tabs.TabPane
                tab={<TranslatedMessage id="mintingValidation" />}
                key="minting-validation"
              >
                <MintValidation />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<TranslatedMessage id="assetCreation" />}
                key="asset-creation"
              >
                <AssetCreation />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<TranslatedMessage id="offlineMint" />}
                key="offline-mint"
              >
                <OfflineMint />
              </Tabs.TabPane>
            </Tabs>
          </Switch>
        ) : (
          <Result
            status="error"
            title={<TranslatedMessage id="notAnAdminDescription" />}
          />
        )}
      </Card>
    </div>
  )
}
