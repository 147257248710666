import * as React from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Button, Descriptions, Form, Modal } from 'antd'
import { TranslatedMessage } from '../../../../../shared/translations/data'
import AssetsAuthorizationForm from './AssetsAuthorizationForm'
import { useSetOrganizationReserve } from '../queries'
import { OrganizationReserve } from '../types'

type Props = {
  participant: string
  displayName: string
  disabled: boolean
}

type FormData = { asset?: string; model?: 'fractional' | 'full' }

const AssetsAuthorization = ({ participant, displayName, disabled }: Props) => {
  const [form] = Form.useForm<Record<number, FormData>>()
  const reserveMutation = useSetOrganizationReserve(participant)
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  React.useEffect(() => {
    form.resetFields()
  }, [isModalVisible])

  const formOnFinish = React.useCallback(
    (v: OrganizationReserve) => {
      reserveMutation.mutate(v, { onSuccess: () => setIsModalVisible(false) })
    },
    [reserveMutation, setIsModalVisible]
  )

  return (
    <>
      <Button
        disabled={disabled}
        type="text"
        icon={<EditOutlined />}
        onClick={() => setIsModalVisible(true)}
      />
      <Modal
        destroyOnClose
        title={<TranslatedMessage id="assetsAuthorization" />}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            <TranslatedMessage id="cancel" />
          </Button>,
          <Button
            loading={reserveMutation.isLoading}
            key="submit"
            onClick={form.submit}
          >
            <TranslatedMessage id="submit" />
          </Button>,
        ]}
      >
        <Descriptions column={1}>
          <Descriptions.Item label={<TranslatedMessage id="organizationID" />}>
            {participant}
          </Descriptions.Item>
          <Descriptions.Item
            label={<TranslatedMessage id="organizationName" />}
          >
            {displayName}
          </Descriptions.Item>
        </Descriptions>
        <AssetsAuthorizationForm
          participant={participant}
          form={form}
          onFinish={formOnFinish}
          active={isModalVisible}
        />
      </Modal>
    </>
  )
}

export default AssetsAuthorization
