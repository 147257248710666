import {
  Button,
  DatePicker,
  Descriptions,
  Form,
  Select,
  Spin,
  Switch,
} from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import AssetWithInfo from '../../../../shared/utils/AssetWithInfo'
import { TranslatedMessage } from '../../../../shared/translations/data'
import { ClearingConfig } from './types'
import {
  useGeneralSettings,
  useOrganizationAssets,
  useUpsertSettings,
} from './queries'
import modal from 'antd/lib/modal'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { CurrenciesSelect } from '../../../../shared/utils/CurrenciesSelect'
import TimezoneSelect from '../../../../shared/utils/TimezoneSelect'
import momentTimezone from 'moment-timezone'

const TwoColumn = styled.div`
  display: grid;
  width: 50%;
  grid-template-columns: 0.5fr 1fr;
`
/* const FourColumns = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 16px;
  margin-top: 16px;
`*/
const AssetSwitch = styled(Switch)`
  maxwidth: 60px;
  margin: 12px 24px;
  align-self: center;
`
const CustomButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 45px 12px 45px 12px;
  & > .ant-btn {
    width: 600px;
  }
`
export const GeneralConfiguration = () => {
  const units = useOrganizationAssets()
  const clearingConfig = useGeneralSettings()
  const [timeZone, setTimezone] = React.useState(momentTimezone.tz.guess())

  const [isEnabled, setEnabled] = React.useState(true)

  const [isCycle, setCycle] = React.useState(false)

  const [form] = Form.useForm<ClearingConfig>()
  const settingsMutation = useUpsertSettings()
  const intl = useIntl()
  React.useEffect(() => {
    if (clearingConfig.isSuccess) {
      setEnabled(clearingConfig.data.clearingAssets.enabled)
      setCycle(clearingConfig.data.workingHours.fullCycle)
      form.resetFields()
      form.setFieldsValue({
        clearingAssets: clearingConfig.data.clearingAssets,
        currencies: clearingConfig.data.currencies,
        workingHours: {
          fullCycle: clearingConfig.data.workingHours.fullCycle,
        },
      })

      if (!clearingConfig.data.workingHours.fullCycle) {
        form.setFieldValue(
          ['workingHours', 'start'],
          moment(clearingConfig.data.workingHours.start, 'HH:mm')
        )
        form.setFieldValue(
          ['workingHours', 'end'],
          moment(clearingConfig.data.workingHours.end, 'HH:mm')
        )
        form.setFieldValue(
          ['workingHours', 'timeZone'],
          clearingConfig.data.workingHours.timeZone
        )
      } else {
        form.setFieldValue(['workingHours', 'start'], undefined)
        form.setFieldValue(['workingHours', 'end'], undefined)
        form.setFieldValue(['workingHours', 'timeZone'], undefined)
      }
    }
  }, [clearingConfig.status, clearingConfig.isFetching])

  return (
    <Spin spinning={clearingConfig.isFetching}>
      <Form<ClearingConfig>
        layout="vertical"
        form={form}
        onFinish={(f) => {
          f.workingHours.fullCycle
            ? (f.workingHours = {
                fullCycle: true,
              })
            : (f.workingHours = {
                end: moment(f.workingHours.end).format('HH:mm') ?? '',
                start: moment(f.workingHours.start).format('HH:mm') ?? '',
                fullCycle: false,
                timeZone: timeZone,
              })

          console.log('onfinish', f)
          settingsMutation.mutate(f, {
            onSuccess: () => {
              clearingConfig.refetch()
            },
          })
        }}
      >
        <Descriptions
          title={<TranslatedMessage id="assetTitle" />}
          layout="vertical"
        >
          <Descriptions.Item>
            <TwoColumn>
              <Form.Item
                label={<TranslatedMessage id="wholesaleCoin" />}
                name={['clearingAssets', 'enabled']}
                valuePropName="checked"
              >
                <AssetSwitch
                  defaultChecked={isEnabled}
                  onChange={(v) => {
                    setEnabled(v)
                  }}
                />
              </Form.Item>
              <Form.Item
                label={<TranslatedMessage id="assetTitle" />}
                help={<TranslatedMessage id="helpAssetSelect" />}
                name={['clearingAssets', 'assets']}
              >
                <Select
                  mode="multiple"
                  disabled={!isEnabled}
                  loading={units.isLoading}
                >
                  {units.data?.map((u) => (
                    <Select.Option value={u.unitID} key={u.unitID}>
                      <AssetWithInfo context={u.assetName} unitID={u.unitID} />
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </TwoColumn>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title={<TranslatedMessage id="unitsAndCurrencies" />}
          layout="vertical"
        >
          <Descriptions.Item>
            <Form.Item
              label={<TranslatedMessage id="labelUnitAccount" />}
              help={<TranslatedMessage id="helpDefaultUnit" />}
              name="currencies"
              rules={[
                {
                  required: true,
                  message: 'Currencies cannot be empty',
                },
                {
                  validator: async (_, value: string[]) => {
                    if (value.length > 3) {
                      throw 'limit exceeded'
                    }
                  },
                  message: 'currency limit exceeded',
                },
              ]}
            >
              <CurrenciesSelect mode="multiple" showArrow></CurrenciesSelect>
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title={<TranslatedMessage id="cycle" />}>
          <Descriptions.Item
            contentStyle={{
              display: 'flex',
              justifyContent: 'start',
              gap: '10%',
            }}
          >
            <Form.Item
              label={<TranslatedMessage id="24/24" />}
              name={['workingHours', 'fullCycle']}
              valuePropName="checked"
            >
              <Switch
                onChange={(v) => {
                  setCycle(v)
                }}
              />
            </Form.Item>
            <Form.Item
              label={<TranslatedMessage id="dayStart" />}
              name={['workingHours', 'start']}
            >
              <DatePicker picker="time" format={'HH:mm'} disabled={isCycle} />
            </Form.Item>
            <Form.Item
              label={<TranslatedMessage id="dayEnd" />}
              name={['workingHours', 'end']}
            >
              <DatePicker picker="time" format={'HH:mm'} disabled={isCycle} />
            </Form.Item>
            <Form.Item
              label={<TranslatedMessage id="timeZone" />}
              name={['workingHours', 'timeZone']}
            >
              <TimezoneSelect
                value={timeZone}
                onChange={(v) => setTimezone(v)}
                disabled={isCycle}
              />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
        <CustomButton>
          <Button
            type="primary"
            onClick={() =>
              modal.confirm({
                title: intl.formatMessage({ id: 'titleUpdateSettingsModal' }),
                content: intl.formatMessage(
                  {
                    id: 'msgModalConfirm',
                  },
                  { br: <br /> }
                ),
                okText: intl.formatMessage({ id: 'confirm' }),
                cancelText: intl.formatMessage({ id: 'cancel' }),
                onOk: () => {
                  console.log('finish'), form.submit()
                },
              })
            }
          >
            <TranslatedMessage id="saveConfig" />
          </Button>
        </CustomButton>
      </Form>
    </Spin>
  )
}
