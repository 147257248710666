{
  "backToLogin": "Retour à la connexion",
  "personalAccount": "Compte personnel",

  "CONTRIBUTOR": "Contributeur",
  "NONE": "Aucun",
  "ADMIN": "Administrateur",
  "USER": "Utilisateur",

  "activationKeyInvalidTitle": "Clé d'activation non valide",
  "activationKeyInvalidSubtitle": "Veuillez vérifier le lien d'activation et réessayer",

  "activationSuccessfulTitle": "Votre compte a été activé",
  "activationSuccessfulSubtitle": "Vous serez bientôt redirigé vers la page de connexion",

  "addUserExists": "L'utilisateur {login} avec e-mail {email} existe déjà, souhaitez-vous les ajouter?",
  "addingExistingUser": "Ajout de l'utilisateur {login}.",
  "accept": "Accepter",
  "noModulePermissions": "Votre compte n'a le droit d'accéder à aucun module, veuillez contacter votre administrateur",
  "noAuthority": "Aucune autorité",
  "invalidRIB": "RIB longeur erronée",
  "invalidRIBFormat": "RIB format erroné",
  "signUpSuccessTitle": "Inscription  terminée",
  "signUpSuccessDescription": "Votre inscription a été enregistrée avec succès, veuillez vérifier votre email pour activer votre compte.",
  "add": "Ajouter",
  "maxLengthExceeded": "Longueur maximale dépassée",
  "noPermissions": "Aucune permission",
  "employees": "Employées",
  "employee": "Employée",
  "modules": "Modules",
  "entertaiment": "Divertissement",
  "organizationEmployees": "Nombre d'employés",
  "organization": "Organisation",
  "bank": "Banque",
  "companySettings": "Paramètres de l'organisation",
  "userexists": "Ce compte existe déjà!  ",
  "next": "Suivant",
  "back": "Précédent",
  "skip": "Passer",
  "genratorPassword": "Vous pouvez utiliser ce mot de pass",
  "changeUsername": "vous pouvez changer votre username",
  "nextDescription": "Entrez votre e-mail et cliquez sur suivant pour continuer le processus d'inscription",
  "setPasswordDescription": "Pour terminer le processus d'inscription, veuillez définir votre mot de passe pour que tu puisses connecter",
  "setPassword": "Ajouter un mot de passe",
  "addPassword": "Ajout du mot de passe",
  "confirm": "Confirmer",
  "security": "Sécurité du compte",
  "services": "MODULES",
  "authorities": "Autorités",
  "account": "Mon compte",
  "tfa": "Authentification à deux facteurs",
  "tfacode": "Code d'authentification à deux facteurs",
  "login": "Connexion",
  "logout": "Déconnexion",
  "signUpProsperus": "Créer un compte Prosperus",
  "signUpPersonal": "Créer un compte personnel",
  "signupPersonal": "Personal",
  "signupProfessional": "Professional",
  "signupProfessionalSme": "SME",
  "signupProfessionalEntreprise": "Entreprise",
  "signupProfessionalBanks": "Banks",
  "signupPersonalDescription": "This subscription allows you to store your assets and make transactions through a Secure Cloud Wallet.",
  "signupProfessionalSmeDescription": "This is for professional use.",
  "signupProfessionalEntrepriseDescription": " Organisations can get financing, manage rewards and collect bill payments",
  "signupProfessionalBanksDescription": "Banks Can Join a Clearing Network or Financing Marketplace to fund  SMEs",
  "SignUp Organization": "Créer un compte avec une nouvelle organisation",
  "SignUp Either": "S'inscrire pour l'un ou l'autre",
  "signUp": "S'inscrire",
  "password": "Mot de passe",
  "currentPassword": "Mot de passe actuel",
  "newPassword": "Nouveau mot de passe",
  "confirmPassword": "Confirmer mot de passe",
  "username": "Nom d'utilisateur",
  "requiredUsername": "Il faut entrer un nom d'utilisateur",
  "requiredPassword": "Il faut entrer un mot de passe",
  "invalidPassword": "Le mot de passe doit comporter au moins 8 caractères",
  "mismatchedPasswords": "Les mots de passe ne correspondent pas",
  "requiredEmail": "Il faut entrer une address e-mail",
  "requiredCode": "Il faut entrer enter code d'authentification à deux facteurs",
  "invalidEmail": "Addresse e-mail incorrecte",
  "loginFailed": "Échec de la connexion, vérifiez votre nom d'utilisateur et mot de passe ou cliquer sur S'inscrire pour créer un nouveau compte",
  "createAccountPrompt": "Voulez vous créer un vouveau compte !",
  "signupFailed": "Signup request was failed",
  "signupSuccess": " La demande de inscription a réussi, veuillez vérifier votre adresse e-mail et activer votre compte",
  "en": "Anglais",
  "fr": "Français",
  "forgotPassword": "Mot de passe oublié?",
  "lostTFACode": "Code d'authentification à deux facteurs perdu?",
  "resendTFACode": "Renvoyer code d'authentification à deux facteurs",
  "resend": "Renvoyer",
  "resetPassword": "Réinitialiser le mot de passe",
  "changePassword": "Changer votre mot de passe",
  "changeRequestFailed": "Demande de changement de mot de passe échouée",
  "changeRequestSuccess": "Demande de changement de mot de passe réussie",
  "tfaRequestFailed": "Demande de renvoie échouée, veuillez réessayer",
  "tfaRequestSuccess": "Demande de renvoie réussie",
  "resetRequestFailed": "Demande de réinitialisation échouée, veuillez réessayer",
  "resetRequestSuccess": "Demande de réinitialisation réussie, verifiez votre boîte de réception",
  "finishResetFailed": "Réinitialisation du mot de passe échouée",
  "finishResetSuccess": "Réinitialisation du mot de passe réussie",
  "finishSetFailed": "Ajout du mot de passe échouée",
  "finishSetSuccess": "Ajout du mot de passe réussie",
  "email": "Addresse e-mail",
  "firstName": "Nom",
  "lastName": "Prénom",
  "fullName": "Nom complet",
  "cancel": "Annuler",
  "notAnAdmin": "Vous n'êtes pas administrateur",
  "notAnAdminDescription": "Vous devez être un administrateur pour voir ou modifier les paramètres de l'organisation.",
  "notificationsAndAlerts": "Notifications et Alertes",
  "backToApplicationSelect": "Retour au menu de selection d'application",

  "ROLE_USER": "Utilisateur",
  "ROLE_ADMIN": "Administrateur",
  "ROLE_SUPER_ADMIN": "Super Administrateur",

  "enabled": "Activé",
  "disabled": "Désactivé",
  "enable": "Activer",
  "disable": "Désactiver",
  "requiredFirstName": "Il faut entrer nom",
  "requiredLastName": "Il faut entrer prénom",
  "setUsername": "Ajout d'un nom d'utilisateur",
  "setEmail": "Ajout d'un Email",
  "emailexists": "Cette adresse est utilisé !",
  "personalSetting": "Paramètres personnels",
  "organizationSettings": "Paramètres de l'entreprise",
  "personalSettings": "paramètres personnels",
  "googleSignup": "Inscription avec Google",
  "organizationInfo": "Informations sur l'organisation",
  "userInfo": "Utilisateurs de l'organisation",
  "activity": "Activité",
  "googleLogin": "Conexion avec Google",
  "createAccountQuestion": "Vous n'avez pas de compte?",
  "personnalInformation": "Informations Personnelles",
  "SecurityInformation": "Information de sécurité",
  "picture": "Image",
  "selectPicture": "Sélectionnez une image",
  "upload": "Télécharger",
  "emailLabel": "Email",
  "organizationLabel": "Organisation",
  "jobTitle": "Profession",
  "language": "Langue",
  "save": "Sauvegarder",
  "missingUsernameMsg": "Veuillez saisir votre nom d'utilisateur!",
  "missingEmailMsg": "Veuillez saisir votre email!",
  "missingFirstnameMsg": "Veuillez saisir votre prénom!",
  "missingLastnameMsg": "Veuillez saisir votre nom!",
  "missingJobTitleMsg": "Veuillez saisir votre profession!",
  "enableTwoFactor": "Activer l'authentification à deux facteurs",
  "twoFactor": "Authentification à deux facteurs",
  "prosperUs_Url": "ProsperUs_Url",
  "asset": "Actif",
  "country": "Pays",
  "Fiscal_Year": "Année fiscale",
  "fiscal_ID": " Id fiscal",
  "Financial_Sector": "Secteur financier",
  "organizationModule": "Modules d'organization ",
  "setOrganization": "Ajouter votre organisation",
  "setUser": "Ajouter User",
  "confirmation": "Confirm",
  "AddUser": "Ajouter Utilisateur ",
  "SearchUser": "Recherche utilisateur",
  "AddOrganizationUser": "Ajouter utilisateur d'organisation",
  "EditOrganizationUser": "Modifier utilisateur d'organisation",
  "Permissions": "Permissions",
  "Contributor": " Contributeur",
  "Viewer": "Consultant",
  "None": "Rien",
  "SelectUserModalText": "êtes-vous sûr vous voulez enlever cet utilisateur",
  "SelectUserModalText2": "tous les informations associées à cet utilisateur seront perdues",
  "missingCompanyNameMsg": "Veuillez entrer le nom de votre organisation!",
  "missingCompanyAbbreviationMsg": "Veuillez entrer l'Abbreviation de l'organisation!",
  "missingBankMsg": "Veuillez entrer le nom de votre banque!",
  "missingRIBMsg": "Veuillez entrer votre RIB!",
  "missingFullNameMsg": "Veuillez entrer votre nom complet!",
  "IBAN": "IBAN",
  "OrganizationUrl": "Url de l'organisation",
  "OrganizationNameExist": "le nom de l'organisation existe déjà",
  "OrganizationAbbreviationExist": "l'abbreviation de l'organisation existe déjà",
  "Next": "suivant",
  "isVerifEmail": "l'email de cet utilisateur existe déjà, si vous cliquez sur soumettre l'utilisateur sera associé à la nouvelle organisation",
  "isVerifyUserName": "le nom de cet utilisateur existe déjà",
  "Previous": "retour",
  "Submit": "soumettre",
  "OrganizationSignUp": "Inscription d'organization",
  "SLOGEN": " The Enabler of Digital Transactions.",
  "AlreadyHaveAnAccount": "Vous avez un compte déja ?",
  "No-Authority": "Pas d'authorisation",
  "NOAUTHORG": "Vous n'avez pas accès à cette page, merci de contacter votre administrateur",
  "NoModulePermissions": "Vous n’êtes pas autorisé sur cette page, merci de contacter votre administrateur",
  "walletPermissions": "Autorisations portefeuille",
  "requiredJobTitle": "Le poste est obligatoire",
  "Loading": "Chargement",
  "TermsOfUse": "Conditions d'utilisation",
  "Compliance": "Conformité",
  "Support": "Assistance",
  "Contact": "Contact",
  "CopyRightMessage": " Copyright © 2017-2022 Prosperus | Politique de confidentialité",
  "EmailOrUserName": "Email ou nom d'utilisateur",

  "ACCOUNT/PUT_ORGANIZATION_USERS_RESULT_SUCCESS": "L'utilisateur a eté modifié avec success ",
  "ACCOUNT/PUT_ORGANIZATION_USERS_RESULT_ERROR": "Modification de Utilisateur a echoue",
  "ACCOUNT/POST_ORGANIZATION_USERS_RESULT_SUCCESS": "L'utilisateur a eté ajouter avec sucess",
  "ACCOUNT/POST_ORGANIZATION_USERS_RESULT_ERROR": "L'ajout de Utilisateur a echoue",
  "ACCOUNT/login_result_RESULT_ERROR": "log in a echoué",
  "ACCOUNT/login_result_RESULT_SUCCESS": "Vous avez eté connecté ou platform",
  "ACCOUNT/update_result_RESULT_SUCCESS": "Les information d'utilisateur ont eté modifié",
  "ACCOUNT/update_result_RESULT_ERROR": "Modification a de l'utilisateur a echoué",
  "ACCOUNT/update_organization_result_RESULT_SUCCESS": "les information d'organisation a eté modifié ",
  "ACCOUNT/update_organization_result_RESULT_ERROR": "Modification a de les information d'organisation a echoué",
  "ACCOUNT/get_balance_trigger_result_error": "Impossible d'obtenir les notifications de solde",
  "ACCOUNT/add_balance_trigger_result_error": "Impossible d'ajouter la notification de solde",
  "ACCOUNT/add_balance_trigger_result_success": "Notification de solde ajoutée avec succès",
  "ACCOUNT/update_balance_trigger_result_error": "Impossible de mettre à jour la notification de solde",
  "ACCOUNT/update_balance_trigger_result_success": "Notification de solde mise à jour avec succès",
  "ACCOUNT/delete_balance_trigger_result_error": "Impossible de supprimer la notification de solde",
  "ACCOUNT/delete_balance_trigger_result_success": "Notification de solde supprimée avec succès",
  "BACKOFFICE": "BackOffice",
  "PROSPERBILL": "Factures",
  "DASHBOARD": "Dashboard",
  "MARKETPLACE": "MarketPlace",
  "WEB_WALLET": "Wallets",
  "PersonalAccount": "Compte Personal",
  "Services": "Services",
  "Public_Sector": "Sector Public ",
  "NgoNonProfit": "Ngo Non Profit",
  "Agriculture": "Agriculture",
  "Entertainment": "Entertainment",
  "Information-Technologies": "Technologies d'information",
  "Consumer Goods": "Consumer Goods",
  "Less-then-5": "Moins de 5",
  "Between-5-and-10": "Entre 5 et 10",
  "Between-10-and-20": "Entre 10 et 20",
  "Between-20-and-50": "Entre 20 et 50",
  "Between-50-and-100": "Entre 50 et 100",
  "Greater-then-100": "Plus de 100",
  "defaultUnitRequired": "Veuillez spécifier un actif!",
  "setUserInfo": "Information d’utilisateur",
  "setWallet": "Ajouter un portefeuille",
  "RIB": "RIB",
  "withWallet": "Ajouter un portefeuille",
  "verifyPassword": "Vérifier le mot de passe",
  "done": "Terminée",

  "invalidResetKeyTitle": "Clé de réinitialisation non valide",
  "invalidResetKeySubTitle": "Veuillez vérifier votre lien de réinitialisation et réessayer.",

  "invalidLogin": "Votre nom d'utilisateur connexion ne peut contenir que des caractères alphanumériques ou '@', '.', '-' et '_'",

  "signUpAlreadyLoggedIn": "Vous êtes déjà connecté en tant que ''{login}'', voulez-vous continuer?",
  "goToHomePage": "Aller à la page d'accueil",
  "organizationCreatedDescription": "L'organisation ''{displayName}'' a été créée avec succès.",
  "pageNotFound": "La page que vous recherchez n'existe pas.",
  "workspaces": "Espaces de travail",
  "pWorkspace": "Espace Personnel",
  "orgWorkspaceSuffix": "Espace",
  "organizationAccount": "Compte {displayName}",

  "signUpOrganization": "Créer un compte d'organization",
  "signUpClearingOrganization": "Créer un compte d'organization de clairière",
  "signUpEither": "Créer un compte d'organization ou personal",
  "onlyAlphanumeric": "Seuls les caractères alphanumériques sont autorisés",
  "onlyAlphabets": "Seuls les alphabets entre 2 et 4 caractères sont autorisés",

  "deleteNotification": "Supprimer notification '{name}'",
  "name": "Nom",
  "module": "Module",
  "type": "Type",
  "actions": "Actions",
  "sms": "SMS",
  "emailOption": "Email",
  "channel": "Channel",
  "wallet": "Portefeuille",
  "minBalance": "Solde Minimal",
  "maxBalance": "Solde Maximal",
  "threshold": "Threshold",
  "requiredName": "Il faut entrer un nom",
  "requiredType": "Il faut selectionner un type",
  "requiredWallet": "Il faut selectionner un portefeuille",
  "newNotification": "Ajouter Notification",
  "addNotification": "Nouvelle Notification",
  "addPhonenumber": "Ajouter",
  "editNotification": "Modifier Notification",
  "updateNotification": "Modification de la notification de ''{nom}''",
  "phonenumberLabel": "Numero de telephone",
  "verificationCode": "Code de verification",
  "newPhonenumber": "Ajouter un numero de telephone",
  "edit": "Modifier",
  "phonenumberRequired": "Il faut entrer un numero de telephone",
  "smsSentMessage": "Un SMS avec un code de vérification vous a été envoyé.",
  "smsCodePrompt": "Veuillez saisir le code ci-dessous.",

  "ACCOUNT/add_phonenumber_result_error": "Impossible d'ajouter le numéro de téléphone",

  "ACCOUNT/add_phonenumber_result error": "Impossible d'ajouter le numéro de téléphone",
  "ACCOUNT/add_phonenumber_result_success": "Le numéro de téléphone a été ajouté avec succès",
  "ACCOUNT/verify_phonenumber_result_error": "Impossible de vérifier le numéro de téléphone",
  "ACCOUNT/verify_phonenumber_result_success": "Numéro de téléphone vérifié avec succès",
  "resendSMSCodeCountdown": "Vous pourrez renvoyer une vérification dans {countdown} secondes",
  "resendSMSCodePrompt": "Vous n'avez pas reçu de SMS ?",

  "AMSettings_Validators": "Validateurs",
  "AMSettings_Modal_Title": "Nombre de validateurs d'opérations",
  "AMSettings_Num_Contributors": "Nombre total de validateurs",
  "AMSettings_Min_Required": "Nombre Minimum de Validateurs",
  "AMSettings_Contributor_Placeholder": "Sélectionner validateur",
  "AMAPPROVAL/upsert_config_result_error": "Impossible de mettre à jour la configuration",

  "nickname": "Nickname",
  "affiliateEmail": "Email",
  "affiliateStatus": "Status",
  "affiliateID": "Affiliate ID",
  "affiliates": "Organization Affiliates",
  "searchAffiliates": "Search Affiliates",
  "pending": "Pending",
  "active": "Active",
  "blocked": "Blocked",
  "deleteAffiliate": "Delete Affiliate {affiliateID}",
  "deleteAffiliatePrompt": "Are you sure you want to delete this affiliate {br} You will not be able to view his profile anymore",

  "mintConfiguration": "Configuration d'émission",
  "distributionConfiguration": "Configuration de la distribution",
  "clearing": "Configuration de clearing",
  "mintingValidation": "Validation frappe",
  "assetCreation": "Création d'actifs",
  "offlineMint": "Frappe hors ligne",
  "changeSettings": "Modifier les paramètres",
  "inheritValidation": "Héritage de validation de frappe",
  "generalConfiguration": "Configuration Générale",
  "participantManagement": "Gestion des participants",
  "assetTitle": "Actifs",
  "helpAssetSelect": "Sélectionnez l'actif  que vous souhaitez utiliser pour le service de compensation.",
  "helpDefaultUnit": "L'unité de compte qui sera utilisée pour distribuer les actifs de l'organisation.",
  "selectAsset": "Sélectionner l'actif",
  "selectAccountUnit": "Sélectionnez l'unité de compte",
  "labelUnitAccount": "L'unité de compte(3 maximum)",
  "wholesaleCoin": "Pièces de monnaie stables en gros",
  "defaultUnit": "Unités de comptes par défaut",
  "n/a": "n/a",
  "saveConfig": "Sauvegarder la configuration",
  "adminEmail": "Adresse e-mail de l'administrateur",
  "orgName": "Nom d'organisation",
  "orgId": "ID de l'organisation",
  "searchParticipant": "recherche un  participant",
  "dayStart": "Jour de début",
  "dayEnd": "Jour de fin",
  "24/24": "24/24 Service",
  "unitsAndCurrencies": "Unités/Devises",
  "timeZone": "Fuseau horaire",
  "cycle": "Cycle",
  "titleUpdateSettingsModal": "MISE À JOUR DE LA CONFIGURATION DE COMPENSATION",
  "msgModalConfirm": "Êtes-vous sûr de vouloir mettre à jour la configuration du module de compensation",
  "exchangeRateSettings": "Paramètres de taux de change",
  "updateRates": " Taux de mise à jour",
  "assetName": "Nom de l'actif",
  "displayName": "Nom d'affichage",
  "format": "Formater",
  "assetID": "Identifiant de l'actif",
  "currency": "Devise",
  "requiredCurrency": "La devise est obligatoire",
  "submit": "Soumettre",
  "priceRequired": "Le prix est requis",
  "rateDateRequired": "La date du taux est requise",
  "rateDate": "Date du taux",
  "unitPrice": "Unité. Prix",
  "lastUpdate": "Dernière mise à jour : {date} à {time}",

  "assetsAuthorization": "Autorisation des actifs",
  "updatingClearingConfig": "Mise à jour de la configuration de compensation",
  "updatingClearingConfigBody": "Voulez-vous vraiment mettre à jour la configuration du module de compensation ?",
  "approve": "accepter",
  "organizationID": "ID de l'organization",
  "organizationName": "Nom de l'organization",
  "organizationAbbreviation": "Nom de l'abbreviation",
  "clearingModel": "Modele de compensation",
  "addRow": "Ajouter une ligne",
  "selectModel": "Selectionner un Modele",
  "fullReserve": "Réserve complète",
  "fracReserve": "Réserve fractionnaire",
  "confirmDisable": "Voulez-vous vraiment bloquer ce participant ?",
  "confirmEnable": "Voulez-vous vraiment activer ce participant ?",
  "statusUpdateConfirmation": "Nom du participant",
  "yes": "Oui",
  "no": "Non",
  "modulePending": "En attente d'activation de l'administrateur système",
  "moduleLocked": "Module verrouillé par l'administrateur système",
  "pendingClearingModule": "Veuillez contacter l'administrateur système si votre module est toujours en attente dans les 24h",
  "lockedClearingModule": "Veuillez contacter l'administrateur système si vous pensez qu'il y a une erreur",
  "or": "OR",
  "tapPassword": "Entrer votre mot de passe",
  "signupReward": "Créer un compte (Recompose)",
  "signupBills": "Créer un compte (Facture)",
  "signupFactoring": "Créer un compte (Factorisation)",
  "signupBillsWallets": "Créer un compte (Factures et portefeuilles)",
  "signupClearing": "Créer un compte (Compensation)"
}
