import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover, Typography } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { TranslatedMessage } from '../translations/data'

type Props = {
  className?: string
  popover?: boolean
  context: string
  unitID?: string
}

const UnitsInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & .anticon {
    margin-left: 0.46em;
    color: #51b148;
    font-size: 0.7em;
  }
`

const AssetWithInfo = ({
  className,
  context,
  unitID,
  popover = true,
}: Props) => {
  const name = !!context ? context : <TranslatedMessage id="n/a" />

  return (
    <UnitsInfo className={className}>
      <span>{name} </span>
      {popover && (
        <Popover
          content={
            <Typography.Text code copyable>
              {unitID}
            </Typography.Text>
          }
        >
          <InfoCircleOutlined />
        </Popover>
      )}
    </UnitsInfo>
  )
}

export default AssetWithInfo
