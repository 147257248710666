import * as axios from '../../../axios'
import * as t from 'io-ts'
import {
  Affiliate,
  AffiliateProfile,
  AffiliateSearchParams,
  VerifyAffiliateRequest,
} from './types'

export const verifyAffiliate = (org: string, req: VerifyAffiliateRequest) =>
  axios.put(`/affiliates/1.0/${org}/affiliates`, req, {
    decoder: t.unknown,
  })

export const deleteAffiliate = (org: string, affiliateID: string) =>
  axios.delete_(`/affiliates/1.0/${org}/affiliates/${affiliateID}`, {
    decoder: t.unknown,
  })

export const getOrgAffiliates = (org: string, params: AffiliateSearchParams) =>
  axios.get(`/affiliates/1.0/${org}/affiliates`, {
    decoder: t.type({
      count: t.number,
      data: t.array(Affiliate),
    }),
    params,
  })

export const getProfileByNym = (pk: string) =>
  axios.get(`/dis/dis/1.0/profile/${pk}`, {
    decoder: AffiliateProfile,
  })
