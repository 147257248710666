import { Button, Card, Typography } from 'antd'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { TranslatedMessage } from '../../../shared/translations/data'
import DefaultSignUp from './DefaultSignUp'
import PersonalSignUp from './PersonalSignUp'
import OrganizationSignUp from './OrganizationSignUp'
import { useSignUpMode } from './hooks'
import { useAccount } from '@library/react-toolkit'

const SignUp = () => {
  const signUpMode = useSignUpMode()
  const account = useAccount()

  return (
    <Card bordered={false} title={<TranslatedMessage id="signUp" />}>
      {signUpMode.type === 'default' ? (
        <DefaultSignUp />
      ) : signUpMode.type === 'personal' ? (
        <PersonalSignUp />
      ) : signUpMode.type === 'organization' ? (
        <OrganizationSignUp modules={signUpMode.modules} />
      ) : signUpMode.type === 'sme' ? (
        <OrganizationSignUp modules={['PROSPERBILL']} />
      ) : signUpMode.type === 'entreprise-factoring' ? (
        <OrganizationSignUp modules={['PROSPERBILL']} />
      ) : signUpMode.type === 'entreprise-rewards' ? (
        <OrganizationSignUp modules={['REWARDS']} />
      ) : signUpMode.type === 'entreprise-bills-wallets' ? (
        <OrganizationSignUp modules={['PROSPERBILL', 'WEB_WALLET']} />
      ) : signUpMode.type === 'banks-factoring' ? (
        <OrganizationSignUp modules={['PROSPERBILL']} />
      ) : signUpMode.type === 'banks-clearing' ? (
        <OrganizationSignUp modules={['CLEARING']} />
      ) : (
        <OrganizationSignUp
          modules={['CLEARING', 'ANALYTICS', 'WEB_WALLET', 'ASSET_MANAGEMENT']}
        />
      )}
      {!account && (
        <Typography.Paragraph style={{ textAlign: 'center' }}>
          <TranslatedMessage id="AlreadyHaveAnAccount" />
          <Button type="link" color="primary">
            <Link to="/auth/login">
              <TranslatedMessage id="login" />
            </Link>
          </Button>
        </Typography.Paragraph>
      )}
    </Card>
  )
}

export default SignUp
