import { LeftOutlined } from '@ant-design/icons'
import { Alert, Button, Form, FormProps, Input, Row, Typography } from 'antd'
import { goBack } from 'connected-react-router'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { State } from '../../../shared/state/store'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../shared/translations/data'
import { loadable } from '../../../shared/types'
import { confirmInitRequest } from './types'

const mapStateToProps = ({
  prosperus: { account },
  locale,
  resetPassword: { initRequestResult },
}: State) => ({
  locale,
  loading: loadable.isLoading(account) || loadable.isLoading(initRequestResult),
  error: pipe(
    initRequestResult,
    loadable.caseOf({ Err: () => true, _: () => false })
  ),
  success: loadable.isOk(initRequestResult),
})

const mapDispatchToProps = { goBack, confirmInitRequest }

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
const Header = styled(Typography.Title)`
  text-align: center;
  margin-bottom: 24px;
`
const ResetForm = styled((p: FormProps<{ email: string }>) => (
  <Form<{ email: string }> {...p} />
))`
  & > .ant-alert {
    margin-bottom: 16px;
  }

  display: grid;
  grid-gap: 2px;
  overflow: hidden;
`

const Init = ({
  confirmInitRequest,
  loading,
  locale,
  goBack,
  error,
  success,
}: Props) => (
  <>
    <Header level={4}>
      <TranslatedMessage id="resetPassword" />
    </Header>
    <ResetForm onFinish={confirmInitRequest}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: <TranslatedMessage id="requiredEmail" />,
          },
          {
            type: 'email',
            message: <TranslatedMessage id="invalidEmail" />,
          },
        ]}
      >
        <Input
          placeholder={translatedMessage(locale)('email')}
          disabled={loading}
        />
      </Form.Item>
      {error && (
        <Alert
          message={<TranslatedMessage id="resetRequestFailed" />}
          type="error"
        />
      )}
      {success && (
        <Alert
          message={<TranslatedMessage id="resetRequestSuccess" />}
          type="success"
        />
      )}
      <Row justify="space-between">
        <Button type="link" onClick={goBack}>
          <LeftOutlined style={{ color: '#51b148' }} />
          <TranslatedMessage id="cancel" />
        </Button>
        <Button type="primary" htmlType="submit">
          <TranslatedMessage id="resetPassword" />
        </Button>
      </Row>
    </ResetForm>
  </>
)

export default connect(mapStateToProps, mapDispatchToProps)(Init)
