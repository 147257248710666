import { Card, Tabs } from 'antd'
import { push } from 'connected-react-router'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { TranslatedMessage } from '../../../shared/translations/data'
import BackHome from '../../../shared/widgets/BackHome'
import { ExchangeRatesSettings } from './clearingTabs/ExchangeRatesSettings'
import { GeneralConfiguration } from './clearingTabs/GeneralConfiguration'
import { ParticipantManagement } from './clearingTabs/ParticipantManagement'

export const ClearingModuleSettings = () => {
  const dispatch = useDispatch()
  const { tab } = useParams<{ tab?: string }>()

  return (
    <>
      <BackHome />
      <Card title={<TranslatedMessage id="clearing" />}>
        <Tabs
          onChange={(tabKey) =>
            dispatch(push(`/modules_setting/clearing/${tabKey}`))
          }
          defaultActiveKey={tab ?? 'general-configuration'}
          activeKey={tab ?? 'general-configuration'}
        >
          <Tabs.TabPane
            tab={<TranslatedMessage id="generalConfiguration" />}
            key="general-configuration"
          >
            <GeneralConfiguration />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<TranslatedMessage id="participantManagement" />}
            key="participant-management"
          >
            <ParticipantManagement />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<TranslatedMessage id="exchangeRateSettings" />}
            key="exchange-rate-settings"
          >
            <ExchangeRatesSettings />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </>
  )
}
