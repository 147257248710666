import {
  FailableAction,
  failableActionCreator,
  foldEitherToAction,
  Loading,
  NotRequested,
  Organization,
  PayloadAction,
  payloadActionCreator,
  waitForWorkspace,
  Workspace,
} from '@library/react-toolkit'
import { CancelToken } from 'axios'
import { pipe } from 'fp-ts/lib/function'
import * as api from './api'
import * as effects from 'redux-saga/effects'
import { takeLatest } from '../../../../shared/state/saga'
import {
  AMApprovalConfig,
  AMApprovalConfigBody,
  State,
  TaskInnerType,
} from './types'

export enum ActionsNames {
  upsertAMAConfigAction = 'AMAPPROVAL/UPSERT_CONFIG',
  upsertAMAConfigResultAction = 'AMAPPROVAL/UPSERT_CONFIG_RESULT',
}

export type UpsertAMAConfigAction = PayloadAction<
  ActionsNames.upsertAMAConfigAction,
  AMApprovalConfigBody
>
export const upsertAMAConfigAction =
  payloadActionCreator<UpsertAMAConfigAction>(
    ActionsNames.upsertAMAConfigAction
  )

export type UpsertAMAConfigResultAction = FailableAction<
  ActionsNames.upsertAMAConfigResultAction,
  AMApprovalConfig
>
export const upsertAMAConfigResultAction =
  failableActionCreator<UpsertAMAConfigResultAction>(
    ActionsNames.upsertAMAConfigResultAction
  )

export type Actions = UpsertAMAConfigAction | UpsertAMAConfigResultAction

function* upsertAMAConfigSaga(a: UpsertAMAConfigAction, token: CancelToken) {
  const ws: Workspace = yield waitForWorkspace()
  if (ws?.type !== 'organization') {
    return
  }
  const org: Organization = ws.organization
  const config: TaskInnerType<ReturnType<typeof api.upsertAMConfig>> =
    yield effects.call(
      api.upsertAMConfig(
        { organizationName: org.name, config: a.payload },
        token
      )
    )
  yield effects.put(
    pipe(config, foldEitherToAction(upsertAMAConfigResultAction))
  )
  console.log('saga config :', config)
}

export function* saga() {
  yield effects.all([
    takeLatest(ActionsNames.upsertAMAConfigAction, upsertAMAConfigSaga),
  ])
}

export const initialState: State = {
  config: NotRequested,
}

export const reducer = (s = initialState, a: Actions): State => {
  switch (a.type) {
    case ActionsNames.upsertAMAConfigAction:
      return { ...s, config: Loading }
    case ActionsNames.upsertAMAConfigResultAction:
      return { ...s, config: a.payload }
    default:
      return s
  }
}
