import { MailOutlined, UserOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import { RuleObject } from 'antd/lib/form'
import { either } from 'fp-ts'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  TranslatedMessage,
  translatedMessage,
} from '../../../shared/translations/data'
import { verifyUserExists } from '../Login/api'
import SignUpStep, { Props as StepProps } from './SignUpStep'

type UserInfo = {
  email: string
  firstName: string
  lastName: string
  login: string
}

const checkUsernameValidator = async (_: RuleObject, v: string) => {
  if (either.isRight(await verifyUserExists(v)())) {
    throw 'exists'
  }
}

const checkEmailValidator = async (_: RuleObject, v: string) => {
  if (either.isRight(await verifyUserExists(v)())) {
    throw 'exists'
  }
}

type FormProps<T> = StepProps<T> & { goBack?: () => void }

const SetUserInfo = ({ ...stepProps }: FormProps<UserInfo>) => {
  const locale = useSelector((s) => s.locale)

  return (
    <SignUpStep<UserInfo> {...stepProps}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: 'email',
            message: <TranslatedMessage id="invalidEmail" />,
          },
          {
            message: <TranslatedMessage id="emailexists" />,
            validator: checkEmailValidator,
          },
        ]}
      >
        <Input
          prefix={<MailOutlined />}
          allowClear
          type="email"
          placeholder={translatedMessage(locale)('email')}
        />
      </Form.Item>
      <Form.Item
        name="login"
        rules={[
          {
            required: true,
            message: <TranslatedMessage id="requiredUsername" />,
          },
          {
            max: 50,
            message: <TranslatedMessage id="maxLengthExceeded" />,
          },
          {
            message: <TranslatedMessage id="userexists" />,
            validator: checkUsernameValidator,
          },
          {
            message: <TranslatedMessage id="invalidLogin" />,
            pattern: /^[_.@A-Za-z0-9-]*$/,
          },
        ]}
      >
        <Input
          allowClear
          prefix={<UserOutlined />}
          placeholder={translatedMessage(locale)('username')}
        />
      </Form.Item>
      <Form.Item
        name="firstName"
        rules={[
          {
            required: true,
            message: <TranslatedMessage id="requiredFirstName" />,
          },
          {
            max: 50,
            message: <TranslatedMessage id="maxLengthExceeded" />,
          },
        ]}
      >
        <Input
          allowClear
          placeholder={translatedMessage(locale)('firstName')}
        />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[
          {
            required: true,
            message: <TranslatedMessage id="requiredLastName" />,
          },
          {
            max: 50,
            message: <TranslatedMessage id="maxLengthExceeded" />,
          },
        ]}
      >
        <Input allowClear placeholder={translatedMessage(locale)('lastName')} />
      </Form.Item>
    </SignUpStep>
  )
}

export default SetUserInfo
