"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ContextSelector = require("../ContextSelector");

var HoverContext = (0, _ContextSelector.createContext)();
var _default = HoverContext;
exports.default = _default;