import { Loadable } from '@library/react-toolkit'
import { task } from 'fp-ts'
import * as t from 'io-ts'
import { DateFromISOString, withFallback } from 'io-ts-types'

export const ApprovalConfig = t.intersection(
  [
    t.type({
      createdAt: DateFromISOString,
      updatedAt: DateFromISOString,
      organization: t.string,
      minimum: t.number,
      users: t.array(t.string),
    }),
    t.partial({
      inherit: withFallback(t.boolean, false),
    }),
  ],
  'ApprovalConfig'
)

export type ApprovalConfig = t.TypeOf<typeof ApprovalConfig>

export const AMApprovalTag = t.keyof({ creation: null, minting: null })
export type AMApprovalTag = t.TypeOf<typeof AMApprovalTag>

export const AMApprovalConfigBody = t.type({
  users: t.array(t.string),
  tag: AMApprovalTag,
  minimum: t.number,
  inherit: t.boolean,
})
export type AMApprovalConfigBody = t.TypeOf<typeof AMApprovalConfigBody>

export const AMApprovalConfig = t.type({
  minimum: t.number,
  organization: t.string,
  createdAt: t.string,
  updatedAt: t.string,
})
export type AMApprovalConfig = t.TypeOf<typeof AMApprovalConfig>

export type State = {
  config: Loadable<AMApprovalConfig>
}

export type TaskInnerType<T> = T extends task.Task<infer R> ? R : never
