import { Button } from 'antd'
import * as React from 'react'
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogin,
} from 'react-google-login'
import { useDispatch, useSelector } from 'react-redux'

import { State } from '../../../shared/state/store'
import { TranslatedMessage } from '../../../shared/translations/data'
import { loadable } from '../../../shared/types'
import gLogo from './GLogo.svg'
import { loginWithGoogleAction } from './types'

const isLoginReposne = (
  resp: GoogleLoginResponse | GoogleLoginResponseOffline
): resp is GoogleLoginResponse => !resp.code

const GoogleLogin = () => {
  const loading = useSelector((s: State) =>
    loadable.isLoading(s.account.loginResult)
  )
  const dispatch = useDispatch()
  const { signIn } = useGoogleLogin({
    onSuccess: (resp) => {
      if (!isLoginReposne(resp)) {
        // TODO: indicate failure.
        return
      }
      dispatch(
        loginWithGoogleAction({
          googleID: resp.getAuthResponse().id_token,
          grant_type: 'google_credentials',
        })
      )
    },
    onFailure: (err) => {
      // TODO: indicate failure.
      console.error(err)
    },
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
  })

  return (
    <Button
      onClick={signIn}
      loading={loading}
      style={{ minWidth: 300, justifyContent: 'center' }}
      icon={
        <span className="anticon">
          <img
            style={{ height: '1em', width: '1em', verticalAlign: 'unset' }}
            src={gLogo}
          />
        </span>
      }
    >
      <span>
        <TranslatedMessage id="googleLogin" />
      </span>
    </Button>
  )
}

export default GoogleLogin
