import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Popover, Typography } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { useParticpant } from '../../components/Account/modulesSettings/clearingTabs/queries'
import { TranslatedMessage } from '../translations/data'

type Props = {
  className?: string
  popover?: boolean
  context: { displayName: string; name: string; logoUrl: string }
}

const OrganisationInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & .anticon {
    margin-left: 0.46em;
  }
`

const OrgNameWithInfo = ({ className, context, popover = true }: Props) => {
  const orgInfo = !!context
    ? context
    : { displayName: '', name: '', logoUrl: '' }
  const participantQuery = useParticpant(orgInfo.name)
  const image =
    orgInfo.logoUrl !== '' ? `/images/uaa/${orgInfo.logoUrl}` : undefined
  return (
    <OrganisationInfo className={className}>
      <Avatar
        src={image}
        icon={image === undefined ? <UserOutlined /> : null}
      />
      <span style={{ marginLeft: '16px' }}>{orgInfo.displayName} </span>
      {popover && (
        <Popover
          content={
            <Typography.Text code copyable>
              {participantQuery.data?.nym || <TranslatedMessage id="n/a" />}
            </Typography.Text>
          }
        >
          <InfoCircleOutlined />
        </Popover>
      )}
    </OrganisationInfo>
  )
}

export default OrgNameWithInfo
