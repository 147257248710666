import React from 'react'
import { Form, Switch } from 'antd'
import { TranslatedMessage } from '../../../shared/translations/data'
import SignUpStep, { Props as StepProps } from './SignUpStep'
type FormProps<T> = StepProps<T> & { goBack?: () => void }

const SetWallet = ({
  goBack,
  ...stepProps
}: FormProps<{ withWallet: boolean }>) => (
  <SignUpStep<{ withWallet: boolean }> {...stepProps} goBack={goBack}>
    <Form.Item name="withWallet" label={<TranslatedMessage id="withWallet" />}>
      <Switch checked={stepProps.initialValue.withWallet} />
    </Form.Item>
  </SignUpStep>
)

export default SetWallet
