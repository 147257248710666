import React from 'react'
import { Alert, Divider, Result, Steps } from 'antd'
import { TranslatedMessage } from '../../../shared/translations/data'
import SetUserInfo from './SetUserInfo'
import SetOrganization from './SetOrganization'
import SetPassword from './SetPassword'
import { useDispatch, useSelector } from 'react-redux'
import { signUpAction, SignUp as SignUpData } from '../Login'
import { loadable, memoed, Module, Organization } from '@library/react-toolkit'
import { pipe } from 'fp-ts/lib/function'
import { array, option, record } from 'fp-ts'

type Step = 0 | 1 | 2 | 3

const nextStep: Record<Step, Step> = { 0: 1, 1: 2, 2: 3, 3: 3 }
const prevStep: Record<Step, Step> = { 0: 0, 1: 0, 2: 1, 3: 2 }

const incrementStep = (s: Step): Step => nextStep[s]
const decrementStep = (s: Step): Step => prevStep[s]

type OrganizationData = Pick<
  Organization,
  'name' | 'displayName' | 'rib' | 'asset' | 'modules'
>

type FormData = {
  organization: OrganizationData
} & Omit<SignUpData, 'organization'>

const OrganizationSignUpWithoutAccount = ({
  modules,
}: {
  modules: Module[]
}) => {
  const dispatch = useDispatch()
  const locale = useSelector((s) => s.locale)
  const units = useSelector((s) => memoed.extract(s.units))
  const failed = useSelector((s) => loadable.isErr(s.account.signUpResult))
  const success = useSelector((s) => loadable.isOk(s.account.signUpResult))
  console.log(modules, 'ff')
  const tndUnitID = pipe(
    units,
    record.filter(
      (u) =>
        u.name.toLowerCase().indexOf('tnd') !== -1 ||
        u.symbol.toLowerCase().indexOf('tnd') !== -1
    ),
    record.keys,
    array.head,
    option.toUndefined
  )

  const [step, setStep] = React.useState<Step>(0)
  const [user, setUser] = React.useState<FormData>({
    email: '',
    login: '',
    password: '',
    firstName: '',
    lastName: '',
    withWallet: false,
    organization: {
      name: '',
      displayName: '',
      rib: '',
      asset: '',
      modules: modules,
    },
  })

  React.useEffect(() => {
    if (!!tndUnitID) {
      setUser((u) => ({
        ...u,
        organization: { ...u.organization, asset: tndUnitID },
      }))
    }
  }, [tndUnitID])
  React.useEffect(() => {
    setUser((u) => ({
      ...u,
      organization: { ...u.organization, modules: modules },
    }))
  }, [modules])

  React.useEffect(() => {
    if (success) {
      setStep(3)
    }
  }, [success])

  const onSubmit = (v: Partial<FormData>) => {
    setUser((u) => ({ ...u, ...v }))
    if (step === 2) {
      user.organization.displayName = user.organization.name
      dispatch(
        signUpAction({
          ...user,

          organization: {
            ...user.organization,
            // @ts-ignore
            modules: modules,
            email: user.email,
          },
          ...v,
        })
      )
    } else {
      setStep(incrementStep)
    }
  }

  const goBack = () => setStep(decrementStep)

  const onChange = (v: Partial<FormData>) => setUser((u) => ({ ...u, ...v }))

  const stepProps = { onSubmit, onChange, locale, initialValue: user }

  return (
    <>
      <Steps progressDot current={step} size="small" style={{ color: 'green' }}>
        <Steps.Step title={<TranslatedMessage id="setUserInfo" />} />
        <Steps.Step title={<TranslatedMessage id="setOrganization" />} />
        <Steps.Step title={<TranslatedMessage id="setPassword" />} />
        <Steps.Step title={<TranslatedMessage id="done" />} />
      </Steps>
      <Divider />
      {step === 0 ? (
        <SetUserInfo {...stepProps} />
      ) : step === 1 ? (
        <SetOrganization {...stepProps} goBack={goBack} units={units} />
      ) : step === 2 ? (
        <SetPassword {...stepProps} goBack={goBack} />
      ) : (
        <Result
          status="success"
          title={<TranslatedMessage id="signUpSuccessTitle" />}
          subTitle={<TranslatedMessage id="signUpSuccessDescription" />}
        />
      )}
      {failed && (
        <Alert message={<TranslatedMessage id="signupFailed" />} type="error" />
      )}
    </>
  )
}

export default OrganizationSignUpWithoutAccount
