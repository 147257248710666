"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var TabPane = function TabPane() {
  return null;
};
if (process.env.NODE_ENV !== 'production') {
  TabPane.displayName = 'DeprecatedTabPane';
}
var _default = TabPane;
exports["default"] = _default;