import {
  AnimatedMenu,
  Header,
  OrganizationPermission,
  useAccount,
  useCurrentWorkspace,
} from '@library/react-toolkit'
import { Menu } from 'antd'
import { push } from 'connected-react-router'
import { record, string } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { matchPath, useLocation } from 'react-router'
import styled from 'styled-components'
import { TranslatedMessage } from '../translations/data'

const Nav = styled(Menu)`
  width: 85%;
  height: ${(props) => props.theme.headerHeight}px;
  background: ${(props) => props.theme.colors.primary};
  border: 0;
  & > li.ant-menu-item.ant-menu-item-selected,
  & > li.ant-menu-item.ant-menu-item-selected a,
  & > li.ant-menu-item.ant-menu-item-active,
  & > li.ant-menu-item.ant-menu-item-active a,
  & > li.ant-menu-submenu.ant-menu-item-selected,
  & > li.ant-menu-submenu.ant-menu-submenu-selected,
  & > li.ant-menu-submenu.ant-menu-submenu-active {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
  & > li.ant-menu-item,
  & > li.ant-menu-item a,
  & > li.ant-menu-submenu {
    vertical-align: top;
    top: 0px;
    margin: 0 !important;
    height: ${(props) => props.theme.headerHeight}px;
    line-height: ${(props) => props.theme.headerHeight}px;
    font-size: 18px;
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    & > .anticon {
      font-size: 18px;
    }
  }
  & > li.ant-menu-item {
    padding: 0 20px !important;
  }
  & > li.ant-menu-submenu > div {
    padding: 0 20px !important;
  }
`

const pageLookup = {
  '/organization_setting': 'organization',
  '/modules_setting/mint': 'modules',
  '/modules_setting/distribution': 'distribution',
  '/modules_setting/clearing': 'clearing',
}

const pathLookup = pipe(
  pageLookup,
  record.collect(string.Ord)((k, v): [string, string] => [v, k]),
  record.fromEntries
)

const usePage = () => {
  const location = useLocation()

  for (const path in pageLookup) {
    if (matchPath(location.pathname, { path })) {
      return pageLookup[path]
    }
  }

  return 'lost'
}

const OrganizationSettingsBasePath = '/organization_setting'
const ModulesSettingsBasePath = '/modules_setting'

const NavMenu = () => {
  const dispatch = useDispatch()
  const page = usePage()
  const [isHoveringInvoices, setIsHoveringInvoices] = React.useState(false)

  const filterWorkspaces = (w: OrganizationPermission) => {
    return w.modulePermissions.SMART_HUB === undefined
  }

  const ws = useCurrentWorkspace()
  const account = useAccount()
  const isRootAdmin =
    ws?.type === 'organization' &&
    ws.organization.name === 'PROSPERUS' &&
    account?.organizations[ws.organization.name].authority === 'ADMIN'

  return (
    <>
      <Header
        logo="Accounts"
        goToPersonalSettings={() => dispatch(push('/personal_setting'))}
        goToOrganizationSettings={() => dispatch(push('/organization_setting'))}
        goToModulesSettings={() => dispatch(push('/modules_setting/mint'))}
        filterWorkspaces={filterWorkspaces}
        excludedModules={['SMART_HUB']}
      >
        {(location.pathname.includes(OrganizationSettingsBasePath) ||
          location.pathname.includes(ModulesSettingsBasePath)) && (
          <Nav
            mode="horizontal"
            selectedKeys={[page]}
            onSelect={({ key }) => {
              const path = pathLookup[key]
              if (!!path) {
                dispatch(push(path))
              }
            }}
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Menu.Item
              key="organization"
              onMouseOver={() => {
                setIsHoveringInvoices(false)
              }}
            >
              <TranslatedMessage id="organizationSettings" />
            </Menu.Item>
            <Menu.Item
              key="modules"
              onMouseOver={() => {
                setIsHoveringInvoices(true)
              }}
            >
              <TranslatedMessage id="modulesSettings" />
            </Menu.Item>
          </Nav>
        )}
      </Header>
      <AnimatedMenu
        visible={isHoveringInvoices}
        duration={100}
        selectedKeys={[page]}
        onSelect={({ key }) => {
          const path = pathLookup[key]
          if (!!path) {
            dispatch(push(path))
          }
        }}
      >
        <Menu.Item key="modules">
          <TranslatedMessage id="mintConfiguration" />
        </Menu.Item>
        <Menu.Item key="distribution">
          <TranslatedMessage id="distributionConfiguration" />
        </Menu.Item>
        {isRootAdmin && (
          <Menu.Item key="clearing">
            <TranslatedMessage id="clearing" />
          </Menu.Item>
        )}
      </AnimatedMenu>
    </>
  )
}

export default NavMenu
