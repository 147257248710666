import * as React from 'react'
import moment from 'moment'
import _ from 'moment-timezone'
import { Select } from 'antd'
const { Option, OptGroup } = Select

type TimezoneProps = {
  value: string
  onChange?: (value: string) => void
  disabled: boolean
}

const TimezoneSelect = ({ value, onChange, disabled }: TimezoneProps) => {
  const mappedValues = {}
  const regions: string[] = []
  const timezones = moment.tz.names()
  timezones.map((timezone) => {
    const splitTimezone = timezone.split('/')
    const region = splitTimezone[0]
    if (!mappedValues[region]) {
      mappedValues[region] = []
      regions.push(region)
    }
    mappedValues[region].push(timezone)
  })

  return (
    <Select
      style={{ width: '200px' }}
      defaultValue={value}
      showSearch
      onSearch={(v) => timezones.includes(v)}
      onChange={(v) => onChange?.(v)}
      disabled={disabled}
    >
      {regions.map((region) => {
        return (
          <OptGroup
            key={region}
            title={<span style={{ fontSize: 30 }}>{region}</span>}
          >
            {mappedValues[region].map((timezone: string) => {
              return <Option key={timezone}>{timezone}</Option>
            })}
          </OptGroup>
        )
      })}
    </Select>
  )
}

export default TimezoneSelect
