import { useAccount, useCurrentWorkspace } from '@library/react-toolkit'
import { Button, Layout, Result } from 'antd'
import * as React from 'react'
import { Route, Switch } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import AccountApps from '../../components/Account/AccountApps'
import { ClearingModuleSettings } from '../../components/Account/modulesSettings/ClearingModuleSettings'
import { DistributionModuleSettings } from '../../components/Account/modulesSettings/DistributionModuleSettings'
import { MintModuleSettings } from '../../components/Account/modulesSettings/MintModuleSettings'
import OrganizationSetting from '../../components/Account/organisationSettings/OrganizationSetting'
import PersonalSetting from '../../components/Account/personalSettings/personalSetting'
import { TranslatedMessage } from '../translations/data'
import NavMenu from './NavMenu'

const Content = styled(Layout.Content)`
  padding: 48px 40px;
`

const MainLayout = () => {
  const ws = useCurrentWorkspace()
  const account = useAccount()
  const isRootAdmin =
    ws?.type === 'organization' &&
    ws.organization.name === 'PROSPERUS' &&
    account?.organizations[ws.organization.name].authority === 'ADMIN'

  console.log(isRootAdmin)
  return (
    <Layout>
      <NavMenu />
      <Content>
        <Switch>
          <Route path="/" exact component={AccountApps} />
          <Route path="/personal_setting/:tab?" component={PersonalSetting} />
          <Route
            path="/organization_setting/:tab?"
            component={OrganizationSetting}
          />
          <Route
            path="/modules_setting/mint/:tab?"
            component={MintModuleSettings}
          />
          <Route
            path="/modules_setting/distribution"
            component={DistributionModuleSettings}
          />
          {isRootAdmin && (
            <Route
              path="/modules_setting/clearing/:tab?"
              component={ClearingModuleSettings}
            />
          )}
          <Route>
            <Result
              status="warning"
              title="404"
              subTitle={<TranslatedMessage id="pageNotFound" />}
              extra={
                <Button type="primary">
                  <Link to="/">
                    <TranslatedMessage id="goToHomePage" />
                  </Link>
                </Button>
              }
            />
          </Route>
        </Switch>
      </Content>
    </Layout>
  )
}

export default MainLayout
