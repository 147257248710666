import { SignUpMode } from '../Login'
import { Module } from '@library/react-toolkit'
import { pipe } from 'fp-ts/lib/function'
import { array } from 'fp-ts'
import { useSearchParams } from '../../../shared/types'

const parseModules = (modules: string[] | string | undefined): Module[] => {
  if (!modules) {
    return ['PROSPERBILL', 'WEB_WALLET']
  }

  const xs = modules instanceof Array ? modules : [modules]

  return pipe(xs, array.filter(Module.is))
}

const signUpModeFromSearchParams = (
  params: Record<string, string | string[]>
): SignUpMode => {
  switch (params.type) {
    case 'personal':
      return { type: 'personal' }
    case 'organization':
      return {
        type: 'organization',
        modules: parseModules(params.modules ?? params.module),
      }
    case 'clearingOrg':
      return {
        type: 'clearingOrg',
        modules: parseModules(params.modules ?? params.module),
      }
    default:
      return { type: 'default' }
  }
}

export const useSignUpMode = () => {
  const params = useSearchParams()
  return signUpModeFromSearchParams(params)
}
