import { TranslatedMessage } from '../../../shared/translations/data'
import { Switch, Table } from 'antd'
import * as React from 'react'
import Search from 'antd/lib/input/Search'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import {
  useDeleteAffiliate,
  useOrgAffiliates,
  useVerifyAffiliate,
} from './queries'
import ViaProfile from './ViaProfile'
import AffiliateStatus from './AffiliateStatus'
import { AffiliateSearchParams } from './types'
import { DeleteOutlined } from '@ant-design/icons'
import modal from 'antd/lib/modal'

const AffiliatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const SearchAffilates = styled(Search)`
  width: 200px;
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0px 27px;
`

const Affiliates = () => {
  const intl = useIntl()

  const [query, setQuery] = React.useState<AffiliateSearchParams>({
    page: 0,
    itemsPerPage: 10,
  })

  const affiliatesQuery = useOrgAffiliates(query)

  const data =
    affiliatesQuery.data?.data?.map((af) => ({ ...af, key: af.affiliateID })) ??
    []

  const verifyAffiliateMutation = useVerifyAffiliate()

  const deleteAffiliateMutation = useDeleteAffiliate()
  return (
    <AffiliatesWrapper>
      <SearchAffilates
        placeholder={intl.formatMessage({ id: 'searchAffiliates' })}
      />
      <Table
        dataSource={data}
        loading={affiliatesQuery.isLoading}
        columns={[
          {
            title: <TranslatedMessage id="nickname" />,
            key: 'name',
            align: 'left',
            render: (_, o) => (
              <ViaProfile nym={o.nym} selector={(p) => p.nickname ?? ''} />
            ),
          },
          {
            title: <TranslatedMessage id="affiliateEmail" />,
            key: 'email',
            align: 'left',
            render: (_, o) => (
              <ViaProfile nym={o.nym} selector={(p) => p.emailAddress ?? ''} />
            ),
          },
          {
            title: <TranslatedMessage id="affiliateStatus" />,
            key: 'status',
            align: 'left',
            render: (_, o) => <AffiliateStatus status={o.status} />,
          },
          {
            title: <TranslatedMessage id="affiliateID" />,
            key: 'affiliateID',
            align: 'left',
            render: (_, o) => o.affiliateID,
          },
          {
            title: <TranslatedMessage id="actions" />,
            key: 'actions',
            align: 'left',
            width: '10%',
            render: (_, o) => (
              <ActionsWrapper>
                <Switch
                  defaultChecked={o.status === 'active'}
                  onChange={(v) => {
                    verifyAffiliateMutation.mutate({
                      affiliateID: o.affiliateID,
                      verified: v,
                    })
                  }}
                />
                <DeleteOutlined
                  style={{ color: 'red', fontSize: 20 }}
                  onClick={() => {
                    modal.confirm({
                      title: intl.formatMessage(
                        { id: 'deleteAffiliate' },
                        { affiliateID: o.affiliateID }
                      ),
                      content: intl.formatMessage(
                        {
                          id: 'deleteAffiliatePrompt',
                        },
                        { br: <br /> }
                      ),
                      okText: intl.formatMessage({ id: 'confirm' }),
                      cancelText: intl.formatMessage({ id: 'cancel' }),
                      onOk: () => deleteAffiliateMutation.mutate(o.affiliateID),
                    })
                  }}
                />
              </ActionsWrapper>
            ),
          },
        ]}
        pagination={{
          current: query.page + 1,
          pageSize: query.itemsPerPage,
          pageSizeOptions: ['10', '20', '50'],
          onChange: (p, ipp) =>
            setQuery((q) => ({
              ...q,
              page: p - 1,
              itemsPerPage: ipp ?? 10,
            })),
          total: affiliatesQuery.data?.count,
        }}
      />
    </AffiliatesWrapper>
  )
}

export default Affiliates
