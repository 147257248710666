import React from 'react'
import { UnitInfo } from '../../../shared/state/Units'
import {
  Locale,
  translatedMessage,
  TranslatedMessage,
} from '../../../shared/translations/data'
import SignUpStep, { Props as StepProps } from './SignUpStep'
import { SignUp as SignUpData } from '../Login'
import { Form, Input, Select } from 'antd'
import {
  verifyOrganizationAbbreviationExists,
  verifyOrganizationNameExists,
} from '../Login/api'
import { array, either, ord, record } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import { useIntl } from 'react-intl'

type FormProps<T> = StepProps<T> & { locale: Locale; goBack?: () => void }

type UserOrganization = Pick<SignUpData, 'organization'>

const ordBySnd: ord.Ord<[unknown, string]> = pipe(
  ord.ordString,
  ord.contramap((v) => v[1])
)

function SetOrganization<T extends UserOrganization>({
  locale,
  goBack,
  skip,
  units,
  ...stepProps
}: FormProps<T> & { units: Record<string, UnitInfo> }) {
  const intl = useIntl()
  return (
    <SignUpStep<T> {...stepProps} goBack={goBack} skip={skip}>
      <Form.Item
        name={['organization', 'name']}
        rules={[
          {
            pattern: new RegExp(/^[\w]+$/),
            message: <TranslatedMessage id="onlyAlphanumeric" />,
          },
          {
            required: true,
            message: <TranslatedMessage id="missingCompanyNameMsg" />,
          },
          {
            message: <TranslatedMessage id="OrganizationNameExist" />,
            validator: (_, v) =>
              verifyOrganizationNameExists(v)().then(
                either.fold(
                  () => {},
                  () => {
                    throw 'exists'
                  }
                )
              ),
          },
        ]}
      >
        <Input
          allowClear
          placeholder={translatedMessage(locale)('organizationName')}
        />
      </Form.Item>
      <Form.Item
        name={['organization', 'abbreviation']}
        rules={[
          {
            pattern: new RegExp(/^[A-Z]+$/),
            message: <TranslatedMessage id="onlyAlphabets" />,
          },
          {
            required: true,
            message: <TranslatedMessage id="missingCompanyAbbreviationMsg" />,
          },
          {
            message: <TranslatedMessage id="OrganizationAbbreviationExist" />,
            validator: (_, v) =>
              verifyOrganizationAbbreviationExists(v)().then(
                either.fold(
                  () => {},
                  () => {
                    throw 'exists'
                  }
                )
              ),
          },
        ]}
      >
        <Input
          allowClear
          minLength={2}
          maxLength={4}
          onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
            (event.target.value = event.target.value.toUpperCase())
          }
          placeholder={intl.formatMessage({ id: 'organizationAbbreviation' })}
        />
      </Form.Item>
      <Form.Item
        name={['organization', 'rib']}
        rules={[
          {
            required: true,
            message: <TranslatedMessage id="missingRIBMsg" />,
          },
          {
            len: 20,
            message: <TranslatedMessage id="invalidRIB" />,
          },
          {
            pattern: new RegExp(/^[\d]+$/),
            message: <TranslatedMessage id="invalidRIBFormat" />,
          },
        ]}
      >
        <Input allowClear placeholder={translatedMessage(locale)('RIB')} />
      </Form.Item>
      <Form.Item
        name={['organization', 'asset']}
        label={<TranslatedMessage id="asset" />}
        rules={[
          {
            required: true,
            message: <TranslatedMessage id="defaultUnitRequired" />,
          },
        ]}
      >
        <Select>
          {pipe(
            units,
            record.map((u) => `${u.name} ${u.symbol}`),
            record.toArray,
            array.sort(ordBySnd),
            array.map((v) => (
              <Select.Option value={v[0]} key={v[0]}>
                {v[1]}
              </Select.Option>
            ))
          )}
        </Select>
      </Form.Item>
    </SignUpStep>
  )
}

export default SetOrganization
