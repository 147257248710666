import { useCurrentWorkspace, useOrganization } from '@library/react-toolkit'
import { pipe } from 'fp-ts/lib/function'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { updateOrganizationModulesAction } from '../../../../shared/state/organization'
import { throwLeft } from '../../affiliatesSettings/queries'
import * as api from './api'
import { AMApprovalConfigBody, AMApprovalTag } from './types'

export const useGetApprovalConfig = (tag: AMApprovalTag) => {
  const org = useOrganization()
  return useQuery(
    [org?.name ?? '', '-get-approval-config-', tag],
    pipe(api.getApprovalConfig(org?.name ?? '', tag), throwLeft),
    { enabled: !!org, keepPreviousData: true }
  )
}

export const useUpsertApprovalConfig = (tag: AMApprovalTag) => {
  const dispatch = useDispatch()
  const org = useOrganization()
  const queryClient = useQueryClient()
  const ws = useCurrentWorkspace()
  const modules = ws?.type === 'organization' ? ws.organization.modules : []
  modules.includes('ASSET_MANAGEMENT') ?? modules.push('ASSET_MANAGEMENT')

  return useMutation(
    ['UpsertAMConfig-', tag, org?.name],
    (config: AMApprovalConfigBody) =>
      pipe(
        api.upsertAMConfig({
          organizationName: org?.name ?? '',
          config: config,
        }),
        throwLeft
      )(),
    {
      onSuccess: () => {
        dispatch(updateOrganizationModulesAction({ ...org!, modules }))
        queryClient.invalidateQueries(`${org?.name}-get-approval-config-${tag}`)
      },
    }
  )
}
