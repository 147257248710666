import { OrganizationUser, UAAAccount } from '@library/react-toolkit'
import { CancelToken } from 'axios'
import { taskEither } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'

import * as axios from '../../axios'
import { getAccount } from '../Authentication/Login/api'
import {
  BalanceTrigger,
  Nullable,
  PasswordForm,
  PhonenumberRequest,
  UserPermissions,
  WalletsSearchRequest,
} from './types'

const apiUrl = '/uaa/api/account'

export const getAccountApi = (cancelToken?: CancelToken) =>
  getAccount(cancelToken)

export const updateAccount = (account: UAAAccount, cancelToken?: CancelToken) =>
  pipe(
    axios.post(`${apiUrl}`, account, {
      cancelToken,
      encoder: UAAAccount,
      decoder: t.unknown,
    }),
    // TODO: maybe the UAA server should return this value instead.
    taskEither.chain(() => getAccount(cancelToken))
  )

export const changePassword = (req: PasswordForm, cancelToken?: CancelToken) =>
  pipe(
    axios.post(`${apiUrl}/change-password`, req, {
      cancelToken,
      decoder: t.unknown,
    }),
    taskEither.map(() => undefined)
  )

export const getOrganizationsUsers = (
  organizationName: string,
  { page = 0, itemsPerPage = 10, search = '' },
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/uaa/api/organizations/${organizationName}/users`, {
    cancelToken,
    params: { page, itemsPerPage, search },
    decoder: t.type({
      data: t.array(OrganizationUser),
      total: t.number,
    }),
  })

export const addOrganizationUser = (
  organizationName: string,
  user: OrganizationUser,
  cancelToken?: axios.CancelToken
) =>
  axios.post(`/uaa/api/organizations/${organizationName}/users`, user, {
    cancelToken,
    encoder: OrganizationUser,
    decoder: t.array(OrganizationUser),
  })

export const putOrganizationsUsers = (
  organizationName: string,
  { login, ...user }: UserPermissions,
  cancelToken?: axios.CancelToken
) =>
  axios.put(`/uaa/api/organizations/${organizationName}/users/${login}`, user, {
    cancelToken,
    decoder: t.unknown,
  })

export const deleteOrganizationUser = (
  organizationName: string,
  login: string,
  cancelToken?: axios.CancelToken
) =>
  axios.delete_(`/uaa/api/organizations/${organizationName}/users/${login}`, {
    cancelToken,
    decoder: t.unknown,
  })

export const uploadFile = (file: FormData, cancelToken?: axios.CancelToken) =>
  axios.post(`${apiUrl}/upload`, file, {
    cancelToken,
    decoder: t.unknown,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const getFile = (imageUrl: string, cancelToken?: axios.CancelToken) =>
  axios.get(`${apiUrl}/picture/${imageUrl}`, {
    cancelToken,
    decoder: t.unknown,
  })
export const getOrganizationWallets = (
  {
    organizationName,
    itemsPerPage = 10,
    page = 0,
    ...rest
  }: { organizationName: string } & Partial<Nullable<WalletsSearchRequest>>,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/api/web-wallet/wallets/${organizationName}`, {
    cancelToken,
    params: { itemsPerPage, page, ...rest },
    decoder: t.any,
  })

export const getBalanceTriggers = (
  page = 0,
  itemsPerPage = 10,
  cancelToken?: axios.CancelToken
) =>
  axios.get('/ons/1.0/balance-triggers', {
    params: { itemsPerPage, page },
    cancelToken,
    decoder: t.type({
      data: t.array(BalanceTrigger),
      count: t.number,
    }),
  })

export const addBalanceTrigger = (
  trigger: BalanceTrigger,
  cancelToken?: axios.CancelToken
) =>
  axios.post('/ons/1.0/balance-triggers', trigger, {
    decoder: t.unknown,
    cancelToken,
  })

export const updateBalanceTrigger = (
  { uuid, ...trigger }: BalanceTrigger,
  cancelToken?: axios.CancelToken
) =>
  axios.put(`/ons/1.0/balance-triggers/${uuid}`, trigger, {
    decoder: t.unknown,
    cancelToken,
  })

export const deleteBalanceTrigger = (
  uuid: string,
  cancelToken?: axios.CancelToken
) =>
  axios.delete_(`/ons/1.0/balance-triggers/${uuid}`, {
    cancelToken,
    decoder: t.unknown,
  })

export const addPhonenumber = (
  req: PhonenumberRequest,
  cancelToken?: axios.CancelToken
) =>
  axios.put('/uaa/api/account/add-phonenumber', req, {
    decoder: t.unknown,
    cancelToken,
  })

export const verifyPhonenumber = (
  req: PhonenumberRequest,
  cancelToken?: axios.CancelToken
) =>
  axios.post('/uaa/api/account/verify-phonenumber', req, {
    cancelToken,
    decoder: t.unknown,
  })

export const resendPhonenumberVerification = (
  req: { phonenumber: string },
  cancelToken?: axios.CancelToken
) =>
  axios.post('/uaa/api/account/resend-verification', req, {
    cancelToken,
    decoder: t.unknown,
  })
export const getOrganizationStatus = (
  org: string,
  cancelToken?: axios.CancelToken
) =>
  axios.get(`/prosper-clear/api/settings/${org}/verify`, {
    decoder: t.string,
    cancelToken,
  })

export const uploadOrgImage = (org: string, file: FormData) =>
  axios.post(`/uaa/api/organizations/${org}/upload`, file, {
    decoder: t.unknown,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
