import {
  Authority,
  Memoed,
  ModulePermissions,
  Organization,
  OrganizationUser,
  UAAAccount,
  WalletPermissions,
  Module,
  applicationDefinitions,
} from '@library/react-toolkit'
import { LocationChangeAction } from 'connected-react-router'
import { option, record, string } from 'fp-ts'
import { Predicate, pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import {
  FailableAction,
  failableActionCreator,
  Loadable,
  loadable,
  PayloadAction,
  payloadActionCreator,
} from '../../shared/types'

export type PasswordForm = {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

export const emptyPasswordForm: PasswordForm = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
}

export const emptyOrganization: Organization = {} as Organization

export type ImageType = { file: any }

export type PhonenumberRequest = { phonenumber: string; code: string }

export type State = {
  updateResult: Loadable<UAAAccount>
  changePasswordResult: Loadable<unknown>
  organizationUsers: Loadable<{ data: OrganizationUser[]; total: number }>
  selectedFile: option.Option<File>
  uploadImageResult: Loadable<unknown>
  organizationUpdateResult: Loadable<unknown>
  wallets: Loadable<Wallets>
  notificationModal: Loadable<unknown>
  balanceTriggers: Memoed<{ data: BalanceTrigger[]; count: number }>
  addBalanceTriggerResult: Loadable<unknown>
  updateBalanceTriggerResult: Loadable<unknown>
  deleteBalanceTriggerResult: Loadable<unknown>
  addPhonenumberResult: Loadable<unknown>
  verifyPhonenumberResult: Loadable<unknown>
}

export const Authorities = t.keyof({
  /* eslint-disable @typescript-eslint/naming-convention */
  ROLE_USER: null,
  ROLE_ADMIN: null,
  ROLE_SUPER_ADMIN: null,
  /* eslint-enable @typescript-eslint/naming-convention */
})

type Authorities = t.TypeOf<typeof Authorities>

export type Application = {
  name: Module
  shouldRender?: Predicate<{
    organization: string
    account: Loadable<UAAAccount>
  }>
  href: string
  icon: Module
  descriptionKey:
    | 'backofficeDescription'
    | 'dashboardDescription'
    | 'webwalletDescription'
    | 'billsDescription'
    | 'marketplaceDescription'
}

const canView =
  (m: Module) =>
  ({
    organization,
    account,
  }: {
    organization: string
    account: Loadable<UAAAccount>
  }) =>
    pipe(
      loadable.toOption(account),
      option.chain((a) => record.lookup(organization, a.organizations)),
      option.chain((auth) => record.lookup(m, auth.modulePermissions)),
      option.isSome
    )

export const WalletsSearchRequest = t.type({
  page: t.number,
  itemsPerPage: t.number,
  unit: t.string,
})

export type WalletsSearchRequest = t.TypeOf<typeof WalletsSearchRequest>

export const BalanceData = t.partial({
  amount: t.number,
  assetName: t.string,
  formatted: t.string,
})
export type BalanceData = t.TypeOf<typeof BalanceData>

export const Balance = t.record(t.string, BalanceData)
export type Balance = t.TypeOf<typeof Balance>

export const WalletsData = t.type({
  nym: t.string,
  firstName: t.string,
  lastName: t.string,
  createdAt: t.number,
  balance: Balance,
})
export type WalletsData = t.TypeOf<typeof WalletsData>

export const Wallets = t.intersection([
  t.type({
    data: t.array(WalletsData),
    total: t.number,
  }),
  t.partial({
    WalletsSearchRequest,
  }),
])
export type Wallets = t.TypeOf<typeof Wallets>

export const emptyWallets: Wallets = {
  data: [],
  total: 0,
}
export const emptyWallet: WalletsData = {} as WalletsData

export const BalanceTrigger = t.type({
  uuid: t.string,
  name: t.string,
  nym: t.string,
  asset: t.string,
  amount: t.number,
  type: t.keyof({ Min: null, Max: null }),
  channel: t.keyof({ SMS: null, Email: null }),
})
export type BalanceTrigger = t.TypeOf<typeof BalanceTrigger>

export const applications = pipe(
  applicationDefinitions,
  record.collect(string.Ord)((app, def) => ({
    ...def,
    name: app,
    shouldRender: canView(app),
    descriptionKey: `${app}-description` as `${typeof app}-description`,
  }))
)

export type UserPermissions = {
  login: string
  modulePermissions: ModulePermissions
  walletPermissions: WalletPermissions
  authority: Authority
  jobTitle?: string
}
export type Nullable<T extends object> = { [K in keyof T]: T[K] | null }

export enum ActionsNames {
  getWalletsAction = 'WALLETS/GET_WALLETS',
  getWalletsResultAction = 'WALLETS/GET_WALLETS_RESULT',
}
export type GetOrganizationUsers = PayloadAction<
  'ACCOUNT/get_organization_users',
  { page: number; itemsPerPage: number; search: string }
>
export const getOrganizationUsers = payloadActionCreator<GetOrganizationUsers>(
  'ACCOUNT/get_organization_users'
)

export type GetOrganizationUsersResultAction = FailableAction<
  'ACCOUNT/get_organization_users_result',
  { data: OrganizationUser[]; total: number }
>
export const getOrganizationUsersResultAction =
  failableActionCreator<GetOrganizationUsersResultAction>(
    'ACCOUNT/get_organization_users_result'
  )

export type PostOrganizationUsers = PayloadAction<
  'ACCOUNT/post_organization_users',
  | { type: 'new'; user: OrganizationUser }
  | { type: 'existing'; user: UserPermissions }
>
export const postOrganizationUser = payloadActionCreator<PostOrganizationUsers>(
  'ACCOUNT/post_organization_users'
)

export type PostOrganizationUsersResultAction = FailableAction<
  'ACCOUNT/post_organization_users_result',
  unknown
>
export const postOrganizationUsersResultAction =
  failableActionCreator<PostOrganizationUsersResultAction>(
    'ACCOUNT/post_organization_users_result'
  )

export type PutOrganizationUsers = PayloadAction<
  'ACCOUNT/put_organization_users',
  UserPermissions
>
export const putOrganizationUsers = payloadActionCreator<PutOrganizationUsers>(
  'ACCOUNT/put_organization_users'
)

export type PutOrganizationUsersResultAction = FailableAction<
  'ACCOUNT/put_organization_users_result',
  unknown
>
export const putOrganizationUsersResultAction =
  failableActionCreator<PutOrganizationUsersResultAction>(
    'ACCOUNT/put_organization_users_result'
  )

export type DeleteOrganizationUser = PayloadAction<
  'ACCOUNT/delete_organization_users',
  string
>
export const deleteOrganizationUser =
  payloadActionCreator<DeleteOrganizationUser>(
    'ACCOUNT/delete_organization_users'
  )

export type DeleteOrganizationUserResultAction = FailableAction<
  'ACCOUNT/delete_organization_users_result',
  unknown
>
export const deleteOrganizationUserResultAction =
  failableActionCreator<DeleteOrganizationUserResultAction>(
    'ACCOUNT/delete_organization_users_result'
  )

export type UpdateAccountAction = PayloadAction<
  'ACCOUNT/update',
  Partial<UAAAccount>
>
export const updateAccountAction =
  payloadActionCreator<UpdateAccountAction>('ACCOUNT/update')

export type UpdateAccountResult = FailableAction<
  'ACCOUNT/update_result',
  UAAAccount
>
export const updateAccountResult = failableActionCreator<UpdateAccountResult>(
  'ACCOUNT/update_result'
)

export type ChangePasswordAction = PayloadAction<
  'ACCOUNT/change_password',
  PasswordForm
>
export const changePasswordAction = payloadActionCreator<ChangePasswordAction>(
  'ACCOUNT/change_password'
)

export type ChangePasswordResult = FailableAction<
  'ACCOUNT/change_password_result',
  any
>
export const changePasswordResult = failableActionCreator<ChangePasswordResult>(
  'ACCOUNT/change_password_result'
)

export type UpdateSelectedFile = PayloadAction<
  'ACCOUNT/update_selected_file',
  File
>
export const updateSelectedFile = payloadActionCreator<UpdateSelectedFile>(
  'ACCOUNT/update_selected_file'
)

export type UploadImageAction = PayloadAction<'ACCOUNT/upload_image', ImageType>
export const uploadImageAction = payloadActionCreator<UploadImageAction>(
  'ACCOUNT/upload_image'
)

export type UploadImageResultAction = FailableAction<
  'ACCOUNT/upload_image_result',
  unknown
>
export const uploadImageResultAction =
  failableActionCreator<UploadImageResultAction>('ACCOUNT/upload_image_result')
export type GetWalletsAction = PayloadAction<
  ActionsNames.getWalletsAction,
  Partial<Nullable<WalletsSearchRequest>>
>
export const getWalletsAction = payloadActionCreator<GetWalletsAction>(
  ActionsNames.getWalletsAction
)

export type GetWalletsResultAction = FailableAction<
  ActionsNames.getWalletsResultAction,
  any
>
export const getWalletsResultAction =
  failableActionCreator<GetWalletsResultAction>(
    ActionsNames.getWalletsResultAction
  )

export type GetBalanceTriggers = PayloadAction<
  'ACCOUNT/get_balance_triggers',
  { page: number; itemsPerPage: number }
>
export const getBalanceTriggers = payloadActionCreator<GetBalanceTriggers>(
  'ACCOUNT/get_balance_triggers'
)

export type GetBalanceTriggerResultAction = FailableAction<
  'ACCOUNT/get_balance_trigger_result',
  { data: BalanceTrigger[]; count: number }
>
export const getBalanceTriggerResultAction =
  failableActionCreator<GetBalanceTriggerResultAction>(
    'ACCOUNT/get_balance_trigger_result'
  )

export type AddBalanceTrigger = PayloadAction<
  'ACCOUNT/add_balance_trigger',
  BalanceTrigger
>
export const addBalanceTrigger = payloadActionCreator<AddBalanceTrigger>(
  'ACCOUNT/add_balance_trigger'
)

export type AddBalanceTriggerResultAction = FailableAction<
  'ACCOUNT/add_balance_trigger_result',
  unknown
>
export const addBalanceTriggerResultAction =
  failableActionCreator<AddBalanceTriggerResultAction>(
    'ACCOUNT/add_balance_trigger_result'
  )

export type UpdateBalanceTrigger = PayloadAction<
  'ACCOUNT/update_balance_trigger',
  BalanceTrigger
>
export const updateBalanceTrigger = payloadActionCreator<UpdateBalanceTrigger>(
  'ACCOUNT/update_balance_trigger'
)

export type UpdateBalanceTriggerResultAction = FailableAction<
  'ACCOUNT/update_balance_trigger_result',
  unknown
>
export const updateBalanceTriggerResultAction =
  failableActionCreator<UpdateBalanceTriggerResultAction>(
    'ACCOUNT/update_balance_trigger_result'
  )

export type DeleteBalanceTrigger = PayloadAction<
  'ACCOUNT/delete_balance_trigger',
  string
>
export const deleteBalanceTrigger = payloadActionCreator<DeleteBalanceTrigger>(
  'ACCOUNT/delete_balance_trigger'
)

export type DeleteBalanceTriggerResultAction = FailableAction<
  'ACCOUNT/delete_balance_trigger_result',
  unknown
>
export const deleteBalanceTriggerResultAction =
  failableActionCreator<DeleteBalanceTriggerResultAction>(
    'ACCOUNT/delete_balance_trigger_result'
  )

export type AddPhonenumber = PayloadAction<
  'ACCOUNT/add_phonenumber',
  PhonenumberRequest
>

export const addPhonenumber = payloadActionCreator<AddPhonenumber>(
  'ACCOUNT/add_phonenumber'
)

export type AddPhonenumberResultAction = FailableAction<
  'ACCOUNT/add_phonenumber_result',
  unknown
>

export const addPhonenumberResultAction =
  failableActionCreator<AddPhonenumberResultAction>(
    'ACCOUNT/add_phonenumber_result'
  )

export type VerifyPhonenumber = PayloadAction<
  'ACCOUNT/verify_phonenumber',
  PhonenumberRequest
>

export const verifyPhonenumber = payloadActionCreator<VerifyPhonenumber>(
  'ACCOUNT/verify_phonenumber'
)

export type VerifyPhonenumberResultAction = FailableAction<
  'ACCOUNT/verify_phonenumber_result',
  unknown
>

export const verifyPhonenumberResultAction =
  failableActionCreator<VerifyPhonenumberResultAction>(
    'ACCOUNT/verify_phonenumber_result'
  )

export type Actions =
  | UpdateAccountAction
  | UpdateAccountResult
  | ChangePasswordAction
  | ChangePasswordResult
  | LocationChangeAction
  | GetOrganizationUsers
  | GetOrganizationUsersResultAction
  | PostOrganizationUsers
  | PostOrganizationUsersResultAction
  | PutOrganizationUsers
  | PutOrganizationUsersResultAction
  | DeleteOrganizationUser
  | DeleteOrganizationUserResultAction
  | UploadImageAction
  | UploadImageResultAction
  | UpdateSelectedFile
  | GetWalletsAction
  | GetWalletsResultAction
  | GetBalanceTriggers
  | GetBalanceTriggerResultAction
  | AddBalanceTrigger
  | AddBalanceTriggerResultAction
  | UpdateBalanceTrigger
  | UpdateBalanceTriggerResultAction
  | DeleteBalanceTrigger
  | DeleteBalanceTriggerResultAction
  | AddPhonenumber
  | AddPhonenumberResultAction
  | VerifyPhonenumber
  | VerifyPhonenumberResultAction
