import { OrganizationSelectModal } from '@library/react-toolkit'
import * as React from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import MainLayout from '../shared/layout/MainLayout'
import Notifications from '../shared/Notifications'
import { State } from '../shared/state/store'
import data from '../shared/translations/data'
import FullPageSpin from '../shared/widgets/FullPageSpin'
import PrivateRoute from '../shared/widgets/PrivateRoute'
import PublicRoute from '../shared/widgets/PublicRoute'
import Authentication from './Authentication/Authentication'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      bodyBackground: string
      primary: string
      primaryLight: string
      primaryExtraLight: string
      error: string
      secondary: string
      white: string
      light: string
      extraLight: string
      extraDark: string
      dark: string
      darkLight: string
      blue: string
      red: string
    }
    paddingUnit: number

    footerHeight: number
    headerHeight: number
  }
}

const GlobalStyle = createGlobalStyle`
#root {
  width: 100%;
  height: 100%;
 
}

*.ant-btn {
  & > *.anticon,
  & > *.ant-btn-loading-icon {
    margin-right: 8px;
   }

   & > *.anticon:last-child {
    margin-right: 0px;
   }
}

*.ant-btn.ant-btn-icon-only {
  & > *.anticon,
  & > *.ant-btn-loading-icon {
    margin-right: 0px;
  }
}

tr {
 & > th:first-child,
  &  > td:first-child {
    padding-left: 24px !important;
  }

  & > th:last-child,
  & > td:last-child {
    padding-right: 24px !important;
  }
}

ul.ant-menu-vertical.ant-menu-sub {
  min-width: unset;
  border-radius: 0 0 8px 8px;
  margin-top: -8px;
}
`

const App = () => {
  const locale = useSelector((s: State) => s.locale)

  return (
    <IntlProvider locale={locale} messages={data[locale]}>
      <ThemeProvider theme={_theme}>
        <GlobalStyle />
        <OrganizationSelectModal />
        <FullPageSpin>
          <Notifications />
          <Switch>
            <PublicRoute path="/auth" component={Authentication} />
            <PrivateRoute component={MainLayout} />
          </Switch>
        </FullPageSpin>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default App
