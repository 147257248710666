import React from 'react'
import { Alert, Button, Divider, Result, Steps, Typography } from 'antd'
import { TranslatedMessage } from '../../../shared/translations/data'
import SetOrganization from './SetOrganization'
import { useDispatch, useSelector } from 'react-redux'
import { createOrganizationAction } from '../Login'
import {
  loadable,
  memoed,
  Module,
  Organization,
  UAAAccount,
} from '@library/react-toolkit'
import { pipe } from 'fp-ts/lib/function'
import { array, option, record } from 'fp-ts'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

type Step = 0 | 1 | 2

const nextStep: Record<Step, Step> = { 0: 1, 1: 2, 2: 2 }
const prevStep: Record<Step, Step> = { 0: 0, 1: 0, 2: 1 }

const incrementStep = (s: Step): Step => nextStep[s]
const decrementStep = (s: Step): Step => prevStep[s]

type OrganizationData = Pick<
  Organization,
  'name' | 'abbreviation' | 'rib' | 'asset' | 'displayName' | 'modules'
>

type FormData = {
  organization: OrganizationData
}

const ButtonBar = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
`

const OrganizationSignUpWithAccount = ({
  modules,
  account,
}: {
  modules: Module[]
  account: UAAAccount
}) => {
  const dispatch = useDispatch()
  const locale = useSelector((s) => s.locale)
  const units = useSelector((s) => memoed.extract(s.units))
  const failed = useSelector((s) =>
    loadable.isErr(s.account.createOrganizationResult)
  )
  const success = useSelector((s) =>
    loadable.isOk(s.account.createOrganizationResult)
  )
  const loading = useSelector((s) =>
    loadable.isLoading(s.account.createOrganizationResult)
  )

  const tndUnitID = pipe(
    units,
    record.filter(
      (u) =>
        u.name.toLowerCase().indexOf('tnd') !== -1 ||
        u.symbol.toLowerCase().indexOf('tnd') !== -1
    ),
    record.keys,
    array.head,
    option.toUndefined
  )

  const [step, setStep] = React.useState<Step>(0)
  const [user, setUser] = React.useState<FormData>({
    organization: {
      name: '',
      abbreviation: '',
      rib: '',
      asset: '',
      displayName: '',
      modules: modules,
    },
  })

  React.useEffect(() => {
    if (!!tndUnitID) {
      setUser((u) => ({
        ...u,
        organization: { ...u.organization, asset: tndUnitID },
      }))
    }
  }, [tndUnitID])

  React.useEffect(() => {
    if (success) {
      setStep(2)
    }
  }, [success])

  const onSubmit = (v: Partial<FormData>) => {
    setUser((u) => ({ ...u, ...v }))
    if (step === 1) {
      dispatch(
        createOrganizationAction({
          ...user.organization,
          ...v.organization,
          modules,
          email: account.email,
        })
      )
    } else {
      setStep(incrementStep)
    }
  }

  const goBack = () => setStep(decrementStep)

  const onChange = (v: Partial<FormData>) => setUser((u) => ({ ...u, ...v }))

  const stepProps = { onSubmit, onChange, locale, initialValue: user }

  return (
    <>
      <Steps progressDot current={step} size="small" style={{ color: 'green' }}>
        <Steps.Step title={<TranslatedMessage id="setUserInfo" />} />
        <Steps.Step title={<TranslatedMessage id="setOrganization" />} />
        <Steps.Step title={<TranslatedMessage id="done" />} />
      </Steps>
      <Divider />
      {step === 0 ? (
        <div>
          <Typography.Paragraph style={{ padding: '32px 0' }}>
            <TranslatedMessage id="signUpAlreadyLoggedIn" values={account} />
          </Typography.Paragraph>
          <ButtonBar>
            <Button loading={loading}>
              <Link to="/">
                <TranslatedMessage id="goToHomePage" />
              </Link>
            </Button>
            <Button loading={loading} onClick={() => setStep(1)} type="primary">
              <TranslatedMessage id="next" />
            </Button>
          </ButtonBar>
        </div>
      ) : step === 1 ? (
        <SetOrganization {...stepProps} goBack={goBack} units={units} />
      ) : (
        <Result
          status="success"
          title={<TranslatedMessage id="signUpSuccessTitle" />}
          subTitle={
            <TranslatedMessage
              id="organizationCreatedDescription"
              values={{ displayName: user.organization.name }}
            />
          }
          extra={
            <Button loading={loading} type="primary">
              <Link to="/">
                <TranslatedMessage id="goToHomePage" />
              </Link>
            </Button>
          }
        />
      )}
      {failed && (
        <Alert message={<TranslatedMessage id="signupFailed" />} type="error" />
      )}
    </>
  )
}

export default OrganizationSignUpWithAccount
