import * as React from 'react'

import { Modal, Switch, Typography } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { usePutOrganizationStatus } from '../queries'

const { Title } = Typography

type Props = {
  name: string
  status: string
}

const ConfirmSwitch = ({ name, status }: Props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const intl = useIntl()
  const statusMutation = usePutOrganizationStatus(name)
  const handleOk = () => {
    status === 'active'
      ? statusMutation.mutate({
          status: 'blocked',
        })
      : statusMutation.mutate({
          status: 'active',
        })
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const showModal = () => {
    setIsModalVisible(true)
    Modal.confirm({
      title: (
        <Title level={5}>
          {status === 'active'
            ? intl.formatMessage({
                id: 'confirmDisable',
              })
            : intl.formatMessage({
                id: 'confirmEnable',
              })}
        </Title>
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <p>
          {intl.formatMessage({
            id: 'statusUpdateConfirmation',
          })}
          : {name}
        </p>
      ),
      okText: intl.formatMessage({
        id: 'yes',
      }),
      onOk: handleOk,
      cancelText: intl.formatMessage({
        id: 'no',
      }),
      onCancel: handleCancel,
      width: 448,
      open: isModalVisible,
    })
  }

  return <Switch checked={status === 'active'} onClick={showModal} />
}
export default ConfirmSwitch
