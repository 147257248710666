import {
  getCurrentWorkspaceAction,
  useOrganization,
} from '@library/react-toolkit'
import { pipe } from 'fp-ts/lib/function'
import { useMutation, useQuery } from 'react-query'
import { getOrganizationStatus, uploadOrgImage } from './api'
import { either, task, taskEither } from 'fp-ts'
import { useDispatch } from 'react-redux'
export const throwLeft: <L, R>(
  ma: taskEither.TaskEither<L, R>
) => task.Task<R> = task.map(
  either.fold(
    (e) => {
      throw e
    },
    (x) => x
  )
)
export const useOrganizationStatus = () => {
  const org = useOrganization()
  return useQuery(
    [org?.name, 'verify'],
    pipe(getOrganizationStatus(org?.name ?? ''), throwLeft),
    {
      enabled: !!org,
    }
  )
}

export const useOrganizationImageMutation = () => {
  const org = useOrganization()
  const dispatch = useDispatch()
  return useMutation(
    (file: FormData) =>
      pipe(uploadOrgImage(org?.name ?? '', file), throwLeft)(),
    {
      onSuccess: () => {
        dispatch(getCurrentWorkspaceAction())
      },
    }
  )
}
