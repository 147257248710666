import { Skeleton } from 'antd'
import * as React from 'react'
import { useRate } from '../queries'
import { Rate } from '../types'

export const ViaCurrency = ({
  currency,
  selector,
}: {
  currency: string
  selector: (_: Rate) => React.ReactNode
}) => {
  const rateQuery = useRate(currency)
  return (
    <Skeleton
      title={false}
      active={rateQuery.isLoading}
      loading={rateQuery.isLoading}
      paragraph={{ rows: 1 }}
    >
      {!!rateQuery.data ? selector(rateQuery.data) : null}
    </Skeleton>
  )
}
