/**
 * @since 0.5.0
 */
import * as t from 'io-ts';
var None = t.strict({
    _tag: t.literal('None')
});
var someLiteral = t.literal('Some');
/**
 * @since 0.5.0
 */
export function option(codec, name) {
    if (name === void 0) { name = "Option<" + codec.name + ">"; }
    return t.union([
        None,
        t.strict({
            _tag: someLiteral,
            value: codec
        }, "Some<" + codec.name + ">")
    ], name);
}
