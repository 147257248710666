import { SwapOutlined } from '@ant-design/icons'
import { useOrganization } from '@library/react-toolkit'
import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Table,
  Typography,
} from 'antd'
import moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import { TranslatedMessage } from '../../../../../shared/translations/data'
import { useGeneralSettings, useRates } from '../queries'
import { Rates } from '../types'

const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  & > * {
    flex: 0;
  }
  & > .ant-input,
  & > .ant-form-item {
    flex: 1;
  }
`

type FormData = Record<
  string,
  {
    rate: number
    time: moment.Moment
  }
>

type CurrencyData = {
  currency: string
}

const toRate = (x: number) => {
  const s = `${x}`
  const dotPosition = s.indexOf('.')
  if (dotPosition === -1) {
    return { numerator: x, denominator: 1 }
  }

  const mult = 10 ** (s.length - (dotPosition + 1))

  return {
    numerator: Math.trunc(x * mult),
    denominator: mult,
  }
}

export const ExchangeRateUpdate: React.FC<{
  form: FormInstance
  onSubmit: (data: Rates) => void
  errorRef: React.MutableRefObject<() => string>
}> = ({ form, onSubmit, errorRef }) => {
  const org = useOrganization()
  const settings = useGeneralSettings()
  const rateQueries = useRates(settings.data?.currencies ?? [])
  const states = rateQueries.map((q) => q.status)
  const rates: FormData = rateQueries.reduce(
    (p, q) =>
      !q.isSuccess || !q.data
        ? p
        : {
            ...p,
            [q.data.currency]: {
              rate: q.data.rate.numerator / q.data.rate.denominator,
              time: moment(q.data.time),
            },
          },
    {}
  )
  const dataSource: CurrencyData[] =
    settings.data?.currencies.map((currency) => ({
      currency,
      key: currency,
    })) ?? []

  const onFinish = (values: FormData) => {
    const changedData = Object.entries(values).filter(([currency]) => {
      return (
        form.isFieldTouched([currency, 'time']) ||
        form.isFieldTouched([currency, 'rate'])
      )
    })

    console.log(changedData)
    onSubmit(
      changedData.map(([currency, { rate, time }]) => ({
        currency,
        time: time.toDate(),
        rate: toRate(rate),
      }))
    )
  }

  React.useEffect(() => {
    if (!!states.find((s) => s === 'success')) {
      form.resetFields()
    }
  }, [states])

  React.useEffect(() => {
    if (!!errorRef) {
      form.setFields([
        {
          name: [errorRef.current.toString(), 'time'],
          errors: ['Invalid date'],
        },
      ])
    }
  }, [errorRef.current.length])

  return (
    <>
      <Typography.Text style={{ display: 'block' }}>
        <TranslatedMessage id="orgName" />:{org?.displayName}
      </Typography.Text>
      <Typography.Text style={{ display: 'block' }}>
        <TranslatedMessage id="orgId" />:{org?.id}
      </Typography.Text>
      <Form form={form} onFinish={onFinish} initialValues={rates}>
        <Table
          dataSource={dataSource}
          columns={[
            {
              // TODO: translate
              title: 'Unit/Currency',
              render: (_, v) => v.currency,
            },
            {
              title: <TranslatedMessage id="unitPrice" />,
              render: (_, v) => (
                <PriceRow>
                  <Input readOnly value={`1.000 ${v.currency}`} />
                  <SwapOutlined style={{ fontSize: 20, marginTop: 5 }} />
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: <TranslatedMessage id="priceRequired" />,
                      },
                    ]}
                    name={[v.currency, 'rate']}
                  >
                    <InputNumber min={0} addonAfter={'XDR'} />
                  </Form.Item>
                </PriceRow>
              ),
            },
            {
              title: <TranslatedMessage id="rateDate" />,
              render: (_, v) => (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: <TranslatedMessage id="rateDateRequired" />,
                    },
                  ]}
                  name={[v.currency, 'time']}
                >
                  <DatePicker format={'YYYY/MM/DD'} />
                </Form.Item>
              ),
            },
          ]}
          pagination={false}
          loading={settings.isLoading}
        />
      </Form>
    </>
  )
}
