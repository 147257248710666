import axios from 'axios'
import { get, CancelToken, post } from '../../../../axios'
import {
  AMApprovalConfig,
  AMApprovalConfigBody,
  AMApprovalTag,
  ApprovalConfig,
} from './types'

export const getUsers: any = (
  organizationName: string,
  page: number,
  itemsPerPage: number,
  data: any
) =>
  axios
    .get(
      `/uaa/api/organizations/${organizationName}/users?page=${page}&itemsPerPage=${itemsPerPage}`
    )
    .then((response) => {
      if (response.data.data.length < 1) {
        return data
      }
      data.push(...response.data.data)
      return getUsers(organizationName, page + 1, 50, data)
    })

export const getApprovalConfig = (org: string, tag: AMApprovalTag) =>
  get(`/assets-management/1.0/${org}/${tag}/approval-config`, {
    decoder: ApprovalConfig,
  })

export const upsertAMConfig = (
  {
    organizationName,
    config,
  }: { organizationName: string; config: AMApprovalConfigBody },
  cancelToken?: CancelToken
) =>
  post(
    `/assets-management/1.0/${organizationName}/${config.tag}/approval-config`,
    { users: config.users, minimum: config.minimum, inherit: config.inherit },
    {
      cancelToken,
      decoder: AMApprovalConfig,
    }
  )
