import {
  Loadable,
  PayloadAction,
  payloadActionCreator,
  FailableAction,
  failableActionCreator,
} from '../../../shared/types'

export type FinishReset = {
  newPassword: string
  key: string
}

export type State = {
  initRequestResult: Loadable<unknown>
  finishRequestResult: Loadable<unknown>
}

export type ConfirmInitRequest = PayloadAction<
  'RESET/confirm_init_request',
  { email: string }
>
export const confirmInitRequest = payloadActionCreator<ConfirmInitRequest>(
  'RESET/confirm_init_request'
)

export type InitRequestResult = FailableAction<
  'RESET/init_request_result',
  undefined
>
export const initRequestResult = failableActionCreator<InitRequestResult>(
  'RESET/init_request_result'
)

export type ConfirmFinishRequest = PayloadAction<
  'RESET/confirm_finish_request',
  FinishReset
>
export const confirmFinishRequest = payloadActionCreator<ConfirmFinishRequest>(
  'RESET/confirm_finish_request'
)

export type FinishRequestResult = FailableAction<
  'RESET/finish_request_result',
  undefined
>
export const finishRequestResult = failableActionCreator<FinishRequestResult>(
  'RESET/finish_request_result'
)

export type Actions =
  | ConfirmInitRequest
  | InitRequestResult
  | ConfirmFinishRequest
  | FinishRequestResult
