import { useOrganization } from '@library/react-toolkit'
import { pipe } from 'fp-ts/lib/function'
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query'
import { isAxiosError } from '../../../../axios'
import { throwLeft } from '../../affiliatesSettings/queries'
import {
  getGeneralSettings,
  getOrganizationsParticipantsClearing,
  setGeneralSettings,
} from './api'
import {
  ClearingConfig,
  OrganisationsSearchParams,
  Currencies,
  Rates,
  OrganizationReserve,
  OrganizationStatus,
} from './types'
import * as api from './api'

export const defaultQuerySettings = {
  staleTime: 1000 * 60 * 5,
  retry: (_: number, e: Error) => isAxiosError(e) && e.response?.status !== 404,
}

export const useOrganizationsParticipantsClearing = (
  params: OrganisationsSearchParams
) => {
  return useQuery(
    ['organisations-clearing', params],
    () => pipe(getOrganizationsParticipantsClearing(params), throwLeft)(),
    {
      ...defaultQuerySettings,
    }
  )
}

export const useGeneralSettings = () => {
  const org = useOrganization()
  return useQuery(
    [org?.name, 'general-settings'],
    () => pipe(getGeneralSettings(org?.name ?? ''), throwLeft)(),
    {
      ...defaultQuerySettings,
      enabled: !!org,
    }
  )
}

export const useUpsertSettings = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ['post-general-settings'],
    (request: ClearingConfig) => pipe(setGeneralSettings(request), throwLeft)(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['general-settings'])
      },
      onError: (e) => {
        console.log('error submission', e)
      },
    }
  )
}

export const useAddCurrencies = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (data: Currencies) => pipe(api.postCurrencies(data), throwLeft)(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('currencies')
      },
    }
  )
}

export const useAddRates = () => {
  const queryClient = useQueryClient()

  return useMutation((data: Rates) => pipe(api.postRates(data), throwLeft)(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['rate'])
    },
  })
}

export const useCurrencies = () => {
  const org = useOrganization()
  return useQuery(
    [org?.name, 'currencies'],
    pipe(api.getCurrencies(org?.name ?? ''), throwLeft),
    {
      enabled: !!org,
    }
  )
}

export const useRate = (currency: string) => {
  const org = useOrganization()
  return useQuery(
    [org?.name, 'rate', currency],
    pipe(api.getRate(org?.name ?? '', currency), throwLeft),
    {
      keepPreviousData: true,
      enabled: !!org && currency !== '',
    }
  )
}

export const useRates = (currencies: string[]) => {
  const org = useOrganization()
  return useQueries(
    currencies.map((currency) => ({
      queryKey: [org?.name, 'rate', currency],
      queryFn: pipe(api.getRate(org?.name ?? '', currency), throwLeft),
    }))
  )
}

export const useOrganizationAssets = () => {
  const org = useOrganization()

  return useQuery(
    ['assets', org?.name],
    pipe(api.getAssets(org?.name ?? ''), throwLeft),
    {
      enabled: !!org,
    }
  )
}

export const useParticpant = (participant: string) => {
  const org = useOrganization()
  return useQuery(
    [org?.name, 'participant', participant],
    pipe(api.getParticipant(org?.name ?? '', participant ?? ''), throwLeft),
    {
      keepPreviousData: true,
      enabled: !!org && participant !== '',
    }
  )
}

export const useGetOrganizationDetails = (org: string) => {
  return useQuery(
    [org, 'details'],
    pipe(api.getOrganizationDetails(org), throwLeft),
    {
      keepPreviousData: true,
      enabled: !!org,
    }
  )
}

export const useGetOrganizationsDetails = (orgs: string[]) => {
  return useQueries(
    orgs.map((org) => ({
      queryKey: [org, 'details'],
      queryFn: pipe(api.getOrganizationDetails(org), throwLeft),
    }))
  )
}

export const useSetOrganizationReserve = (org: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (reserve: OrganizationReserve) =>
      pipe(api.postOrganizationReserve(org, reserve), throwLeft)(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([org, 'details'])
      },
    }
  )
}

export const usePutOrganizationStatus = (org: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (status: OrganizationStatus) =>
      pipe(api.putOrganizationStatus(org, status), throwLeft)(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([org, 'details'])
      },
    }
  )
}

export const useVerifyOrganization = (orgName: string) => {
  return useQuery(
    [orgName, 'verify'],
    pipe(api.getVerifyOrganization(orgName), throwLeft),
    {
      enabled: !!orgName,
    }
  )
}
