import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Modal, Steps } from 'antd'
import * as React from 'react'
import { isAxiosError } from '../../../../../axios'
import { TranslatedMessage } from '../../../../../shared/translations/data'
import { useAddCurrencies, useAddRates } from '../queries'
import { CurrencyPegging } from './CurrencyPegging'
import { ExchangeRateUpdate } from './ExchangeRateUpdate'

export const UpdateRatesModal = () => {
  const [currencyPegginForm] = Form.useForm()
  const [exchangeRateUpdateForm] = Form.useForm()
  const addRates = useAddRates()
  const addCurrencies = useAddCurrencies()
  const [modalState, setModalState] = React.useState({
    open: false,
    step: 0,
  })
  const next = () => {
    if (modalState.step === 0) {
      currencyPegginForm.submit()
    }
    if (modalState.step === 1) {
      exchangeRateUpdateForm.submit()
    }
  }

  const errorRef = React.useRef(() => '')
  return (
    <>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => setModalState({ open: true, step: 0 })}
      >
        <TranslatedMessage id="updateRates" />
      </Button>
      <Modal
        width="fit-content"
        destroyOnClose
        open={modalState.open}
        onCancel={() => {
          setModalState({ open: false, step: 0 })
        }}
        footer={
          <>
            <Button onClick={() => setModalState({ open: false, step: 0 })}>
              <TranslatedMessage id="cancel" />
            </Button>
            <>
              {modalState.step > 0 && (
                <Button onClick={() => setModalState({ open: true, step: 0 })}>
                  <TranslatedMessage id="Previous" />
                </Button>
              )}
              <Button
                loading={
                  modalState.step === 0
                    ? addCurrencies.isLoading
                    : addRates.isLoading
                }
                type="primary"
                onClick={next}
              >
                <TranslatedMessage
                  id={modalState.step === 1 ? 'submit' : 'next'}
                />
              </Button>
            </>
          </>
        }
      >
        <Steps current={modalState.step}>
          <Steps.Step title="Pegging Currency" />
          <Steps.Step title="Exchange Rate Update" />
        </Steps>
        <Divider />
        {modalState.step === 0 && (
          <CurrencyPegging
            onSubmit={(data) => {
              addCurrencies.mutate(data, {
                onSuccess: () => setModalState({ open: true, step: 1 }),
              })
            }}
            form={currencyPegginForm}
          />
        )}
        {modalState.step === 1 && (
          <ExchangeRateUpdate
            onSubmit={(data) => {
              addRates.mutate(data, {
                onSuccess: () => setModalState({ open: false, step: 0 }),
                onError(error) {
                  if (isAxiosError(error) && error.response?.status === 409) {
                    errorRef.current = error.response.data
                  }
                  console.error(error)
                },
              })
            }}
            form={exchangeRateUpdateForm}
            errorRef={errorRef}
          />
        )}
      </Modal>
    </>
  )
}
