import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { TranslatedMessage } from '../translations/data'

type Props = {
  className?: string
  popover?: boolean
  status: string
}

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & .anticon {
    margin-top: 20px;
    margin-left: 0.46em;
    font-size: 1.5em;
  }
`

const ClearingModuleInfo = ({ className, status, popover = true }: Props) => {
  const context =
    !!status && status === 'pending' ? (
      <TranslatedMessage id="pendingClearingModule" />
    ) : (
      <TranslatedMessage id="lockedClearingModule" />
    )
  const title =
    !!status && status === 'pending' ? (
      <TranslatedMessage id="modulePending" />
    ) : (
      <TranslatedMessage id="moduleLocked" />
    )

  return (
    <Info className={className}>
      {popover && (
        <Popover
          placement="bottom"
          arrowPointAtCenter
          title={
            <>
              <InfoCircleOutlined
                style={{
                  marginRight: '12px',
                  fontSize: '32',
                  color: status === 'pending' ? '#fd8d08' : '#CF2A27',
                }}
              />
              {title}
            </>
          }
          content={context}
        >
          <InfoCircleOutlined
            style={{
              fontSize: '32',
              color: status === 'pending' ? '#fd8d08' : '#CF2A27',
            }}
          />
        </Popover>
      )}
    </Info>
  )
}

export default ClearingModuleInfo
