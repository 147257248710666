import {
  Col,
  Descriptions,
  Row,
  Skeleton,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd'
import * as React from 'react'
import { TranslatedMessage } from '../../../../shared/translations/data'
import AMSettings from './Modals/ValidationSettings'
import { useGetApprovalConfig, useUpsertApprovalConfig } from './queries'

const { Title } = Typography

export const AssetCreation = () => {
  const approvalConfigQuery = useGetApprovalConfig('creation')
  const upsertApprovalConfig = useUpsertApprovalConfig('creation')
  return (
    <>
      <Row>
        <Col>
          <Space>
            <TranslatedMessage id={'inheritValidation'} />
            <Switch
              loading={approvalConfigQuery.isLoading}
              checked={approvalConfigQuery.data?.inherit}
              onChange={(v) =>
                upsertApprovalConfig.mutateAsync({
                  tag: 'creation',
                  inherit: v,
                  minimum: approvalConfigQuery.data?.minimum ?? 0,
                  users: approvalConfigQuery.data?.users ?? [],
                })
              }
            />
          </Space>
        </Col>
        <Col flex={1}>
          <AMSettings
            tag="creation"
            disabled={approvalConfigQuery.data?.inherit}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Descriptions layout="vertical">
            <Descriptions.Item
              label={
                <Title level={5}>
                  <TranslatedMessage id={'AMSettings_Num_Contributors'} />
                </Title>
              }
            >
              {approvalConfigQuery.isLoading ? (
                <Skeleton.Input active />
              ) : (
                approvalConfigQuery.data?.users &&
                approvalConfigQuery.data?.users.length
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Title level={5}>
                  <TranslatedMessage id={'AMSettings_Min_Required'} />
                </Title>
              }
            >
              {approvalConfigQuery.isLoading ? (
                <Skeleton.Input active />
              ) : (
                approvalConfigQuery.data?.minimum
              )}
            </Descriptions.Item>
          </Descriptions>
          <Table
            loading={approvalConfigQuery.isLoading}
            pagination={false}
            columns={[
              {
                title: <TranslatedMessage id={'AMSettings_Validators'} />,
                dataIndex: 'validator',
                key: 'validator',
              },
            ]}
            dataSource={
              approvalConfigQuery.data?.users &&
              approvalConfigQuery.data?.users.map((u) => ({
                key: u,
                validator: u,
              }))
            }
          />
        </Col>
      </Row>
    </>
  )
}
