import { Form, Table } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import * as React from 'react'
import { TranslatedMessage } from '../../../../../shared/translations/data'
import { CurrenciesSelect } from '../../../../../shared/utils/CurrenciesSelect'
import {
  useOrganizationAssets,
  useGeneralSettings,
  useCurrencies,
} from '../queries'
import { Currencies } from '../types'

export const CurrencyPegging: React.FC<{
  form: FormInstance
  onSubmit: (data: Currencies) => void
}> = ({ form, onSubmit }) => {
  const organizationAssetsQuery = useOrganizationAssets()
  const settingsQuery = useGeneralSettings()
  const initialValues = useCurrencies()
  const dataSource = organizationAssetsQuery.data
    ?.filter((v) =>
      settingsQuery.data?.clearingAssets.assets.includes(v.unitID)
    )
    .map((v) => ({ ...v, key: v.unitID }))

  const initialAssetValues: Record<string, string> = (initialValues.data ?? [])
    .filter(({ assetID }) => !!dataSource?.find((v) => v.unitID === assetID))
    .reduce(
      (acc, { assetID, currency }) => ({ ...acc, [assetID]: currency }),
      {}
    )

  return (
    <Form<Record<string, string>>
      form={form}
      onFinish={(values) =>
        onSubmit(
          Object.entries(values).map(([assetID, currency]) => ({
            assetID,
            currency,
          }))
        )
      }
      initialValues={initialAssetValues}
    >
      <Table
        dataSource={dataSource}
        columns={[
          {
            title: <TranslatedMessage id="asset" />,
            dataIndex: 'assetName',
            render: (_, v) => (v.assetName ? v.assetName : 'N/A'),
          },
          {
            title: <TranslatedMessage id="currency" />,
            dataIndex: 'displayName',
            render: (_, v) => (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: <TranslatedMessage id="requiredCurrency" />,
                  },
                ]}
                name={v.unitID}
              >
                <CurrenciesSelect />
              </Form.Item>
            ),
          },
        ]}
        pagination={false}
        loading={organizationAssetsQuery.isLoading}
      />
    </Form>
  )
}
