import { Module, Organization, UAAAccount } from '@library/react-toolkit'
import { LocationChangeAction } from 'connected-react-router'
import { option } from 'fp-ts'

import {
  Action,
  actionCreator,
  FailableAction,
  failableActionCreator,
  Loadable,
  PayloadAction,
  payloadActionCreator,
} from '../../../shared/types'
import { UpdateAccountResult } from '../../Account/types'

export type LoginStatus = '2FA' | {}

export type SignUpMode =
  | { type: 'default' }
  | { type: 'personal' }
  | { type: 'organization'; modules: Module[] }
  | { type: 'clearingOrg'; modules: Module[] }
  | { type: 'sme'; modules: Module[] }
  | { type: 'entreprise-factoring'; modules: Module[] }
  | { type: 'entreprise-rewards'; modules: Module[] }
  | { type: 'entreprise-bills-wallets'; modules: Module[] }
  | { type: 'banks-factoring'; modules: Module[] }
  | { type: 'banks-clearing'; modules: Module[] }

export type State = {
  redirect: option.Option<string>
  loginResult: Loadable<LoginStatus>
  signUpResult: Loadable<unknown>
  createOrganizationResult: Loadable<unknown>

  signUpMode: SignUpMode
}

export const emptyAccount: Readonly<UAAAccount> = {} as UAAAccount

export type Login = {
  username: string
  password: string
  tfacode: string
}

export type SignUp = {
  email: string
  firstName: string
  lastName: string
  login: string
  password: string
  organization?: Pick<Organization, 'name' | 'rib' | 'asset' | 'displayName'>
  withWallet: boolean
}

export type GoogleLogin = {
  googleID: string
  grant_type: string
}

export type OrganizationSignUp = {
  organizationName: string
  email: string
  userName: string
  organizationUrl: string
}

export type OrganizationSignUpWithModules = OrganizationSignUp & {
  modules: Array<string>
}

export type LoginWithGoogleAction = PayloadAction<
  'ACCOUNT/login_with_google',
  GoogleLogin
>
export const loginWithGoogleAction =
  payloadActionCreator<LoginWithGoogleAction>('ACCOUNT/login_with_google')

export type LoginAction = PayloadAction<'ACCOUNT/login', Login>
export const loginAction = payloadActionCreator<LoginAction>('ACCOUNT/login')

export type LoginResultAction = FailableAction<
  'ACCOUNT/login_result',
  LoginStatus
>
export const loginResultAction = failableActionCreator<LoginResultAction>(
  'ACCOUNT/login_result'
)

export type SignUpAction = PayloadAction<'ACCOUNT/sign_up', SignUp>
export const signUpAction =
  payloadActionCreator<SignUpAction>('ACCOUNT/sign_up')

export type SignUpActionResultAction = FailableAction<
  'ACCOUNT/sign_up_result',
  unknown
>
export const signUpActionResultAction =
  failableActionCreator<SignUpActionResultAction>('ACCOUNT/sign_up_result')

export type OpenSignUpResponseModalAction = Action<'ACCOUNT/open_modal'>
export const openSignUpResponseModalAction =
  actionCreator<OpenSignUpResponseModalAction>('ACCOUNT/open_modal')

export type CloseSignUpResponseModalAction = Action<'ACCOUNT/close_modal'>
export const closeSignUpResponseModalAction =
  actionCreator<CloseSignUpResponseModalAction>('ACCOUNT/close_modal')

export type SetSignUpModeAction = PayloadAction<
  'ACCOUNT/set_signup_mode',
  SignUpMode
>
export const setSignUpModeAction = payloadActionCreator<SetSignUpModeAction>(
  'ACCOUNT/set_signup_mode'
)

export type CreateOrganizationAction = PayloadAction<
  'ACCOUNT/create_organization',
  Pick<
    Organization,
    'name' | 'abbreviation' | 'rib' | 'modules' | 'asset' | 'email'
  >
>
export const createOrganizationAction =
  payloadActionCreator<CreateOrganizationAction>('ACCOUNT/create_organization')

export type CreateOrganizationResultAction = FailableAction<
  'ACCOUNT/create_organization_result',
  Organization
>
export const createOrganizationResultAction =
  failableActionCreator<CreateOrganizationResultAction>(
    'ACCOUNT/create_organization_result'
  )

export type Actions =
  | LoginAction
  | LoginResultAction
  | UpdateAccountResult
  | LocationChangeAction
  | LoginWithGoogleAction
  | SignUpActionResultAction
  | SignUpAction
  | SetSignUpModeAction
  | CreateOrganizationAction
  | CreateOrganizationResultAction
