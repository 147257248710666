import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useOrganization } from '@library/react-toolkit'
import { putOrganizationUsers, UserPermissions } from '../../../types'
import { getUsers } from '../api'
import { TranslatedMessage } from '../../../../../shared/translations/data'
import { useGetApprovalConfig, useUpsertApprovalConfig } from '../queries'
import { AMApprovalTag } from '../types'

const { Title } = Typography

export const ModalTitle = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 0em;
`

type Props = {
  tag: AMApprovalTag
  disabled?: boolean
}

const AMSettings = ({ tag, disabled }: Props) => {
  const approvalConfigQuery = useGetApprovalConfig(tag)
  const upsertApprovalConfig = useUpsertApprovalConfig('creation')

  const org = useOrganization()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const intl = useIntl()

  const [users, setUsers] = useState<UserPermissions[]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [numValidators, setNumValidators] = useState<number>(1)
  const [minRequired, setMinRequired] = useState<number>(1)
  const [validators, setValidators] = useState<{ [key: number]: string }>({})
  const data: {
    key: number
    validator: JSX.Element
  }[] = []

  useEffect(() => {
    isModalVisible &&
      getUsers(org!.name, 0, 50, []).then((data: UserPermissions[]) => {
        setUsers(data)
      })
  }, [isModalVisible])

  useEffect(() => {
    if (approvalConfigQuery.isSuccess && isModalVisible) {
      setMinRequired(approvalConfigQuery.data.minimum)
      setNumValidators(approvalConfigQuery.data.users.length)
      setValidators(
        approvalConfigQuery.data.users.reduce(
          (us, u, i) => ({ ...us, [i]: u }),
          {}
        )
      )
    }
  }, [approvalConfigQuery.status, isModalVisible])

  useEffect(() => {
    let vs: { [key: number]: string } = {}
    for (let i = 0; i < numValidators; i++) {
      if (validators[i] != undefined) {
        vs[i] = validators[i]
      }
    }
    setValidators(vs)
  }, [numValidators, minRequired])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    upsertApprovalConfig.mutateAsync({
      tag: tag,
      inherit: approvalConfigQuery.data?.inherit ?? false,
      minimum: minRequired,
      users: Object.entries(validators).map(([_, v]) => v),
    })
    for (let i = 0; i < numValidators; i++) {
      for (let u of users) {
        if (validators[i] === u.login) {
          dispatch(
            putOrganizationUsers({
              authority: u.authority,
              login: u.login,
              modulePermissions: {
                ...u.modulePermissions,
                ASSET_MANAGEMENT: 'CONTRIBUTOR',
              },
              walletPermissions: u.walletPermissions,
              jobTitle:
                u.jobTitle != undefined
                  ? u.jobTitle
                  : 'ASSETS MANAGEMENT VALIDATOR',
            })
          )
          break
        }
      }
    }
    setIsModalVisible(false)
  }
  console.log('num users: ', numValidators)
  console.log('users: ', validators)
  for (let i = 0; i < numValidators; i++) {
    data.push({
      key: i,
      validator: (
        <Form.Item
          rules={[{ required: true, message: 'Please select a validator!' }]}
          preserve={false}
        >
          <Select
            defaultValue={validators[i]}
            value={validators[i]}
            showSearch
            placeholder={intl.formatMessage({
              id: 'AMSettings_Contributor_Placeholder',
            })}
            optionFilterProp="label"
            onChange={(value: string) => {
              setValidators((v) => ({ ...v, [i]: value }))
            }}
          >
            {users
              .filter((u) => {
                let f = true
                for (let j = 0; j < numValidators; j++) {
                  if (validators[j] == u.login) {
                    f = false
                  }
                }
                return f
              })
              .map((user) => (
                <Select.Option key={user.login} value={user.login}>
                  {user.login}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      ),
    })
  }

  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        style={{ float: 'right' }}
        disabled={disabled}
      >
        <TranslatedMessage id="changeSettings" />
      </Button>
      <Modal
        title={
          <Title level={3}>
            <ModalTitle>
              <FormattedMessage id={'AMSettings_Modal_Title'} />
            </ModalTitle>
          </Title>
        }
        width={580}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key={'cancel'}
            form={'AMSettings'}
            onClick={() => setIsModalVisible(false)}
          >
            <FormattedMessage id={'cancel'} />
          </Button>,
          <Button key={'submit'} form={'AMSettings'} htmlType="submit">
            <FormattedMessage id={'Submit'} />
          </Button>,
        ]}
      >
        <Form
          name="AMSettings"
          id={'AMSettings'}
          initialValues={{
            total:
              approvalConfigQuery.data?.users &&
              approvalConfigQuery.data?.users.length,
            minimum:
              approvalConfigQuery.data?.minimum &&
              approvalConfigQuery.data?.minimum,
          }}
          form={form}
          layout={'vertical'}
          onFinish={handleOk}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={'total'}
                label={<FormattedMessage id={'AMSettings_Num_Contributors'} />}
                rules={[{ required: true }]}
                preserve={false}
              >
                <InputNumber
                  value={numValidators}
                  min={1}
                  max={users.length}
                  step={1}
                  onChange={(value) => !!value && setNumValidators(value)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{ paddingRight: '0px' }}>
              <Form.Item
                name={'minimum'}
                label={<FormattedMessage id={'AMSettings_Min_Required'} />}
                rules={[{ required: true }]}
                preserve={false}
              >
                <InputNumber
                  value={minRequired}
                  min={1}
                  max={numValidators}
                  step={1}
                  onChange={(value) => setMinRequired(value ?? 1)}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Table
            pagination={false}
            columns={[
              {
                title: <FormattedMessage id={'AMSettings_Validators'} />,
                dataIndex: 'validator',
                key: 'validator',
              },
            ]}
            dataSource={data}
          />
        </Form>
      </Modal>
    </>
  )
}

export default AMSettings
