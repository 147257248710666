import { Skeleton } from 'antd'
import React from 'react'
import { useProfile } from './queries'
import { AffiliateProfile } from './types'

const ViaProfile = ({
  nym,
  selector,
}: {
  nym: string
  selector: (_: AffiliateProfile) => React.ReactNode
}) => {
  console.log(nym)
  const profileQuery = useProfile(nym)
  return (
    <Skeleton
      title={false}
      active={profileQuery.isLoading}
      loading={profileQuery.isLoading}
      paragraph={{ rows: 1 }}
    >
      {!!profileQuery.data ? selector(profileQuery.data) : null}
    </Skeleton>
  )
}

export default ViaProfile
