import { Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import * as React from 'react'
import { TranslatedMessage } from '../../../../shared/translations/data'
import { UpdateRatesModal } from './Modals/UpdateRatesModal'
import { ViaCurrency } from './Modals/ViaCurrency'
import {
  useCurrencies,
  useGeneralSettings,
  useOrganizationAssets,
  useRates,
} from './queries'
import { Asset } from './types'
import styled from 'styled-components'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const TableHeader = () => {
  const settings = useGeneralSettings()
  const rateQueries = useRates(settings.data?.currencies ?? [])
  const lastDate = rateQueries.reduce<Date | undefined>(
    (p, c) => (!p || (!!c.data?.time && c.data.time > p) ? c.data?.time : p),
    undefined
  )

  return (
    <HeaderGrid>
      <FlexStart>
        {!lastDate ? (
          <FormattedMessage id="n/a" />
        ) : (
          <FormattedMessage
            id="lastUpdate"
            values={{
              date: (
                <FormattedDate
                  value={lastDate}
                  year="numeric"
                  month="numeric"
                  day="numeric"
                />
              ),
              time: (
                <FormattedTime
                  value={lastDate}
                  hour="2-digit"
                  minute="2-digit"
                />
              ),
            }}
          />
        )}
      </FlexStart>
      <FlexEnd>
        <UpdateRatesModal />
      </FlexEnd>
    </HeaderGrid>
  )
}

const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
`

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`
const num = 1.0

// xdr  * (denumerator / numerator)

export const ExchangeRatesSettings = () => {
  const organizationAssetsQuery = useOrganizationAssets()
  const currencyQuery = useCurrencies()
  const currencies = currencyQuery.data ?? []
  const settings = useGeneralSettings()

  const columns: ColumnProps<Asset & { currency: string }>[] = [
    {
      title: <TranslatedMessage id="assetName" />,
      dataIndex: 'assetName',
      render: (_, v) => (v.assetName ? v.assetName : 'N/A'),
    },
    {
      title: <TranslatedMessage id="displayName" />,
      dataIndex: 'displayName',
      render: (_, v) => (v.format?.code ? v.format.code : 'N/A'),
    },
    {
      title: <TranslatedMessage id="assetID" />,
      dataIndex: 'unitID',
      render: (_, v) => (v.unitID ? v.unitID : 'N/A'),
    },
    {
      title: <TranslatedMessage id="format" />,
      key: 'format',
      render: (_, v) =>
        v.format.decimalPoints ? num.toFixed(v.format.decimalPoints) : 'N/A',
    },
    {
      title: 'UNIT-XDR',
      render: (_, v) => (
        <ViaCurrency
          currency={v.currency}
          selector={(c) =>
            c.rate?.denominator !== 0
              ? c.rate?.numerator / c.rate?.denominator
              : 'N/A'
          }
        />
      ),
    },
    ...(settings.data?.currencies.map(
      (currency): ColumnProps<Asset & { currency: string }> => ({
        title: `UNIT-${currency}`,
        render: (_, v) => (
          <ViaCurrency
            currency={v.currency}
            selector={(xdr) => (
              <ViaCurrency
                currency={currency}
                selector={(converted) =>
                  (xdr.rate?.numerator / xdr.rate?.denominator) *
                  (converted.rate?.denominator / converted.rate?.numerator)
                }
              />
            )}
          />
        ),
      })
    ) ?? []),
  ]

  return (
    <Table
      title={() => <TableHeader />}
      dataSource={
        organizationAssetsQuery.data
          ?.filter(
            (a) =>
              settings.data?.clearingAssets.assets.includes(a.unitID) ?? false
          )
          .map((a) => ({
            ...a,
            key: a.unitID,
            currency:
              currencies.find((c) => c.assetID === a.unitID)?.currency ?? '',
          })) ?? []
      }
      columns={columns}
      loading={organizationAssetsQuery.isLoading}
      pagination={false}
    />
  )
}
