import { Select, SelectProps } from 'antd'
import * as React from 'react'
import currenciesData from '../currencies.json'

const currencies = Object.keys(currenciesData).map((code) => ({
  code,
  name: currenciesData[code].name,
}))

export const CurrenciesSelect = (props: SelectProps) => {
  return (
    <Select style={{ width: '160px' }} {...props}>
      {currencies.map((currency) => (
        <Select.Option key={currency.code} value={currency.code}>
          {currency.code}
        </Select.Option>
      ))}
    </Select>
  )
}
