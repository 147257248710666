import { loadable, useCurrentWorkspace } from '@library/react-toolkit'
import { Card, Switch, Typography } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { updateOrganizationModulesAction } from '../../../shared/state/organization'
import { TranslatedMessage } from '../../../shared/translations/data'
import { applications } from './../types'

const Icon = styled.div.attrs((props) => ({
  // or we can define dynamic ones
  color: props.color || '#666666',
}))`
  height: 80px;
  width: 80px;

  background: ${(props) => props.color};
  border-radius: 40px;
  margin: auto;
  svg {
    height: 40px;
    width: 40px;
    color: #fff;
    margin-left: 20px;
    margin-top: 20px;
  }
`

const ModuleSelectorGrid = styled(Card)`
  box-shadow: none;

  & > .ant-card-body {
    display: grid;
    grid-template-columns: 80px 1fr auto;
    grid-template-rows: auto 1fr;
    padding: 24px 24px 24px 72px;
    grid-column-gap: 48px;
    grid-row-fap: 8px;
    & > ${Icon} {
      grid-row: 1 / 3;
      grid-column: 1;
    }
    & > h4 {
      grid-column: 2;
      grid-row: 1;
      font-weight: 700;
      margin: 0;
      text-transform: uppercase;
    }
    & > .ant-switch {
      align-self: center;
      grid-column: 3;
      grid-row: 1;
    }
    & > span {
      grid-column: 2 / 4;
      grid-row: 2;
      margin: 0;
      font-weight: 500;
      line-height: 22px;
    }
  }
`

type Application = typeof applications extends (infer App)[] ? App : never

const ModuleSelector = ({
  name,
  descriptionKey,
  icon,
  disabled,
}: Application & { disabled: boolean }) => {
  const ws = useCurrentWorkspace()
  const dispatch = useDispatch()
  const permissions = ws?.type === 'organization' ? ws.organization.modules : []
  const organization = ws?.type === 'organization' ? ws.organization : undefined
  const isLoading = useSelector((s) =>
    loadable.isLoading(s.organization.updateResult)
  )

  const hasPermission = permissions.includes(name)
  return (
    <ModuleSelectorGrid>
      <Icon color={disabled && !hasPermission ? '#79747E' : '#51b148'}>
        {icon}
      </Icon>
      <Typography.Title level={4} type="secondary">
        <TranslatedMessage id={name} />
      </Typography.Title>
      <Switch
        loading={isLoading}
        checked={hasPermission}
        disabled={disabled}
        onChange={(checked) => {
          const modules = checked
            ? [...new Set([...permissions, name])]
            : permissions.filter((e) => e !== name)

          dispatch(
            // @ts-ignore
            updateOrganizationModulesAction({ ...organization, modules })
          )
        }}
      />
      <Typography.Text>
        <TranslatedMessage id={descriptionKey} />
      </Typography.Text>
    </ModuleSelectorGrid>
  )
}

const ModulesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
`

const OrganizationModules = () => {
  const ws = useCurrentWorkspace()
  const organization = ws?.type === 'organization' ? ws.organization : undefined
  return (
    <ModulesGrid>
      {applications
        .filter(
          (app) =>
            app.name !== 'SMART_HUB' &&
            ((app.name !== 'BACKOFFICE' &&
              app.name !== 'DASHBOARD' &&
              app.name !== 'MARKETPLACE') ||
              organization?.name === 'PROSPERUS')
        )
        .map((app) => (
          <ModuleSelector
            {...app}
            key={app.name}
            disabled={
              app.name === 'CLEARING' && organization?.name !== 'PROSPERUS'
            }
          />
        ))}
    </ModulesGrid>
  )
}
export default OrganizationModules
