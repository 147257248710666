import { Loadable, Organization } from '@library/react-toolkit'
import {
  FailableAction,
  failableActionCreator,
  PayloadAction,
  payloadActionCreator,
} from '../../types/Actions'

export type State = {
  updateResult: Loadable<unknown>
}

export type UpdateOrganizationAction = PayloadAction<
  'ORGANIZATION/update_organization',
  Organization
>
export const updateOrganizationAction =
  payloadActionCreator<UpdateOrganizationAction>(
    'ORGANIZATION/update_organization'
  )

export type UpdateOrganizationResultAction = FailableAction<
  'ORGANIZATION/update_organization_result',
  Organization
>
export const updateOrganizationResultAction =
  failableActionCreator<UpdateOrganizationResultAction>(
    'ORGANIZATION/update_organization_result'
  )

export type UpdateOrganizationModulesAction = PayloadAction<
  'ORGANIZATION/update_organization_modules',
  Organization
>
export const updateOrganizationModulesAction =
  payloadActionCreator<UpdateOrganizationModulesAction>(
    'ORGANIZATION/update_organization_modules'
  )

export type UpdateOrganizationModulesResultAction = FailableAction<
  'ORGANIZATION/update_organization_modules_result',
  Organization
>
export const updateOrganizationModulesResultAction =
  failableActionCreator<UpdateOrganizationModulesResultAction>(
    'ORGANIZATION/update_organization_modules_result'
  )

export type Actions =
  | UpdateOrganizationAction
  | UpdateOrganizationResultAction
  | UpdateOrganizationModulesAction
  | UpdateOrganizationModulesResultAction
