import {
  ModulePermissions,
  OrganizationUser,
  useCurrentWorkspace,
} from '@library/react-toolkit'
import { Col, Row, Space, Table, Tag, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { array, option } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Search from 'antd/lib/input/Search'

import {
  applications,
  getOrganizationUsers,
} from '../../../components/Account/types'
import { State } from '../../../shared/state/store'
import { TranslatedMessage } from '../../../shared/translations/data'
import { loadable } from '../../../shared/types'
import UserAddModal from './userModals/UserAddModal'
import UserDeleteModal from './userModals/UserDeleteModal'
import UserEditModal from './userModals/UserEditModal'

const UserManagement = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const ws = useCurrentWorkspace()

  console.log(ws)

  const users = useSelector((s: State) =>
    option.toUndefined(loadable.toOption(s.accountSetting.organizationUsers))
  )

  const loading = useSelector(
    (s: State) => !loadable.isOk(s.accountSetting.organizationUsers)
  )

  const addDeleteOrUpdateResult = useSelector(
    (s: State) => s.accountSetting.organizationUpdateResult._tag
  )

  const organizationPermissions =
    ws?.type === 'organization' ? ws.organization.modules : []

  const [page, setPage] = React.useState(0)
  const [itemsPerPage, setItemsPerPage] = React.useState(10)
  const [search, setSearch] = React.useState('')

  React.useEffect(() => {
    if (
      !!ws &&
      addDeleteOrUpdateResult !== 'Loading' &&
      addDeleteOrUpdateResult !== 'Err'
    ) {
      dispatch(getOrganizationUsers({ page, itemsPerPage, search }))
    }
  }, [ws, page, itemsPerPage, search, addDeleteOrUpdateResult])

  const columns: ColumnsType<OrganizationUser & { key: string }> = [
    {
      title: <TranslatedMessage id="ROLE_USER" />,
      dataIndex: 'login',
      key: 'login',
      align: 'left',
      className: 'userTable',
      render: (text: any) => (
        <Space direction="vertical" size="small">
          {text ? text : 'N/A'}
        </Space>
      ),
    },
    {
      title: <TranslatedMessage id="emailLabel" />,
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      className: 'userTable',
      render: (text: any) => (
        <Row style={{ verticalAlign: 'baseline' }} gutter={10}>
          {text ? text : 'N/A'}
        </Row>
      ),
    },
    {
      title: <TranslatedMessage id="fullName" />,
      dataIndex: 'fullName',
      key: 'fullName',
      align: 'left',
      width: '10%',
      className: 'userTable',
      render: (text: any) => (
        <Row style={{ verticalAlign: 'baseline' }} gutter={10}>
          {text ? text : 'N/A'}
        </Row>
      ),
    },
    {
      title: <TranslatedMessage id="jobTitle" />,
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      align: 'left',
      width: '10%',
      className: 'userTable',
      render: (_, row) => (
        <Row style={{ verticalAlign: 'baseline' }} gutter={10}>
          {option.toUndefined(row.jobTitle) ?? 'N/A'}
        </Row>
      ),
    },
    {
      title: <TranslatedMessage id="Permissions" />,
      dataIndex: 'modulePermissions',
      key: 'modulePermissions',
      width: '35%',
      align: 'left',
      render: (permissions: ModulePermissions) => {
        console.log(permissions)
        console.log(organizationPermissions)
        return (
          <Space size="small" wrap>
            {pipe(
              applications,
              array.filter(
                (app) =>
                  organizationPermissions.includes(app.name) &&
                  !!permissions[app.name]
              ),
              (apps) =>
                array.isEmpty(apps) ? (
                  <TranslatedMessage id="noPermissions" />
                ) : (
                  apps.map((app) => {
                    const p = permissions[app.name]
                    return (
                      <Typography.Text
                        key={app.descriptionKey}
                        style={{ textAlign: 'center' }}
                      >
                        <Tooltip title={<FormattedMessage id={p ?? 'NONE'} />}>
                          <Tag
                            color={
                              p === 'CONTRIBUTOR'
                                ? 'success'
                                : p === 'VIEWER'
                                ? 'processing'
                                : 'default'
                            }
                          >
                            <FormattedMessage id={app.name} />
                          </Tag>
                        </Tooltip>
                      </Typography.Text>
                    )
                  })
                )
            )}
          </Space>
        )
      },
    },
    {
      dataIndex: 'authority',
      key: 'authority',
      align: 'center',
      render: (_, v) => (
        <Tag color={v.authority === 'ADMIN' ? 'success' : 'default'}>
          <FormattedMessage id={v.authority} />
        </Tag>
      ),
    },
    {
      key: 'action',
      align: 'left',
      render: (_, record) => (
        <Row justify="end">
          <Space size="middle">
            <UserDeleteModal user={record} />
            <UserEditModal user={record} />
          </Space>
        </Row>
      ),
    },
  ]

  const data = users?.data?.map(({ ...u }) => ({
    ...u,
    key: u.login,
    fullName: `${u.firstName} ${u.lastName}`,
  }))

  return (
    <Row gutter={[30, 30]}>
      <Col>
        <Search
          placeholder={intl.formatMessage({ id: 'SearchUser' })}
          allowClear
          onSearch={(id) => {
            setSearch(id)
          }}
          onAbort={() => {
            setSearch('')
          }}
        />
      </Col>
      <Col flex="auto"></Col>
      <Col>
        <UserAddModal />
      </Col>
      <Col span={24}>
        <Table
          size="small"
          columns={columns}
          dataSource={data}
          loading={loading}
          className="userTable"
          onChange={(p) => {
            if (!!p.current) {
              setPage(p.current - 1)
            }

            if (!!p.pageSize) {
              setItemsPerPage(p.pageSize)
            }
          }}
          pagination={{
            current: page + 1,
            pageSize: itemsPerPage,
            total: users?.total,
          }}
        />
      </Col>
    </Row>
  )
}
export default UserManagement
