import * as t from 'io-ts'
import { withFallback } from 'io-ts-types'

export const Affiliate = t.type(
  {
    affiliateID: t.string,
    organization: t.string,
    nym: t.string,
    status: t.string,
  },
  'Affiliate'
)

export type Affiliate = t.TypeOf<typeof Affiliate>

export const VerifyAffiliateRequest = t.type(
  {
    affiliateID: t.string,
    verified: t.boolean,
  },
  'VerifyAffiliateRequest'
)

export type VerifyAffiliateRequest = t.TypeOf<typeof VerifyAffiliateRequest>

export const AffiliateProfile = t.type(
  {
    nickname: withFallback(t.string, 'N/A'),
    emailAddress: withFallback(t.string, 'N/A'),
  },
  'AffiliateProfile'
)

export type AffiliateProfile = t.TypeOf<typeof AffiliateProfile>

export const AffiliateSearchParams = t.type(
  {
    page: t.number,
    itemsPerPage: t.number,
  },
  'AffiliateSearchParams'
)

export type AffiliateSearchParams = t.TypeOf<typeof AffiliateSearchParams>
