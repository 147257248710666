import { Badge, Button, Space, Table, Tag } from 'antd'
import Search from 'antd/lib/input/Search'
import * as React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { TranslatedMessage } from '../../../../shared/translations/data'
import OrgNameWithInfo from '../../../../shared/utils/OrgNameWithInfo'
import {
  useGetOrganizationsDetails,
  useOrganizationsParticipantsClearing,
} from './queries'
import { OrganisationsSearchParams, OrganizationDetails } from './types'
import { LinkOutlined } from '@ant-design/icons'
import AssetsAuthorization from './Modals/AssetsAuthorization'
import { useUnits } from '@library/react-toolkit'
import ConfirmSwitch from './Modals/ConfirmSwitch'

const ParticipantsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 24px;
`

export const ParticipantManagement = () => {
  const [query, setQuery] = React.useState<OrganisationsSearchParams>({
    page: 0,
    itemsPerPage: 10,
    module: 'CLEARING',
    search: undefined,
  })

  const intl = useIntl()
  const assets = useUnits()
  const orgClearingQuery = useOrganizationsParticipantsClearing(query)
  const orgClearing = orgClearingQuery.data?.data
    .filter((o) => o.name !== 'PROSPERUS')
    .map((org) => ({
      ...org,
      key: org.name,
    }))
  const organizationDetailsQuery = useGetOrganizationsDetails(
    orgClearing?.map((org) => org.name) ?? []
  )
  const organizationDetails: OrganizationDetails[] =
    organizationDetailsQuery.map((d) => {
      return (
        d.data ?? {
          organization: '',
          status: 'pending',
          full: [],
          fractional: [],
        }
      )
    })

  const data = orgClearing?.map((p) => {
    const details = organizationDetails.find(
      ({ organization }) => organization === p.name
    ) ?? {
      organization: '',
      status: 'pending',
      full: [],
      fractional: [],
    }
    return { ...p, ...details }
  })

  return (
    <ParticipantsContainer>
      <Search
        placeholder={intl.formatMessage({
          id: 'searchParticipant',
        })}
        style={{ width: '200px' }}
        allowClear
        onSearch={(v: string) =>
          setQuery((q) => ({
            ...q,
            search: v,
          }))
        }
        onAbort={() =>
          setQuery((q) => ({
            ...q,
            search: undefined,
          }))
        }
      />

      <Table
        loading={orgClearingQuery.isLoading}
        dataSource={data ?? []}
        columns={[
          {
            title: <TranslatedMessage id="orgName" />,
            key: 'displayName',
            dataIndex: 'displayName',
            render: (_, v) => (
              <OrgNameWithInfo
                context={{
                  displayName: v.displayName,
                  name: v.name,
                  logoUrl: v.logoUrl,
                }}
              />
            ),
          },
          {
            title: <TranslatedMessage id="adminEmail" />,
            key: 'email',
            dataIndex: 'email',
          },
          {
            title: <TranslatedMessage id="status" />,
            width: '10%',
            key: 'status',
            render: (_, r) => {
              const status =
                r.status === 'active'
                  ? 'success'
                  : r.status === 'blocked'
                  ? 'error'
                  : 'warning'
              return (
                <Badge
                  status={status}
                  text={<TranslatedMessage id={r.status} />}
                />
              )
            },
          },
          {
            title: <TranslatedMessage id="assetTitle" />,
            key: 'assets',
            render: (_, r) => (
              <Space>
                {r.full.map((a) => (
                  <Tag key={`full ${a}`} color="blue">
                    {assets[a].name}
                  </Tag>
                ))}
                {r.fractional.map((a) => (
                  <Tag key={`frac ${a}`} color="orange">
                    {assets[a].name}
                  </Tag>
                ))}
              </Space>
            ),
          },
          {
            title: <TranslatedMessage id="actions" />,
            width: '10%',
            key: 'actions',
            render: (_, r) => {
              return (
                <Space>
                  <ConfirmSwitch name={r.name} status={r.status} />
                  <AssetsAuthorization
                    key={r.name}
                    participant={r.name}
                    displayName={r.displayName}
                    disabled={r.status === 'pending'}
                  />
                  <Button type={'text'} icon={<LinkOutlined />}></Button>
                </Space>
              )
            },
          },
        ]}
        pagination={{
          pageSize: query.itemsPerPage,
          onChange: (p, ipp) =>
            setQuery((q) => ({
              ...q,
              page: p - 1,
              itemsPerPage: ipp ?? 10,
            })),
          total: orgClearingQuery.data?.count,
        }}
      />
    </ParticipantsContainer>
  )
}
