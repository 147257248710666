import { useOrganization } from '@library/react-toolkit'
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Upload,
} from 'antd'
import { array, either, ord, record } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import moment from 'moment'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { updateOrganizationAction } from '../../../shared/state/organization'
import { State } from '../../../shared/state/store'
import { TranslatedMessage } from '../../../shared/translations/data'
import { memoed } from '../../../shared/types'
import { countries, countryToFlag } from '../../../shared/utils/countyUtils'
import { useOrganizationImageMutation } from '../queries'
import { verifyOrganizationAbbreviationExists } from '../../Authentication/Login/api'
const { Option } = Select
const OrganizationInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 11fr;
`
const LogoColumn = styled.div``
const Info = styled.div`
  .ant-row {
    justify-content: space-around;
  }
`
const Save = styled(Button)`
  width: 251px;
  height: 32px;
`
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const ordBySnd: ord.Ord<[unknown, string]> = pipe(
  ord.ordString,
  ord.contramap((v) => v[1])
)

const OrganizationInformation = () => {
  const organization = useOrganization()
  const units = useSelector((s: State) => memoed.extract(s.units))
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [selectedImage, setSelectedImage] = React.useState<File | undefined>(
    undefined
  )

  const orgImageMutation = useOrganizationImageMutation()

  const image = selectedImage ? URL.createObjectURL(selectedImage) : undefined
  return (
    <Form
      form={form}
      initialValues={
        !!organization
          ? {
              id: organization.id,
              bankName: organization.bankName,
              url: undefined,
              iban: organization.iban,
              fiscalYear: moment(organization.fiscalYear, 'YYYY'),
              fiscalId: organization.fiscalId,
              name: organization.name,
              abbreviation: organization.abbreviation,
              displayName: organization.displayName,
              rib: organization.rib,
              employeesNumber: organization.employeesNumber,
              activity: organization.activity,
              country: organization.country,
              asset: organization.asset,
              email: organization.email,
            }
          : undefined
      }
      {...formItemLayout}
      onFinish={(values) => {
        if (!!organization) {
          dispatch(
            updateOrganizationAction({
              ...values,
              name: organization.name,
              fiscalYear: values.fiscalYear.year(),
              id: organization.id,
            })
          )
        }
      }}
    >
      <OrganizationInfoWrapper>
        <LogoColumn>
          <Upload
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={(file) => {
              const isPng = file.type === 'image/png'
              if (!isPng) {
                message.error('You can only upload PNG file!')
                return false
              }
              if (file.size > 2 * 1024 * 1024) {
                message.error('Image must smaller than 2MB!')
                return false
              }
              setSelectedImage(file)
              return false
            }}
          >
            <img
              src={image ?? `/images/uaa/${organization?.logoUrl}`}
              alt="Company logo"
              style={{ width: '100%' }}
            />
          </Upload>
          <Save
            loading={orgImageMutation.isLoading}
            type="primary"
            disabled={!selectedImage}
            onClick={() => {
              if (!selectedImage) {
                return
              }
              const file = new FormData()
              file.append('file', selectedImage)
              orgImageMutation.mutate(file)
            }}
          >
            <TranslatedMessage id="save" />
          </Save>
        </LogoColumn>
        <Info>
          <Row>
            <Col span={10}>
              <Form.Item
                label={<TranslatedMessage id="organizationName" />}
                name="displayName"
                rules={[
                  {
                    required: true,
                    message: <TranslatedMessage id="missingCompanyNameMsg" />,
                  },
                ]}
              >
                <Input
                  placeholder="Organization Name"
                  minLength={1}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<TranslatedMessage id="organizationAbbreviation" />}
                name="abbreviation"
                rules={[
                  {
                    pattern: new RegExp(/[A-Z]+/),
                    message: <TranslatedMessage id="onlyAlphabets" />,
                  },
                  {
                    required:
                      organization!.abbreviation.length === 0 ? true : false,
                    message: (
                      <TranslatedMessage id="missingCompanyAbbreviationMsg" />
                    ),
                  },
                  {
                    message: (
                      <TranslatedMessage id="OrganizationAbbreviationExist" />
                    ),
                    validator:
                      organization!.abbreviation.length === 0
                        ? (_, v) =>
                            verifyOrganizationAbbreviationExists(v)().then(
                              either.fold(
                                () => {},
                                () => {
                                  throw 'exists'
                                }
                              )
                            )
                        : undefined,
                  },
                ]}
              >
                <Input
                  disabled={!organization!.abbreviation ? false : true}
                  placeholder="Abbreviation"
                  minLength={2}
                  maxLength={4}
                  onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
                    (event.target.value = event.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item
                label={<TranslatedMessage id="fiscal_ID" />}
                name="fiscalId"
              >
                <Input placeholder="Fiscal ID" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<TranslatedMessage id="Fiscal_Year" />}
                name="fiscalYear"
              >
                <DatePicker
                  style={{ width: '100%' }}
                  mode="year"
                  picker="year"
                  format="YYYY"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item
                label="RIB"
                name="rib"
                rules={[
                  {
                    required: true,
                    message: <TranslatedMessage id="missingRIBMsg" />,
                  },
                ]}
              >
                <Input type="number" placeholder="RIB" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<TranslatedMessage id="bank" />}
                name="bankName"
              >
                <Input placeholder="Bank" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item label={<TranslatedMessage id="IBAN" />} name="iban">
                <Input placeholder="IBAN" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<TranslatedMessage id="organizationEmployees" />}
                name="employeesNumber"
              >
                <Select
                  placeholder={<TranslatedMessage id="employees" />}
                  allowClear
                >
                  <Option value="Less then 5">
                    <TranslatedMessage id="Less-then-5" />
                  </Option>
                  <Option value="Between 5 and 10">
                    <TranslatedMessage id="Between-5-and-10" />
                  </Option>
                  <Option value="Between 10 and 20">
                    <TranslatedMessage id="Between-10-and-20" />
                  </Option>
                  <Option value="Between 20 and 50">
                    <TranslatedMessage id="Between-20-and-50" />
                  </Option>
                  <Option value="Between 50 and 100">
                    <TranslatedMessage id="Between-50-and-100" />
                  </Option>
                  <Option value="Greater then 100">
                    <TranslatedMessage id="Greater-then-100" />
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item
                label={<TranslatedMessage id="activity" />}
                name="activity"
              >
                <Select
                  placeholder={<TranslatedMessage id="activity" />}
                  allowClear
                >
                  <Option value="Financial Sector">
                    <TranslatedMessage id="Financial_Sector" />
                  </Option>
                  <Option value="Services">
                    <TranslatedMessage id="Services" />
                  </Option>
                  <Option value="Public Sector">
                    <TranslatedMessage id="Public_Sector" />
                  </Option>
                  <Option value="NonProfit">
                    <TranslatedMessage id="NgoNonProfit" />
                  </Option>
                  <Option value="Agriculture">
                    <TranslatedMessage id="Agriculture" />
                  </Option>
                  <Option value="Entertainment">
                    <TranslatedMessage id="Entertainment" />
                  </Option>
                  <Option value="Information Technology">
                    <TranslatedMessage id="Information-Technologies" />
                  </Option>
                  <Option value="Consumer Goods">
                    <TranslatedMessage id="Consumer Goods" />
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<TranslatedMessage id="country" />}
                name="country"
              >
                <AutoComplete placeholder="Country">
                  {countries.map(({ code, label, phone }) => (
                    <Option key={code} value={label}>
                      <Space>
                        <span>{countryToFlag(code)}</span>
                        {label} ({code}) +{phone}
                      </Space>
                    </Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Form.Item
                name="asset"
                label={<TranslatedMessage id="asset" />}
                rules={[
                  {
                    required: true,
                    message: <TranslatedMessage id="defaultUnitRequired" />,
                  },
                ]}
              >
                <Select>
                  {pipe(
                    units,
                    record.map((u) => `${u.name} ${u.symbol}`),
                    record.toArray,
                    array.sort(ordBySnd),
                    array.map((v) => (
                      <Select.Option value={v[0]} key={v[0]}>
                        {v[1]}
                      </Select.Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={<TranslatedMessage id="email" />}
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: <TranslatedMessage id="invalidEmail" />,
                  },
                ]}
              >
                <Input placeholder="Organization Email" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[8, 8]} justify="end">
            <Col>
              <Save htmlType="submit" type="primary">
                <TranslatedMessage id="save" />
              </Save>
            </Col>
          </Row>
        </Info>
      </OrganizationInfoWrapper>
    </Form>
  )
}

export default OrganizationInformation
