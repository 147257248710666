import { useOrganization } from '@library/react-toolkit'
import { taskEither, task, either } from 'fp-ts'
import { pipe } from 'fp-ts/lib/function'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { isAxiosError } from '../../../axios'
import {
  deleteAffiliate,
  getOrgAffiliates,
  getProfileByNym,
  verifyAffiliate,
} from './api'
import { AffiliateSearchParams, VerifyAffiliateRequest } from './types'

export const throwLeft: <L, R>(
  ma: taskEither.TaskEither<L, R>
) => task.Task<R> = task.map(
  either.fold(
    (e) => {
      throw e
    },
    (x) => x
  )
)

export const defaultQuerySettings = {
  staleTime: 1000 * 60 * 5,
  retry: (_: number, e: Error) => isAxiosError(e) && e.response?.status !== 404,
}

export const useOrgAffiliates = (params: AffiliateSearchParams) => {
  const org = useOrganization()
  return useQuery(
    [org?.name ?? '', 'affiliates'],
    pipe(getOrgAffiliates(org?.name ?? '', params), throwLeft),
    {
      ...defaultQuerySettings,
      enabled: !!org,
    }
  )
}

export const useProfile = (pk: string) => {
  return useQuery([pk, 'user-profile'], pipe(getProfileByNym(pk), throwLeft), {
    ...defaultQuerySettings,
    enabled: !!pk,
  })
}

export const useVerifyAffiliate = () => {
  const queryClient = useQueryClient()
  const org = useOrganization()
  return useMutation(
    [org?.name ?? '', 'affiliates-verification'],
    (req: VerifyAffiliateRequest) =>
      pipe(verifyAffiliate(org?.name ?? '', req), throwLeft)(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([org?.name ?? '', 'affiliates'])
      },
      onError: (e) => {
        console.debug(e)
      },
    }
  )
}

export const useDeleteAffiliate = () => {
  const queryClient = useQueryClient()
  const org = useOrganization()
  return useMutation(
    [org?.name ?? '', 'delete-affiliate'],
    (id: string) => pipe(deleteAffiliate(org?.name ?? '', id), throwLeft)(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([org?.name ?? '', 'affiliates'])
      },
      onError: (e) => {
        console.debug(e)
      },
    }
  )
}
