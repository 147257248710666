import { Button, Card, Typography } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { TranslatedMessage } from '../../../shared/translations/data'

const SiginUpTypesCard = styled(Card)`
  max-width: 770px;
  max-height: 650px;
`
const SiginUpTypesHeader = styled(Typography.Title)`
  text-align: center;
  padding: 24px;
`

const TypesContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
`
const TypeCard = styled(Card)`
  width: 320px;
  min-height: 473px;
  display: grid;
  grid-template-columns: auto;
  .ant-card-actions {
    width: 100%;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-self: flex-end;
    align-items: stretch;
    justify-content: flex-end;
    border-top: 0;
    > li {
      margin: 0;
      padding-left: 31px;
      padding-right: 31px;
      border-right: 0px;
      > span {
        flex-grow: 1;
        width: 100%;
      }
    }
  }
`

const TypeCardHeader = styled(Typography.Title)`
  text-align: center;
  padding: 24px;
`

const SignUpButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  align-content: stretch;
`

const btnSignupBill = (
  <Button style={{ marginTop: '24px' }} key="signup-bill" type="primary" block>
    <TranslatedMessage id="signupBills" />
  </Button>
)

const btnSignupFactoring = (
  <Button
    key="signup-factoring"
    disabled
    type="primary"
    style={{ marginTop: '24px' }}
    block
  >
    {<TranslatedMessage id="signupFactoring" />}
  </Button>
)

const btnSignupRewards = (
  <Button
    key="signup-reward"
    type="primary"
    style={{ marginTop: '24px' }}
    block
  >
    {<TranslatedMessage id="signupReward" />}
  </Button>
)

const btnSignupBillsWallets = (
  <Button
    key="signup-bills-wallets"
    type="primary"
    style={{ marginTop: '24px' }}
    block
  >
    {<TranslatedMessage id="signupBillsWallets" />}
  </Button>
)

const btnSignupClearing = (
  <Button
    key="signup-clearing"
    type="primary"
    style={{ marginTop: '24px' }}
    block
  >
    {' '}
    {<TranslatedMessage id="signupClearing" />}
  </Button>
)

const ProfessionalTab = [
  {
    key: 'SME',
    tab: <TranslatedMessage id="signupProfessionalSme" />,
  },
  {
    key: 'ENTREPRISE',
    tab: <TranslatedMessage id="signupProfessionalEntreprise" />,
  },
  {
    key: 'BANKS',
    tab: <TranslatedMessage id="signupProfessionalBanks" />,
  },
]

const tabButtons: Record<string, React.ReactNode> = {
  SME: (
    <SignUpButtonsContainer key={'btn-list-SME'}>
      <Link to="/auth/signup?type=sme">{btnSignupBill}</Link>
    </SignUpButtonsContainer>
  ),
  ENTREPRISE: (
    <SignUpButtonsContainer key={'btn-list-ENTREPRISE'}>
      <Link to="/auth/signup?type=entreprise-factoring">
        {btnSignupFactoring}
      </Link>
      <Link to="/auth/signup?type=entreprise-rewards">{btnSignupRewards}</Link>
      <Link to="/auth/signup?type=entreprise-bills-wallets">
        {btnSignupBillsWallets}
      </Link>
    </SignUpButtonsContainer>
  ),
  BANKS: (
    <SignUpButtonsContainer key={'btn-list-BANKS'}>
      <Link to="/auth/signup?type=banks-factoring">{btnSignupFactoring}</Link>
      <Link to="/auth/signup?type=banks-clearing">{btnSignupClearing}</Link>
    </SignUpButtonsContainer>
  ),
}

const tabContent: Record<string, React.ReactNode> = {
  SME: <TranslatedMessage id="signupProfessionalSmeDescription" />,
  ENTREPRISE: (
    <TranslatedMessage id="signupProfessionalEntrepriseDescription" />
  ),
  BANKS: <TranslatedMessage id="signupProfessionalBanksDescription" />,
}

const SiginUpTypesModal = () => {
  const [activeTabKey, setActiveTabKey] = useState<string>('SME')

  const ontabChange = (key: string) => {
    setActiveTabKey(key)
  }
  return (
    <SiginUpTypesCard>
      <SiginUpTypesHeader level={4}>
        <TranslatedMessage id="signUpProsperus" />
      </SiginUpTypesHeader>
      <TypesContainer>
        <TypeCard
          actions={[
            <Link key={'link-personal-signup'} to="/auth/signup?type=personal">
              <Button key="individual-signup" type="primary" block>
                <TranslatedMessage id="signUp" />
              </Button>
            </Link>,
          ]}
        >
          <TypeCardHeader level={5}>
            <TranslatedMessage id="signupPersonal" />
          </TypeCardHeader>
          <Typography.Paragraph style={{ textAlign: 'center' }}>
            <TranslatedMessage id="signupPersonalDescription" />
          </Typography.Paragraph>
        </TypeCard>
        <TypeCard
          tabList={ProfessionalTab}
          onTabChange={ontabChange}
          actions={[tabButtons[activeTabKey]]}
          title={
            <TypeCardHeader level={5}>
              <TranslatedMessage id="signupProfessional" />
            </TypeCardHeader>
          }
        >
          <Typography.Paragraph style={{ textAlign: 'center' }}>
            {tabContent[activeTabKey]}
          </Typography.Paragraph>
        </TypeCard>
      </TypesContainer>
      <Typography.Paragraph style={{ marginTop: 16, textAlign: 'center' }}>
        <TranslatedMessage id="AlreadyHaveAnAccount" />
        <Button type="link" color="primary">
          <Link to="/auth/login">
            <TranslatedMessage id="login" />
          </Link>
        </Button>
      </Typography.Paragraph>
    </SiginUpTypesCard>
  )
}

export default SiginUpTypesModal
