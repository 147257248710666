import React from 'react'
import OrganizationSignUpWithAccount from './OrganizationSignUpWithAccount'
import OrganizationSignUpWithoutAccount from './OrganizationSignUpWithoutAccount'
import { Module, useAccount } from '@library/react-toolkit'

const OrganizationSignUp = ({ modules }: { modules: Module[] }) => {
  const account = useAccount()
  return !!account ? (
    <OrganizationSignUpWithAccount modules={modules} account={account} />
  ) : (
    <OrganizationSignUpWithoutAccount modules={modules} />
  )
}

export default OrganizationSignUp
