import { Col, Descriptions, Row, Skeleton, Table, Typography } from 'antd'
import * as React from 'react'
import { TranslatedMessage } from '../../../../shared/translations/data'
import AMSettings from './Modals/ValidationSettings'
import { useGetApprovalConfig } from './queries'

const { Title } = Typography

export const MintValidation = () => {
  const approvalConfigQuery = useGetApprovalConfig('minting')
  return (
    <>
      <Row>
        <Col flex={'auto'}>
          <AMSettings tag="minting" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Descriptions layout="vertical">
            <Descriptions.Item
              label={
                <Title level={5}>
                  <TranslatedMessage id={'AMSettings_Num_Contributors'} />
                </Title>
              }
            >
              {approvalConfigQuery.isLoading ? (
                <Skeleton.Input active />
              ) : (
                approvalConfigQuery.data?.users &&
                approvalConfigQuery.data?.users.length
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Title level={5}>
                  <TranslatedMessage id={'AMSettings_Min_Required'} />
                </Title>
              }
            >
              {approvalConfigQuery.isLoading ? (
                <Skeleton.Input active />
              ) : (
                approvalConfigQuery.data?.minimum
              )}
            </Descriptions.Item>
          </Descriptions>
          <Table
            loading={approvalConfigQuery.isLoading}
            pagination={false}
            columns={[
              {
                title: <TranslatedMessage id={'AMSettings_Validators'} />,
                dataIndex: 'validator',
                key: 'validator',
              },
            ]}
            dataSource={
              approvalConfigQuery.data?.users &&
              approvalConfigQuery.data?.users.map((u) => ({
                key: u,
                validator: u,
              }))
            }
          />
        </Col>
      </Row>
    </>
  )
}
