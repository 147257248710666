{
  "AED": {
     "name": "UAE Dirham",       
     "decimalPoints": 2
  },
  "ALL": {
     "name": "Albanian Lek",     
     "decimalPoints": 2
  },
  "AMD": {
     "name": "Armenian Dram",    
     "decimalPoints": 2
  },
  "ANG": {
     "name": "Antillian Guilder",
     "decimalPoints": 2
  },
  "AOA": {
     "name": "Angolan Kwanza",
     "decimalPoints": 2
  },
  "ARS": {
     "name": "Nuevo Argentine Peso",
     "decimalPoints": 2
  },
  "AUD": {
     "name": "Australian Dollar",
     "decimalPoints": 2
  },
  "AWG": {
     "name": "Aruban Guilder",
     "decimalPoints": 2
  },
  "AZN": {
     "name": "Azerbaijani manat",
     "decimalPoints": 2
  },
  "BAM": {
     "name": "Bosnia and Herzegovina Convertible Marks",
     "decimalPoints": 2
  },
  "BBD": {
     "name": "Barbados Dollar",
     "decimalPoints": 2
  },
  "BDT": {
     "name": "Bangladesh Taka",
     "decimalPoints": 2
  },
  "BGN": {
     "name": "New Bulgarian Lev",
     "decimalPoints": 2
  },
  "BHD": {
     "name": "Bahraini Dinar",
     "decimalPoints": 3
  },
  "BMD": {
     "name": "Bermudian Dollar",
     "decimalPoints": 2
  },
  "BND": {
     "name": "Brunei Dollar",
     "decimalPoints": 2
  },
  "BOB": {
     "name": "Bolivia Boliviano",
     "decimalPoints": 2
  },
  "BRL": {
     "name": "Brazilian Real",
     "decimalPoints": 2
  },
  "BSD": {
     "name": "Bahamian Dollar",
     "decimalPoints": 2
  },
  "BWP": {
     "name": "Botswana Pula",
     "decimalPoints": 2
  },
  "BYN": {
     "name": "New Belarusian Ruble",
     "decimalPoints": 2
  },
  "BZD": {
     "name": "Belize Dollar",
     "decimalPoints": 2
  },
  "CAD": {
     "name": "Canadian Dollar",
     "decimalPoints": 2
  },
  "CHF": {
     "name": "Swiss Franc",
     "decimalPoints": 2
  },
  "CLP": {
     "name": "Chilean Peso",
     "decimalPoints": 2
  },
  "CNY": {
     "name": "Yuan Renminbi",
     "decimalPoints": 2
  },
  "COP": {
     "name": "Colombian Peso",
     "decimalPoints": 2
  },
  "CRC": {
     "name": "Costa Rican Colon",
     "decimalPoints": 2
  },
  "CUP": {
     "name": "Cuban Peso",
     "decimalPoints": 2
  },
  "CVE": {
     "name": "Cape Verdi Escudo",
     "decimalPoints": 0
  },
  "CZK": {
     "name": "Czech Koruna",
     "decimalPoints": 2
  },
  "DJF": {
     "name": "Djibouti Franc",
     "decimalPoints": 0
  },
  "DKK": {
     "name": "Danish Krone",
     "decimalPoints": 2
  },
  "DOP": {
     "name": "Dominican Republic Peso",
     "decimalPoints": 2
  },
  "DZD": {
     "name": "Algerian Dinar",
     "decimalPoints": 2
  },
  "EGP": {
     "name": "Egyptian Pound",
     "decimalPoints": 2
  },
  "ETB": {
     "name": "Ethiopian Birr",
     "decimalPoints": 2
  },
  "EUR": {
     "name": "Euro",
     "decimalPoints": 2
  },
  "FJD": {
     "name": "Fiji Dollar",
     "decimalPoints": 2
  },
  "FKP": {
     "name": "Falkland Islands Pound",
     "decimalPoints": 2
  },
  "GBP": {
     "name": "Pound Sterling",
     "decimalPoints": 2
  },
  "GEL": {
     "name": "Georgian Lari",
     "decimalPoints": 2
  },
  "GHS": {
     "name": "Ghanaian Cedi (3rd)",
     "decimalPoints": 2
  },
  "GIP": {
     "name": "Gibraltar Pound",
     "decimalPoints": 2
  },
  "GMD": {
     "name": "Gambia Delasi",
     "decimalPoints": 2
  },
  "GNF": {
     "name": "Guinea Franc",
     "decimalPoints": 0
  },
  "GTQ": {
     "name": "Guatemala Quetzal",
     "decimalPoints": 2
  },
  "GYD": {
     "name": "Guyanese Dollar",
     "decimalPoints": 2
  },
  "HKD": {
     "name": "Hong Kong Dollar",
     "decimalPoints": 2
  },
  "HNL": {
     "name": "Honduras Lempira",
     "decimalPoints": 2
  },
  "HTG": {
     "name": "Haitian Gourde",
     "decimalPoints": 2
  },
  "HUF": {
     "name": "Hungarian Forint",
     "decimalPoints": 2
  },
  "IDR": {
     "name": "Indonesian Rupiah",
     "decimalPoints": 0
  },
  "ILS": {
     "name": "New Israeli Scheqel",
     "decimalPoints": 2
  },
  "INR": {
     "name": "Indian Rupee",
     "decimalPoints": 2
  },
  "IQD": {
     "name": "Iraqi Dinar",
     "decimalPoints": 3
  },
  "ISK": {
     "name": "Iceland Krona",
     "decimalPoints": 2
  },
  "JMD": {
     "name": "Jamaican Dollar",
     "decimalPoints": 2
  },
  "JOD": {
     "name": "Jordanian Dinar",
     "decimalPoints": 3
  },
  "JPY": {
     "name": "Japanese Yen",
     "decimalPoints": 0
  },
  "KES": {
     "name": "Kenyan Shilling",
     "decimalPoints": 2
  },
  "KGS": {
     "name": "Kyrgyzstan Som",
     "decimalPoints": 2
  },
  "KHR": {
     "name": "Cambodia Riel",
     "decimalPoints": 2
  },
  "KMF": {
     "name": "Comoro Franc",
     "decimalPoints": 0
  },
  "KRW": {
     "name": "South-Korean Won",
     "decimalPoints": 0
  },
  "KWD": {
     "name": "Kuwaiti Dinar",
     "decimalPoints": 3
  },
  "KYD": {
     "name": "Cayman Islands Dollar",
     "decimalPoints": 2
  },
  "KZT": {
     "name": "Kazakhstani Tenge",
     "decimalPoints": 2
  },
  "LAK": {
     "name": "Laos Kip",
     "decimalPoints": 2
  },
  "LBP": {
     "name": "Lebanese Pound",
     "decimalPoints": 2
  },
  "LKR": {
     "name": "Sri Lanka Rupee",
     "decimalPoints": 2
  },
  "LYD": {
     "name": "Libyan Dinar",
     "decimalPoints": 3
  },
  "MAD": {
     "name": "Moroccan Dirham",
     "decimalPoints": 2
  },
  "MDL": {
     "name": "Moldovia Leu",
     "decimalPoints": 2
  },
  "MKD": {
     "name": "Macedonian Denar",
     "decimalPoints": 2
  },
  "MMK": {
     "name": "Myanmar Kyat",
     "decimalPoints": 2
  },
  "MNT": {
     "name": "Mongolia Tugrik",
     "decimalPoints": 2
  },
  "MOP": {
     "name": "Macau Pataca",
     "decimalPoints": 2
  },
  "MRU": {
     "name": "Mauritania Ouguiya",
     "decimalPoints": 2
  },
  "MUR": {
     "name": "Mauritius Rupee",
     "decimalPoints": 2
  },
  "MVR": {
     "name": "Maldives Rufiyaa",
     "decimalPoints": 2
  },
  "MWK": {
     "name": "Malawi Kwacha",
     "decimalPoints": 2
  },
  "MXN": {
     "name": "Mexican Peso",
     "decimalPoints": 2
  },
  "MYR": {
     "name": "Malaysian Ringgit",
     "decimalPoints": 2
  },
  "MZN": {
     "name": "Mozambican Metical",
     "decimalPoints": 2
  },
  "NAD": {
     "name": "Namibian Dollar",
     "decimalPoints": 2
  },
  "NGN": {
     "name": "Nigerian Naira",
     "decimalPoints": 2
  },
  "NIO": {
     "name": "Nicaragua Cordoba Oro",
     "decimalPoints": 2
  },
  "NOK": {
     "name": "Norwegian Krone",
     "decimalPoints": 2
  },
  "NPR": {
     "name": "Nepalese Rupee",
     "decimalPoints": 2
  },
  "NZD": {
     "name": "New Zealand Dollar",
     "decimalPoints": 2
  },
  "OMR": {
     "name": "Rial Omani",
     "decimalPoints": 3
  },
  "PAB": {
     "name": "Panamanian Balboa",
     "decimalPoints": 2
  },
  "PEN": {
     "name": "Peruvian Nuevo Sol",
     "decimalPoints": 2
  },
  "PGK": {
     "name": "New Guinea Kina",
     "decimalPoints": 2
  },
  "PHP": {
     "name": "Philippine Peso",
     "decimalPoints": 2
  },
  "PKR": {
     "name": "Pakistan Rupee",
     "decimalPoints": 2
  },
  "PLN": {
     "name": "New Polish Zloty",
     "decimalPoints": 2
  },
  "PYG": {
     "name": "Paraguay Guarani",
     "decimalPoints": 0
  },
  "QAR": {
     "name": "Qatari Rial",
     "decimalPoints": 2
  },
  "RON": {
     "name": "New Romanian Lei",
     "decimalPoints": 2
  },
  "RSD": {
     "name": "Serbian Dinar",
     "decimalPoints": 2
  },
  "RUB": {
     "name": "Russian Ruble",
     "decimalPoints": 2
  },
  "RWF": {
     "name": "Rwanda Franc",
     "decimalPoints": 0
  },
  "SAR": {
     "name": "Saudi Riyal",
     "decimalPoints": 2
  },
  "SBD": {
     "name": "Solomon Island Dollar",
     "decimalPoints": 2
  },
  "SCR": {
     "name": "Seychelles Rupee",
     "decimalPoints": 2
  },
  "SEK": {
     "name": "Swedish Krone",
     "decimalPoints": 2
  },
  "SGD": {
     "name": "Singapore Dollar",
     "decimalPoints": 2
  },
  "SHP": {
     "name": "St. Helena Pound",
     "decimalPoints": 2
  },
  "SLE": {
     "name": "Sierra Leone Leone",
     "decimalPoints": 2
  },
  "SOS": {
     "name": "Somalia Shilling",
     "decimalPoints": 2
  },
  "SRD": {
     "name": "Surinamese dollar",
     "decimalPoints": 2
  },
  "STN": {
     "name": "Sao Tome \u0026 Principe Dobra",
     "decimalPoints": 2
  },
  "SVC": {
     "name": "El Salvador Colón",
     "decimalPoints": 2
  },
  "SZL": {
     "name": "Swaziland Lilangeni",
     "decimalPoints": 2
  },
  "THB": {
     "name": "Thai Baht",
     "decimalPoints": 2
  },
  "TND": {
     "name": "Tunisian Dinar",
     "decimalPoints": 3
  },
  "TOP": {
     "name": "Tonga Pa'anga",
     "decimalPoints": 2
  },
  "TRY": {
     "name": "New Turkish Lira",
     "decimalPoints": 2
  },
  "TTD": {
     "name": "Trinidad \u0026 Tobago Dollar",
     "decimalPoints": 2
  },
  "TWD": {
     "name": "New Taiwan Dollar",
     "decimalPoints": 2
  },
  "TZS": {
     "name": "Tanzanian Shilling",
     "decimalPoints": 2
  },
  "UAH": {
     "name": "Ukraine Hryvnia",
     "decimalPoints": 2
  },
  "UGX": {
     "name": "Uganda Shilling",
     "decimalPoints": 0
  },
  "USD": {
     "name": "US Dollars",
     "decimalPoints": 2
  },
  "UYU": {
     "name": "Peso Uruguayo",
     "decimalPoints": 2
  },
  "UZS": {
     "name": "Uzbekistani Som",
     "decimalPoints": 2
  },
  "VEF": {
     "name": "Venezuelan Bolívar",
     "decimalPoints": 2
  },
  "VND": {
     "name": "Vietnamese New Dong",
     "decimalPoints": 0
  },
  "VUV": {
     "name": "Vanuatu Vatu",
     "decimalPoints": 0
  },
  "WST": {
     "name": "Samoan Tala",
     "decimalPoints": 2
  },
  "XAF": {
     "name": "CFA Franc BEAC",
     "decimalPoints": 0
  },
  "XCD": {
     "name": "East Caribbean Dollar",
     "decimalPoints": 2
  },
  "XOF": {
     "name": "CFA Franc BCEAO",
     "decimalPoints": 0
  },
  "XPF": {
     "name": "CFP Franc",
     "decimalPoints": 0
  },
  "YER": {
     "name": "Yemeni Rial",
     "decimalPoints": 2
  },
  "ZAR": {
     "name": "South African Rand",
     "decimalPoints": 2
  },
  "ZMW": {
     "name": "Zambian Kwacha",
     "decimalPoints": 2
  }
}