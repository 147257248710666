import React from 'react'
import { Alert, Divider, Result, Steps } from 'antd'
import { TranslatedMessage } from '../../../shared/translations/data'
import SetUserInfo from './SetUserInfo'
import SetWallet from './SetWallet'
import SetPassword from './SetPassword'
import { useDispatch, useSelector } from 'react-redux'
import { signUpAction, SignUp as SignUpData } from '../Login'
import { loadable } from '@library/react-toolkit'

type Step = 0 | 1 | 2 | 3

const nextStep: Record<Step, Step> = { 0: 1, 1: 2, 2: 3, 3: 3 }
const prevStep: Record<Step, Step> = { 0: 0, 1: 0, 2: 1, 3: 2 }

const incrementStep = (s: Step): Step => nextStep[s]
const decrementStep = (s: Step): Step => prevStep[s]

const PersonalSignUp = () => {
  const dispatch = useDispatch()
  const failed = useSelector((s) => loadable.isErr(s.account.signUpResult))
  const success = useSelector((s) => loadable.isOk(s.account.signUpResult))

  const [step, setStep] = React.useState<Step>(0)
  const [user, setUser] = React.useState<SignUpData>({
    email: '',
    login: '',
    password: '',
    firstName: '',
    lastName: '',
    withWallet: false,
  })

  React.useEffect(() => {
    if (success) {
      setStep(3)
    }
  }, [success])

  const onSubmit = (v: Partial<SignUpData>) => {
    setUser((u) => ({ ...u, ...v }))
    if (step === 2) {
      dispatch(signUpAction({ ...user, ...v }))
    } else {
      setStep(incrementStep)
    }
  }

  const goBack = () => setStep(decrementStep)

  const onChange = (v: Partial<SignUpData>) => setUser((u) => ({ ...u, ...v }))

  const stepProps = { onSubmit, onChange, initialValue: user }

  return (
    <>
      <Steps progressDot current={step} size="small" style={{ color: 'green' }}>
        <Steps.Step title={<TranslatedMessage id="setUserInfo" />} />
        <Steps.Step title={<TranslatedMessage id="setWallet" />} />
        <Steps.Step title={<TranslatedMessage id="setPassword" />} />
        <Steps.Step title={<TranslatedMessage id="done" />} />
      </Steps>
      <Divider />
      {step === 0 ? (
        <SetUserInfo {...stepProps} />
      ) : step === 1 ? (
        <SetWallet {...stepProps} goBack={goBack} />
      ) : step === 2 ? (
        <SetPassword {...stepProps} goBack={goBack} />
      ) : (
        <Result
          status="success"
          title={<TranslatedMessage id="signUpSuccessTitle" />}
          subTitle={<TranslatedMessage id="signUpSuccessDescription" />}
        />
      )}
      {failed && (
        <Alert message={<TranslatedMessage id="signupFailed" />} type="error" />
      )}
    </>
  )
}

export default PersonalSignUp
