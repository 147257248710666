import { Organization } from '@library/react-toolkit'
import * as axios from '../../../axios'

export const updateOrganizationModules = (
  { name, modules }: Organization,
  cancelToken?: axios.CancelToken
) =>
  axios.put(`/uaa/api/organizations/${name}/modules`, modules, {
    cancelToken,
    decoder: Organization,
  })

export const updateOrganization = (
  org: Organization,
  cancelToken?: axios.CancelToken
) =>
  axios.put(
    '/uaa/api/organizations',
    // TODO: remove this when we have a form input for displayName
    !!org.displayName ? org : { ...org, displayName: org.name },
    {
      cancelToken,
      decoder: Organization,
    }
  )
